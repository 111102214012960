import React from 'react';

interface IQuotesModalView {
  quotesModalView: boolean;
}

const initialState: IQuotesModalView = {
  quotesModalView: false
};

export const QuotesModalViewContext = React.createContext(initialState);
const useQuotesModalView = () => React.useContext(QuotesModalViewContext);

export default useQuotesModalView;
