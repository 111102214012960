import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { IPerson, IRelatedPerson } from '../../../../../interfaces';
import { YES_VALUE } from '../../../../../interfaces/IDatapoint';
import { useActiveFCRAConsent, useFCRADisclosure } from '../../../../../queries/disclosures/useDisclosure';
import usePersonRelatedPeople from '../../../../../queries/people/usePersonRelatedPeople';
import { phoneFormatter } from '../../../../../utils/formatter';
import { isCustomerDataCompletenessHigh } from '../../../_helpers';
import { useAnswersWithVerificationStatus } from '../../../_hooks';

interface PartialRelatedPerson {
  gid: IRelatedPerson['gid'];
  first_name: IRelatedPerson['first_name'];
  last_name: IRelatedPerson['last_name'];
  middle_name: IRelatedPerson['middle_name'];
  name: IRelatedPerson['name'];
  date_of_birth: IRelatedPerson['date_of_birth'];
  relation_kind: IRelatedPerson['kind'];
  marital_status: IRelatedPerson['marital_status'];
  email: IRelatedPerson['email'];
  phone: IRelatedPerson['phone'];
}

interface PartialPerson {
  gid: IPerson['gid'];
  fcra: 'yes' | '';
  first_name: IPerson['first_name'];
  last_name: IPerson['last_name'];
  middle_name: IPerson['middle_name'];
  date_of_birth: IPerson['date_of_birth'];
  email: IPerson['email'];
  phone: IPerson['phone'];
  phone_secondary: IPerson['secondary_phone'];
  phone_business: IPerson['business_phone'];
  marital_status: IPerson['marital_status'];
}

export interface FormValues {
  person: PartialPerson;
  related_people: PartialRelatedPerson[];
}

export const useCustomerProfilePageInitialValues = () => {
  const { personGid, person, isLifeLead, customerDataCompleteness } = useGuidedSellingExperienceContext();

  const { data: fcraDisclosure } = useFCRADisclosure();

  const {
    data: isFcraDisclosureAccepted,
    isLoading: isLoadingFcraConsent,
    refetch: refetchFcraConsent
  } = useActiveFCRAConsent(personGid);

  const { data: relatedPeople, refetch: refetchRelatedPeople } = usePersonRelatedPeople(
    isLifeLead ? undefined : personGid
  );

  const answersWithVerificationStatus = useAnswersWithVerificationStatus({
    personGid: personGid!,
    relatedPeopleGids: (relatedPeople || []).map(({ gid }) => gid),
    isDataVerifiedByCustomer: isCustomerDataCompletenessHigh(customerDataCompleteness)
  });

  const relatedPeopleInitialValues = (relatedPeople || []).map(relatedPerson => ({
    gid: relatedPerson.gid,
    first_name: relatedPerson.first_name,
    middle_name: relatedPerson.middle_name || '',
    name: relatedPerson.name,
    last_name: relatedPerson.last_name,
    date_of_birth: relatedPerson.date_of_birth || '',
    relation_kind: relatedPerson.kind || ('' as PartialRelatedPerson['relation_kind']),
    marital_status: relatedPerson.marital_status || ('' as PartialRelatedPerson['marital_status']),
    phone: phoneFormatter(relatedPerson.phone) || '',
    email: relatedPerson.email || ''
  })) satisfies FormValues['related_people'];

  const personInitialValues = {
    person: {
      gid: person!.gid,
      first_name: person!.first_name,
      last_name: person!.last_name,
      middle_name: person!.middle_name || '',
      date_of_birth: person!.date_of_birth || '',
      email: person!.email || '',
      phone: phoneFormatter(person!.phone) || '',
      phone_secondary: phoneFormatter(person!.secondary_phone) || '',
      phone_business: phoneFormatter(person!.business_phone) || '',
      fcra: isFcraDisclosureAccepted ? YES_VALUE : '',
      marital_status: person!.marital_status || ('' as PartialPerson['marital_status'])
    } satisfies FormValues['person']
  };

  const isLoading =
    (isLifeLead ? false : !relatedPeople) ||
    !fcraDisclosure ||
    isLoadingFcraConsent ||
    !answersWithVerificationStatus[personGid!];

  return {
    answersWithVerificationStatus,
    isLoading,
    relatedPeopleInitialValues,
    personInitialValues,
    fcraDisclosure,
    refetchFcraConsent,
    refetchRelatedPeople,
    isFcraDisclosureAccepted
  };
};
