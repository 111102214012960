import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import QuestionVerificationStatusIcon from '../../../../../components/core/QuestionVerificationStatusIcon';
import Text from '../../../../../components/core/Text';
import VehicleKind from '../../../../../components/core/VehicleKind';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import { buildVehicleTitle, IVehicle } from '../../../../../interfaces/IVehicle';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
//eslint-disable-next-line max-len
import { useVehiclesWithVerificationStatus } from '../../../GuidedDataCollection/VehicleProfilePage/_hooks/useVehicleProfileAnswersWithVerificationAnswers';
import CopyableAnswer from '../_components/CopyableAnswer';
import QuestionAnswerPair from '../_components/QuestionAnswerPair';

interface NonAutoVehicleProps {
  vehicle: IVehicle;
  index: number;
  isEditModeAvailable: boolean;
  toggleEdit: () => void;
  answers: ReturnType<typeof useVehiclesWithVerificationStatus>[string] | undefined;
}

const NonAutoVehicle = ({ vehicle, index, isEditModeAvailable, toggleEdit, answers }: NonAutoVehicleProps) => {
  return (
    <FlexBox columnDirection gap={spacings.px8}>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <FlexBox alignItemsCenter gap={spacings.px8}>
          <Paragraph type="small" bold>
            {buildVehicleTitle(vehicle)}
          </Paragraph>
          <VehicleKind kind={vehicle.kind} />
        </FlexBox>
        {index === 0 && (
          <>
            <IconButton
              data-testid={`collect-info-tab-vehicle-edit-button-${index}`}
              disabled={!isEditModeAvailable}
              icon={ButtonIcons.Edit}
              color={colors.black}
              {...(!isEditModeAvailable && {
                'data-tip': 'Edit is not available at this moment',
                'data-for': 'edit-hint'
              })}
              onClick={event => {
                event.stopPropagation();
                toggleEdit();
              }}
            />
            <Tooltip id="edit-hint" />
          </>
        )}
      </FlexBox>
      {vehicle.vin && (
        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              VIN
            </Text>
          }
          answerLabel={
            <>
              {answers?.[DatapointKey.VehicleVin] && (
                <QuestionVerificationStatusIcon
                  verificationStatus={answers[DatapointKey.VehicleVin].verificationStatus}
                />
              )}
              <CopyableAnswer
                fsMask
                textType="small"
                value={vehicle.vin}
                testId={`collect-info-tab-vehicle-profile-results-vin-${index}`}
              />
            </>
          }
        />
      )}
    </FlexBox>
  );
};

export default NonAutoVehicle;
