/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';

import Container from '../../../components/core/Container';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useScoutingStrategy } from '../../../queries/scouting_strategies/useScoutingStrategy';
import { spacings } from '../../../theme/variables';
import ScoutingStrategy from './ScoutingStrategy';
import ScoutingStrategySkeleton from './ScoutingStrategy/ScoutingStrategySkeleton';

const UnknownPersonScoutingReport = () => {
  const { callLogId } = useGuidedSellingExperienceContext();
  const { data: scoutingStrategy, isFetching: isFetchingStrategy } = useScoutingStrategy({ callLogId });

  const isPreparingScoutingStrategy = isFetchingStrategy || !scoutingStrategy;

  return (
    <Container
      ph={spacings.px32}
      fitParentWidth
      customCss={css`
        max-width: 880px;
      `}
    >
      {isPreparingScoutingStrategy ? (
        <ScoutingStrategySkeleton />
      ) : (
        <ScoutingStrategy scoutingStrategy={scoutingStrategy} />
      )}
    </Container>
  );
};

export default UnknownPersonScoutingReport;
