/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import * as React from 'react';
import { ErrorBoundary, FallbackProps, useErrorBoundary } from 'react-error-boundary';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { Link } from '../../components/common';
import alert from '../../components/core/Alert';
import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import { InputField, TextareaField } from '../../components/core/forms/fields';
import Heading from '../../components/core/Heading';
import { WarningIcon } from '../../components/core/icons';
import Modal, { ModalSize } from '../../components/core/Modal';
import Paragraph from '../../components/core/Paragraph';
import Text from '../../components/core/Text';
import { IStepWithoutConditionGroups } from '../../interfaces';
import { useScoutingStrategyConfigs } from '../../queries/scouting_strategies/useScoutingStrategyConfigs';
import useScoutingStrategySteps, {
  SSS_QUERY_KEY,
  useCreateScoutingStrategyStep,
  useDeleteScoutingStrategyStep,
  useUpdateScoutingStrategyStep
} from '../../queries/scouting_strategy_steps/useScoutingStrategySteps';
import colors from '../../theme/colors';
import { heading4Css } from '../../theme/typography';
import { spacings } from '../../theme/variables';
import parseHtmlString from '../../utils/htmlStringParser';
import { sortSteps, transformString } from './_utils';
import HelpModal, { helpText } from './HelpModal';

const StrategyStepListItem = ({
  step,
  setStepToDelete
}: {
  step: IStepWithoutConditionGroups;
  setStepToDelete: (s: IStepWithoutConditionGroups) => void;
}) => {
  return (
    <NavLink to={`/scouting_steps/${step.id}`}>
      {({ isActive }) => (
        <FlexBox
          justifySpaceBetween
          alignItemsCenter
          backgroundColor={isActive ? colors.grey5 : colors.white}
          pv={spacings.px12}
          ph={spacings.px24}
          customCss={css`
            .hidden-child {
              visibility: hidden;
            }
            &:hover {
              background-color: ${colors.grey5};
              .hidden-child {
                visibility: visible;
              }
            }
          `}
        >
          <FlexBox
            alignItemsCenter
            justifySpaceBetween
            customCss={css`
              min-width: 0px;
              flex: 1;
            `}
          >
            <Paragraph type="small" mb={spacings.px4}>
              {step.name || step.title}
            </Paragraph>
            <IconButton
              className="hidden-child"
              icon={ButtonIcons.Delete}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setStepToDelete(step);
              }}
            />
          </FlexBox>
        </FlexBox>
      )}
    </NavLink>
  );
};

const FallbackComponent = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div role="alert">
      <Paragraph>
        We are unable to parse and transform provided description. Please check syntax and click try again.
      </Paragraph>
      <Button size={ButtonSize.Small} variant={ButtonVariant.SecondaryDanger} onClick={resetErrorBoundary}>
        Try again
      </Button>
      <Paragraph>
        Report the problem with the following error message and used description to support channel.
      </Paragraph>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
};

const PreviewStep = ({ description }: { description: string }) => {
  const { showBoundary } = useErrorBoundary();
  return (
    <div
      css={css`
        ${heading4Css};
        font-weight: normal;
      `}
    >
      {parseHtmlString(transformString(description), showBoundary)}
    </div>
  );
};

const ScoutingSteps = (): JSX.Element => {
  const { stepId } = useParams();
  const navigate = useNavigate();
  const [stepToDelete, setStepToDelete] = React.useState<IStepWithoutConditionGroups>();

  const { data: list = [] } = useScoutingStrategySteps();
  const { data: scoutingStrategyConfigs } = useScoutingStrategyConfigs();
  const sortedStepsList = sortSteps(list) || [];

  const queryClient = useQueryClient();
  const { mutateAsync: createStep } = useCreateScoutingStrategyStep({
    onSuccess: data => {
      navigate(`/scouting_steps/${data.scouting_strategy_step.id}`);
      return queryClient.invalidateQueries({ queryKey: [SSS_QUERY_KEY] });
    }
  });
  const { mutateAsync: updateStep } = useUpdateScoutingStrategyStep({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [SSS_QUERY_KEY] })
  });
  const { mutateAsync: deleteStep } = useDeleteScoutingStrategyStep({
    onSuccess: () => {
      navigate('/scouting_steps/new');
      return queryClient.invalidateQueries({ queryKey: [SSS_QUERY_KEY] });
    }
  });

  const step = sortedStepsList.find(s => `${s.id}` === stepId);

  return (
    <div
      css={css`
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 300px 1fr 300px;
        grid-template-rows: fit-content(116px) 1fr;
        grid-template-areas:
          'header header header'
          'left-bar guide info-bar';
        height: 100vh;
      `}
    >
      <div
        css={css`
          grid-area: left-bar;
          display: grid;
          overflow: hidden;
        `}
      >
        <div
          css={css`
            overflow-y: scroll;
            &:last-child {
              padding-bottom: 24px;
            }
          `}
        >
          {sortedStepsList.map(step => (
            <StrategyStepListItem key={step.id} step={step} setStepToDelete={setStepToDelete} />
          ))}
        </div>
      </div>
      <div
        css={css`
          grid-area: header;
          box-shadow: inset 0px -1px 0px ${colors.grey10};
        `}
      >
        <FlexBox justifySpaceBetween alignItemsCenter p={spacings.px24}>
          <Heading type="h3">Steps</Heading>
          <FlexBox gap={spacings.px8} justifyRight>
            <Button onClick={() => navigate('/scouting_steps/new')}>New step</Button>
            <Button onClick={() => navigate('/scouting_report_scripts')}>Switch to config editor</Button>
            <HelpModal />
            {stepToDelete && (
              <Modal
                title="Help"
                size={ModalSize.medium}
                dangerModal
                confirmHandler={() => deleteStep(stepToDelete.id).finally(() => setStepToDelete(undefined))}
                cancelHandler={() => setStepToDelete(undefined)}
              >
                <Paragraph
                  customCss={css`
                    white-space: break-spaces;
                  `}
                >
                  You are going to delete step {stepToDelete.name || stepToDelete.title}
                </Paragraph>
              </Modal>
            )}
          </FlexBox>
        </FlexBox>
      </div>
      <div
        css={css`
          background: ${colors.white};
          grid-area: 'guide';
          overflow: hidden;
          display: grid;
        `}
      >
        <div
          css={css`
            overflow-y: scroll;
            padding: 20px 24px 0;
          `}
        >
          <Formik
            enableReinitialize
            onSubmit={values =>
              step
                ? updateStep({ ...step, ...values }).then(() => alert({ title: 'Step updated' }).success())
                : createStep({ ...values, position: 1 }).then(() => alert({ title: 'Step created' }).success())
            }
            initialValues={{
              title: step?.title || '',
              name: step?.name || '',
              description: step?.description || ''
            }}
          >
            {({ values, submitForm, isSubmitting }) => {
              const usedInConfigs = scoutingStrategyConfigs?.filter(config =>
                config.scouting_strategy_steps.some(connectedStep => connectedStep.id === step?.id)
              );

              const isConfigLoaded = !!scoutingStrategyConfigs?.length;

              return (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    submitForm();
                  }}
                >
                  <FlexBox mb={spacings.px32} justifySpaceBetween>
                    <Heading>Step editor</Heading>
                    <FlexBox gap={spacings.px4}>
                      <Button loading={isSubmitting} type="submit">
                        Save step
                      </Button>
                    </FlexBox>
                  </FlexBox>

                  <Container mb={spacings.px32}>
                    {isConfigLoaded && !!usedInConfigs?.length && (
                      <>
                        <Text>Used in: </Text>
                        {usedInConfigs?.map((config, index) => (
                          <Link key={config.id} to={`/scouting_report_scripts/${config.id}`}>
                            {config.key}
                            {index < usedInConfigs.length - 1 ? ', ' : ''}
                          </Link>
                        ))}
                      </>
                    )}
                    {isConfigLoaded && !usedInConfigs?.length && (
                      <>
                        <WarningIcon color={colors.statusOrange} />
                        <Text pl={spacings.px4}>Not used in any scouting report configs</Text>
                      </>
                    )}
                  </Container>

                  <InputField id="name" name="name" label="Name" required />
                  <InputField id="title" name="title" label="Title" />
                  <TextareaField id="description" name="description" label="Description" />

                  {values.title && <Heading type="h4">{values.title}</Heading>}
                  <ErrorBoundary FallbackComponent={FallbackComponent}>
                    <PreviewStep description={values.description} />
                  </ErrorBoundary>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>

      <div
        css={css`
          border-left: solid 1px ${colors.grey10};
          grid-area: 'info-bar';
          overflow: hidden;
          padding: 16px;
        `}
      >
        <Paragraph
          customCss={css`
            white-space: break-spaces;
          `}
        >
          {helpText}
        </Paragraph>
      </div>
    </div>
  );
};

export default ScoutingSteps;
