import React from 'react';

import { IGroupedQuotes, IQuote } from '../../../../../../interfaces';
import { IAssetsQuotesGroup } from '../../../../../../interfaces/IGroupedQuotes';
import {
  isInsurableInterestLife,
  isInsurableInterestRealProperty,
  isInsurableInterestVehicle,
  PolicyType
} from '../../../../../../interfaces/IPolicyType';
import { IQuotesRequest } from '../../../../../../interfaces/IQuotesRequests';
import useQuotesRequests from '../../../../../../queries/quotes_requests/useQuotesRequests';
import { filterAutoQuotesByTier } from '../../_helpers/autoQuotesHelper';
import LifeQuoteGroup from './LifeQuoteGroup';
import PAFQuotesGroup from './PAFQuotesGroup';
import PetQuotesGroup from './PetQuotesGroup';
import RealPropertyQuotesGroup from './RealPropertyQuotesGroup';
import UmbrellaQuotesGroup from './UmbrellaQuotesGroup';
import VehiclesQuotesGroup from './VehiclesQuotesGroup';

export interface QuoteGroupProps {
  quotesGroup: IAssetsQuotesGroup;
  assets: IGroupedQuotes['assets'];
  leadId: number | undefined;
  personGid: string;
  refetchQuotesRequests: ReturnType<typeof useQuotesRequests>['refetch'];
  quotingFinished: boolean;
  quotesRequest: IQuotesRequest;
  errors: IQuote[] | undefined;
}

const AssetsQuoteGroup = (props: QuoteGroupProps) => {
  const {
    quotesGroup: {
      quotes,
      opportunity: { policy_type: policyType }
    }
  } = props;

  if (isInsurableInterestRealProperty(policyType)) {
    if (policyType === PolicyType.Umbrella) {
      return <UmbrellaQuotesGroup {...props} />;
    }

    return <RealPropertyQuotesGroup {...props} />;
  }

  if (policyType === PolicyType.PAF) {
    return <PAFQuotesGroup {...props} />;
  }

  if (policyType === PolicyType.Pet) {
    return <PetQuotesGroup {...props} />;
  }

  if (isInsurableInterestLife(policyType)) {
    return <LifeQuoteGroup {...props} />;
  }

  if (isInsurableInterestVehicle(policyType)) {
    const vehiclesQuotesGroupProps = {
      ...props,
      quotesGroup: { ...props.quotesGroup, quotes: filterAutoQuotesByTier(quotes) }
    };
    return <VehiclesQuotesGroup {...vehiclesQuotesGroupProps} />;
  }

  return null;
};

export default AssetsQuoteGroup;
