import { useNavigate } from 'react-router-dom';

import { GuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import analytics from '../../../services/analytics';
// eslint-disable-next-line max-len
import { CurrentDispositionSubscriptionContext } from '../../GuidedSellingExperience/_hooks/useCurrentDispositionSubscription';
import GuidedQuoting from '../../GuidedSellingExperience/GuidedQuoting';

const CustomerQuotes = ({ personGid }: { personGid: string }) => {
  const navigate = useNavigate();
  const onGetQuotes = () => {
    analytics.track('Get quotes clicked', {
      lead_gid: undefined,
      person_gid: personGid,
      place: 'old_customer_profile_ui'
    });
    return navigate(`/people/${personGid}/quotes/new`);
  };

  return (
    <GuidedSellingExperienceContext.Provider value={{ isPersonLinkedToActiveCall: true } as any}>
      <CurrentDispositionSubscriptionContext.Provider value={{ currentDispositions: undefined } as any}>
        <GuidedQuoting
          leadGid={undefined}
          leadId={undefined}
          personGid={personGid}
          onConfigureQuoting={() => onGetQuotes()}
        />
      </CurrentDispositionSubscriptionContext.Provider>
    </GuidedSellingExperienceContext.Provider>
  );
};

export default CustomerQuotes;
