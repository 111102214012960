/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { IDisposition } from '../../../interfaces/IDisposition';
import useLeadAssignees from '../../../queries/leads/assignees/useLeadAssignees';
import useEditDispositionAssignee from '../../../queries/leads/dispositions/useEditDispositionAssignee';
import colors from '../../../theme/colors';
import { defaultFontValues } from '../../../theme/typography';
import { requiredField } from '../../../utils/yupRules';
import { ErrorCol } from '../../common';
import { Select } from '../../common/FormikComponents';
import FlexBox from '../../core/FlexBox';
import FormModal from '../../core/FormModal';
import { ModalSize } from '../../core/Modal';
import Paragraph from '../../core/Paragraph';
import Text from '../../core/Text';

interface EditAssigneeProps {
  leadId: string | number;
  disposition: IDisposition;
  closeModal: () => void;
  onDispositionUpdated?: () => void;
}

const EditAssigneeModal: React.FC<EditAssigneeProps> = ({ leadId, disposition, closeModal, onDispositionUpdated }) => {
  const { data: assignees, isFetching: isFetchingAssignees } = useLeadAssignees({
    leadId,
    licensed: disposition.is_license_required
  });

  const { mutateAsync: editDispositionAssignee, isPending: isEditingDispositionAssignee } =
    useEditDispositionAssignee();

  return (
    <FormModal
      returnFocusAfterClose={false}
      size={ModalSize.large}
      title={`You're going to change the assignee of "${disposition.label}" disposition`}
      confirmText="Update record"
      initialValues={{
        current_assignee: disposition.assignee?.id,
        new_assignee: ''
      }}
      validationSchema={yup.object().shape({
        new_assignee: requiredField
      })}
      confirmHandler={values => {
        editDispositionAssignee({
          leadId,
          dispositionType: disposition.disposition_type,
          assigneeId: values.new_assignee
        }).then(() => {
          closeModal();
          onDispositionUpdated?.();
        });
      }}
      cancelHandler={() => {
        closeModal();
      }}
      dangerModal
      confirmationInProgress={isFetchingAssignees || isEditingDispositionAssignee}
      renderForm={({ errors, touched }) => (
        <>
          <Paragraph>
            Are you sure you want to change the record? This action will affect company&apos;s data and will be
            reflected in the activity log.
          </Paragraph>
          <Paragraph bold>Your change</Paragraph>
          <FlexBox
            alignItemsCenter
            customCss={css`
              ${defaultFontValues}
            `}
          >
            <ErrorCol sm={4}>
              <Text bold uppercase color={colors.grey30} type="small">
                From
              </Text>
              <Field
                css={css`
                  margin-top: 4px;
                `}
                disabled
                small
                name="current_assignee"
                component={Select}
                options={[disposition.assignee]}
                labelName="name"
                valueName="id"
              />
            </ErrorCol>
            <Text>&#8594;</Text>
            <ErrorCol
              sm={4}
              name="new_assignee"
              error={errors['new_assignee']}
              when={Boolean(errors['new_assignee'] && touched['new_assignee'])}
            >
              <Text bold uppercase color={colors.grey30} type="small">
                To
              </Text>
              <Field
                css={css`
                  margin-top: 4px;
                `}
                small
                name="new_assignee"
                component={Select}
                options={assignees}
                placeholder="Please select an assignee"
                labelName="name"
                valueName="id"
              />
            </ErrorCol>
          </FlexBox>
        </>
      )}
    />
  );
};

export default EditAssigneeModal;
