import { Navigate, useParams } from 'react-router-dom';

import useZendeskUser from '../../queries/zendesk_users/useZendeskUser';
import FlexBox from '../core/FlexBox';
import Paragraph from '../core/Paragraph';

const ZendeskUserPersonRedirect = () => {
  const params = useParams();
  const userId = params.userId;
  const subPath = params['*'];

  const { data: zendeskUser, isLoading } = useZendeskUser({ userId });

  if (zendeskUser) {
    return (
      <Navigate
        replace
        to={
          subPath
            ? `/people/${zendeskUser.person_gid}/${subPath}`
            : `/people/${zendeskUser.person_gid}/communication_history`
        }
      />
    );
  }

  if (!isLoading) {
    return (
      <FlexBox alignItemsCenter justifyCenter fitParentHeight>
        <Paragraph bold>Customer not found in AMP</Paragraph>
      </FlexBox>
    );
  }

  return null;
};

export default ZendeskUserPersonRedirect;
