/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import Text from '../../components/core/Text';
import { IDataCollectionOpportunity } from '../../interfaces/IDataCollection';
import { IOpportunity } from '../../interfaces/IOpportunity';
import { spacings } from '../../theme/variables';
import { DataCollectionStepper } from '../GuidedSellingExperience/GuidedDataCollection/_hooks/useDataCollectionStepper';
import { GuidedSellingPathnames } from '../GuidedSellingExperience/navigation';

interface ISkippedAssetForm {
  onSubmit: (values: Record<string, never>) => void;
  children?: React.ReactNode;
  isDataEditingForbidden: boolean;
  dataCollectionStepper: DataCollectionStepper;
  opportunity: IOpportunity;
}

const SkippedAssetForm = ({
  opportunity,
  onSubmit,
  children,
  isDataEditingForbidden,
  dataCollectionStepper
}: ISkippedAssetForm): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <BaseForm
      controlsAlignment="right"
      controlsWidth={320}
      pb={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      pt={spacings.px24}
      type="fullPage"
      disabled={isDataEditingForbidden}
      submitText="Save and proceed"
      initialValues={{}}
      onSubmit={onSubmit}
      renderForm={() => (
        <FlexBox columnDirection>
          {children}
          <>
            <FlexBox mt={spacings.px24} columnDirection>
              <Text>Information about this opportunity was not collected</Text>
              <Container mt={spacings.px8}>
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Secondary}
                  onClick={() => {
                    navigate(`${GuidedSellingPathnames.DataCollection}${search}`);
                    dataCollectionStepper.moveToOpportunity(opportunity as IDataCollectionOpportunity);
                  }}
                >
                  Collect data
                </Button>
              </Container>
            </FlexBox>
          </>
        </FlexBox>
      )}
    />
  );
};

export default SkippedAssetForm;
