/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import React from 'react';

import { Copy } from '../../../../components/common';
import HomeLinks from '../../../../components/common/HomeLinks';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import CopyableAddress from '../../../../components/core/CopyableAddress';
import FlexBox from '../../../../components/core/FlexBox';
import { WarningIcon } from '../../../../components/core/icons';
import PolicyTypeIcon from '../../../../components/core/PolicyTypeIcon';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import { Translations } from '../../../../constants';
import featureFlags from '../../../../constants/featureFlags';
import { ICurrentDisposition, IHome, IOpportunity, IOpportunityPolicies, IQuote } from '../../../../interfaces';
import { OpportunityStatus } from '../../../../interfaces/IOpportunity';
import { findPolicyTypeLabel, isInsurableInterestRealProperty } from '../../../../interfaces/IPolicyType';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { moneyFormatter } from '../../../../utils/formatter';
import ExternalLinkModal from '../../../GuidedSellingExperience/GuidedQuoting/Quotes/_components/ExternalLinkModal';
// eslint-disable-next-line max-len
import DeprecatedStatus from '../../../GuidedSellingExperience/GuidedQuoting/Quotes/_components/QuoteRow/DeprecatedStatus';
import Status from '../../../GuidedSellingExperience/GuidedQuoting/Quotes/_components/QuoteRow/Status';

const OpportunityRow = ({
  personGid,
  opportunity,
  currentDisposition,
  onEditOpportunity
}: {
  personGid: string;
  opportunity: IOpportunity & IOpportunityPolicies & { customerChoice: IQuote | undefined };
  currentDisposition: ICurrentDisposition;
  onEditOpportunity: null | ((opportunity: IOpportunity) => void);
}) => {
  const { policy_type, primary, assets, customerChoice } = opportunity;

  let preparedReason;
  if (opportunity.disqualification_message) {
    preparedReason = opportunity.disqualification_message;
  } else if (currentDisposition.disposition_reason === 'auto_closed') {
    preparedReason = 'It hasn’t been worked on for 45 days';
  } else if (opportunity.status === OpportunityStatus.Lost) {
    const configuredReason = Translations.opportunityLostReason(opportunity.reason);

    preparedReason = configuredReason && `Lost due to ${configuredReason}`;
  }

  const showQuoteLink =
    customerChoice && (customerChoice.attributes.external_id || customerChoice.attributes.binding_url);
  const [showExternalLinkModal, setShowExternalLinkModal] = React.useState(false);

  const latestPolicy = opportunity.latestPolicy;
  const latestPolicyYearlyPremium = moneyFormatter(latestPolicy?.premium, true);
  const latestPolicyCarrierName = latestPolicy?.carrier?.name;

  return (
    <>
      <FlexBox alignItemsCenter pb={spacings.px4}>
        <PolicyTypeIcon
          policyType={policy_type}
          css={css`
            margin-right: 8px;
          `}
        />
        <Text bold mr={spacings.px8}>
          {findPolicyTypeLabel(policy_type)}
        </Text>
        {primary && <Tag label="Primary opportunity" />}
        {preparedReason && (
          <>
            <WarningIcon
              color={colors.statusOrange}
              css={css`
                margin: 0 6px 0 10px;
              `}
            />
            <Text color={colors.statusOrange} type="small" bold>
              {preparedReason}
            </Text>
          </>
        )}
      </FlexBox>

      <Grid container justifyContent="space-between">
        <Grid item xs={5} container direction="column">
          <Text bold color={colors.grey60} type="small">
            Assets
          </Text>
          {assets?.map(({ gid, description_without_icon, address, ...rest }) => (
            <FlexBox key={gid} pr={spacings.px16} pt={spacings.px4} alignItemsCenter>
              {isInsurableInterestRealProperty(policy_type) ? (
                <>
                  <HomeLinks
                    personGid={personGid}
                    homeGid={gid}
                    customCss={css`
                      padding-right: 4px;
                    `}
                  />
                  <div>
                    {Translations.homeUsage((rest as IHome).usage)}
                    <CopyableAddress address={address} />
                  </div>
                </>
              ) : (
                <Copy value={description_without_icon} displayText={description_without_icon} />
              )}
            </FlexBox>
          ))}
          {!assets?.length && <Text>–</Text>}
        </Grid>

        <Grid item xs={3} container direction="column">
          <Text bold color={colors.grey60} type="small">
            {"Client's choice"}
          </Text>
          <FlexBox pt={spacings.px4} columnDirection>
            {customerChoice ? (
              <>
                {showQuoteLink ? (
                  <Text
                    bold
                    onClick={() => setShowExternalLinkModal(true)}
                    color={colors.azure50}
                    customCss={css`
                      &:hover {
                        color: ${colors.blueHoverLink};
                      }
                      cursor: pointer;
                    `}
                  >
                    {customerChoice.attributes.carrier.name}
                  </Text>
                ) : (
                  <Text>{customerChoice.attributes.carrier.name}</Text>
                )}
                {customerChoice.attributes.premium && (
                  <Text>{moneyFormatter(customerChoice.attributes.premium, true)}/yr</Text>
                )}
                {!customerChoice.attributes.premium &&
                  (featureFlags.newQuotesUI ? (
                    <Status quote={customerChoice} />
                  ) : (
                    <DeprecatedStatus quote={customerChoice} withIcon />
                  ))}
              </>
            ) : (
              <Text>-</Text>
            )}
          </FlexBox>
        </Grid>

        <Grid item xs={3} container direction="column">
          <Text bold color={colors.grey60} type="small">
            Current policy
          </Text>
          <FlexBox pt={spacings.px4} columnDirection>
            {latestPolicyCarrierName || latestPolicyYearlyPremium ? (
              <>
                {latestPolicyCarrierName && <Text>{latestPolicyCarrierName}</Text>}
                {latestPolicyYearlyPremium && <Text>{latestPolicyYearlyPremium}/yr</Text>}
              </>
            ) : (
              <Text>-</Text>
            )}
          </FlexBox>
        </Grid>

        <Grid item xs="auto" container direction="column" alignContent="end">
          <IconButton
            icon={ButtonIcons.Edit}
            data-testid="opportunity-edit"
            disabled={onEditOpportunity === null}
            color={colors.black}
            onClick={() => onEditOpportunity && onEditOpportunity(opportunity)}
          />
        </Grid>
      </Grid>

      {showExternalLinkModal && customerChoice && (
        <ExternalLinkModal quote={customerChoice} cancelBtnHandler={() => setShowExternalLinkModal(false)} />
      )}
    </>
  );
};

export default OpportunityRow;
