/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Blockquote from '../../../../../../components/core/Blockquote';
import CollapsingContainer from '../../../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../../../components/core/FlexBox';
import Paragraph, { ParagraphType } from '../../../../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../../../../components/core/PolicyTypeIcon';
import Text from '../../../../../../components/core/Text';
import featureFlags from '../../../../../../constants/featureFlags';
import { IHome } from '../../../../../../interfaces';
import { RealPropertyOpportunity } from '../../../../../../interfaces/IOpportunity';
import { PolicyType } from '../../../../../../interfaces/IPolicyType';
import { QuoteStatus } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';
import FloodRiskTag from '../../../../_components/FloodRiskTag';
import { isQuoteEligibleOrPartlyEligible, isQuoteNonEligible, isQuoteRequiresDataFix } from '../../_helpers';
import { promoteNonIntegratedCarriers } from '../../_helpers/quotesAugmentationHelper';
import useQuotesModalView from '../../_hooks/useModalView';
import QuotingErrorsByAssets from '../../QuotingErrors/QuotingErrorsByAssets';
import DeprecatedHomeCoverageLevel from '../CoverageLevel/DeprecatedHomeCoverageLevel';
import HomeCoverageLevel from '../CoverageLevel/HomeCoverageLevel';
import RealPropertyGroupHeader from '../GroupHeaders/RealPropertyGroupHeader';
import QuoteRow from '../QuoteRow/QuoteRow';
import { QuoteGroupProps } from '.';

const RealPropertyQuotesGroup = ({
  personGid,
  leadId,
  quotesGroup,
  assets,
  refetchQuotesRequests,
  quotingFinished,
  quotesRequest,
  errors
}: QuoteGroupProps) => {
  const { quotesModalView } = useQuotesModalView();
  const [isPremiumAnnual, setAnnualPremium] = React.useState(true);
  const quotes = quotesGroup.quotes;
  const realQuotes = quotes.filter(({ status }) => status !== QuoteStatus.CompleteManually);

  const eligibleQuotes = realQuotes.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const invalidDataQuotes = realQuotes.filter(quote => isQuoteRequiresDataFix(quote));
  const notEligibleQuotes = realQuotes.filter(quote => isQuoteNonEligible(quote));

  const carriersRecommendations = quotes.filter(({ status }) => status === QuoteStatus.CompleteManually);

  const eligibleRecommendations = carriersRecommendations.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleRecommendations = carriersRecommendations.filter(quote => isQuoteNonEligible(quote));

  const assetGid = quotes.find(q => q.gid === quotes[0]?.gid)?.attributes.asset_gids?.[0];
  const policyType = quotesGroup.opportunity.policy_type;

  const showHomeCoverageConfig = leadId && policyType !== PolicyType.Renters && assetGid;

  const groupTitle = assets
    .filter(({ gid }) => quotes[0]?.attributes.asset_gids?.includes(gid))
    .map(({ label }) => label)
    .join('\n');

  const eligibleEntriesCount = eligibleQuotes.length + invalidDataQuotes.length + eligibleRecommendations.length;
  const nonEligibleEntriesCount = notEligibleQuotes.length + notEligibleRecommendations.length;

  const nonIntegratedQuotesWithPromotions = promoteNonIntegratedCarriers({
    quotes: eligibleRecommendations
  });

  return (
    <FlexBox columnDirection gap={spacings.px8}>
      {!quotesModalView && (
        <>
          {realQuotes.length > 0 && policyType !== PolicyType.Flood && (
            <Blockquote
              ml={spacings.px32}
              pv={spacings.px16}
              text={
                <Paragraph>
                  Our premium analysis engine uses neighborhood premium and your policy history to predict changes in
                  your next premium. We calculate predictive savings based on future prices, so you can make informed
                  insurance decisions and get the best premium possible.
                </Paragraph>
              }
            />
          )}
          <FlexBox justifySpaceBetween gap={spacings.px8}>
            <FlexBox alignItemsCenter gap={spacings.px4}>
              <PolicyTypeIcon policyType={policyType} />
              <Text
                bold={!featureFlags.newQuotesUI}
                type="large"
                customCss={css`
                  white-space: pre-wrap;
                `}
                singleLine
              >
                {quotesGroup.opportunity.title} ({quotes.length})
              </Text>
              {policyType === PolicyType.Flood && (
                <FloodRiskTag
                  floodRisk={(quotesGroup.opportunity.assets?.[0] as IHome | undefined)?.flood_risk || null}
                />
              )}
              <Text className="fs-mask" ml={spacings.px6} type="large" color={colors.grey60} singleLine>
                {groupTitle || quotesGroup.opportunity.assets?.[0]?.address.full}
              </Text>
            </FlexBox>
            {featureFlags.newQuotesUI && (
              <FlexBox alignItemsCenter>
                {assetGid && leadId && (
                  <HomeCoverageLevel
                    opportunity={quotesGroup.opportunity as RealPropertyOpportunity}
                    assetGid={assetGid}
                    leadId={leadId}
                    personGid={personGid}
                    refetchQuotesRequests={refetchQuotesRequests}
                  />
                )}
                {errors && errors.length > 0 && <QuotingErrorsByAssets errors={errors} quotesRequest={quotesRequest} />}
              </FlexBox>
            )}
          </FlexBox>

          {showHomeCoverageConfig && !featureFlags.newQuotesUI && (
            <DeprecatedHomeCoverageLevel
              opportunity={quotesGroup.opportunity as RealPropertyOpportunity}
              assetGid={assetGid}
              leadId={leadId}
              personGid={personGid}
              refetchQuotesRequests={refetchQuotesRequests}
            />
          )}
        </>
      )}

      {eligibleEntriesCount > 0 && (
        <RealPropertyGroupHeader isPremiumAnnual={isPremiumAnnual} setAnnualPremium={setAnnualPremium} />
      )}

      {eligibleEntriesCount > 0 && (
        <CollapsingContainer
          title={`Eligible and partly eligible (${eligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          {eligibleQuotes.map(quote => {
            return (
              <QuoteRow
                key={quote.gid}
                quote={quote}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
                opportunity={quotesGroup.opportunity}
              />
            );
          })}
          {invalidDataQuotes.map(quote => {
            return (
              <QuoteRow
                key={quote.gid}
                quote={quote}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
          {nonIntegratedQuotesWithPromotions.map(recommendation => {
            return (
              <QuoteRow
                key={recommendation.gid}
                quote={recommendation}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
        </CollapsingContainer>
      )}

      {quotingFinished && eligibleEntriesCount === 0 && nonEligibleEntriesCount ? (
        <CollapsingContainer
          title={`Eligible and partly eligible (${eligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          <FlexBox alignItemsCenter justifyCenter pv={spacings.px24}>
            <Paragraph color={colors.grey60}>No quotes available</Paragraph>
          </FlexBox>
        </CollapsingContainer>
      ) : null}

      {nonEligibleEntriesCount > 0 && (
        <CollapsingContainer
          title={`Not eligible (${nonEligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
          openedByDefault={false}
        >
          {notEligibleQuotes.map(quote => {
            return (
              <QuoteRow
                key={quote.gid}
                quote={quote}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
          {notEligibleRecommendations.map(recommendation => {
            return (
              <QuoteRow
                key={recommendation.gid}
                quote={recommendation}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
        </CollapsingContainer>
      )}
      {quotingFinished && !eligibleEntriesCount && !nonEligibleEntriesCount && (
        <FlexBox justifyCenter pv={spacings.px24}>
          <Paragraph color={colors.grey60}>No quotes available</Paragraph>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default RealPropertyQuotesGroup;
