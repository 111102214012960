/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../../../components/core/FlexBox';
import { MultiSelectField } from '../../../../../components/core/forms/fields';
import { IPerson, isDriverInfoCollected, isPersonAgedToDrive, personAge } from '../../../../../interfaces/IPerson';
import usePersonRelatedPeople from '../../../../../queries/people/usePersonRelatedPeople';
import { spacings } from '../../../../../theme/variables';
import { IQuotingConfigurationOpportunity } from '../_interfaces/IQuotingConfigurationOpportunity';

const AutoInsuredPeople = ({
  person,
  opportunity
}: {
  person: IPerson;
  opportunity: IQuotingConfigurationOpportunity;
}) => {
  const { data: relatedPeople, isPending: isPendingRelatedPeople } = usePersonRelatedPeople(person.gid);

  const primaryInsuredOption = { key: person.gid, value: person.name, isDisabled: true };
  const coinsuredPeopleOptions =
    relatedPeople?.map(person => {
      const { name, gid } = person;
      let optionConfig;

      if (!isDriverInfoCollected(person)) {
        optionConfig = { value: `${name} (driver info is not collected)`, isDisabled: true };
      } else if (!isPersonAgedToDrive(person)) {
        optionConfig = { value: `${name} (${personAge(person)} years old)`, isDisabled: true };
      } else {
        optionConfig = { value: name, isDisabled: false };
      }

      return { key: gid, ...optionConfig };
    }) || [];
  const options = [
    primaryInsuredOption,
    ...coinsuredPeopleOptions.sort((option1, option2) => (option1.isDisabled ? 1 : 0) - (option2.isDisabled ? 1 : 0))
  ];

  return (
    <FlexBox ml={spacings['-px4']} pv={spacings.px4} gap={spacings.px12}>
      <MultiSelectField
        fsMask
        inline
        label="Insured drivers"
        key={`opportunities.${opportunity.id}.insured_gids`}
        id={`opportunities.${opportunity.id}.insured_gids`}
        name={`opportunities.${opportunity.id}.insured_gids`}
        options={options}
        disabled={isPendingRelatedPeople}
        isLoading={isPendingRelatedPeople}
        isOptionDisabled={(option: any) => option.isDisabled}
        css={css`
          flex-grow: 1;
        `}
      />
    </FlexBox>
  );
};

export default AutoInsuredPeople;
