import { Navigate, useParams } from 'react-router-dom';

import useCustomer from '../../queries/customers/useCustomer';

const PersonGidRedirect = () => {
  const params = useParams();
  const customerId = params.customerId;
  const subPath = params['*'];

  const { data } = useCustomer(customerId);

  if (data?.origin_gid) {
    return <Navigate replace to={subPath ? `/people/${data.origin_gid}/${subPath}` : `/people/${data.origin_gid}`} />;
  }

  return null;
};

export default PersonGidRedirect;
