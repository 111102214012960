import FlexBox from '../../../../../components/core/FlexBox';
import Heading from '../../../../../components/core/Heading';
import Text from '../../../../../components/core/Text';
import { QuotingAccessLevel } from '../../../../../interfaces/IQuotingAccess';
import { spacings } from '../../../../../theme/variables';

const QUOTING_NO_LICENSE_MESSAGE = 'Quoting feature is disabled for non-licensed agents in the current state';
const QUOTING_NO_LICENSE_EXPLANATION =
  'Make sure that assets addresses are correct and you have a valid license in a state';

const QUOTING_FORBIDDEN_DISPOSITION_MESSAGE = 'Update disposition status to enable quoting feature.';
const QUOTING_FORBIDDEN_DISPOSITION_EXPLANATION =
  "As a data-driven company we use data to get insights about how our business works, to measure products' health." +
  ' Help us to improve everyday decision making.';

const messageForAccessLevel = (accessLevel: QuotingAccessLevel) => {
  if (accessLevel === QuotingAccessLevel.LicenseRequired) {
    return { title: QUOTING_NO_LICENSE_MESSAGE, description: QUOTING_NO_LICENSE_EXPLANATION };
  }

  return { title: QUOTING_FORBIDDEN_DISPOSITION_MESSAGE, description: QUOTING_FORBIDDEN_DISPOSITION_EXPLANATION };
};

const QuotingRestriction = ({ accessLevel }: { accessLevel: QuotingAccessLevel }) => {
  const { title, description } = messageForAccessLevel(accessLevel);

  return (
    <FlexBox mt={spacings.px32} alignItemsCenter columnDirection>
      <Heading type="h4">{title}</Heading>
      <Text mt={spacings.px12}>{description}</Text>
    </FlexBox>
  );
};

export default QuotingRestriction;
