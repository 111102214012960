import React from 'react';

import FlexBox from '../../../../../components/core/FlexBox';
import { WarningIcon } from '../../../../../components/core/icons';
import Text from '../../../../../components/core/Text';
import featureFlags from '../../../../../constants/featureFlags';
import { IQuote } from '../../../../../interfaces';
import { isMoreThanOneWeekOld } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { relativeTimeFormatter } from '../../../../../utils/formatter';
import DeprecatedStatus from '../../../GuidedQuoting/Quotes/_components/QuoteRow/DeprecatedStatus';
import Status from '../../../GuidedQuoting/Quotes/_components/QuoteRow/Status';

interface ICustomerChoiceHeader {
  customerChoice: IQuote;
}

const CustomerChoiceHeader = ({ customerChoice }: ICustomerChoiceHeader) => (
  <FlexBox gap={spacings.px8} alignItemsCenter>
    <Text bold>{customerChoice.attributes.carrier.name}</Text>
    <Text type="tiny" color={colors.grey80}>
      requested {relativeTimeFormatter(customerChoice.created_at)}
    </Text>
    {isMoreThanOneWeekOld(customerChoice.created_at) && (
      <WarningIcon color={colors.statusOrange} data-tip="Quotes might be outdated" data-for="quoted-outdated-tooltip" />
    )}
    {featureFlags.newQuotesUI ? (
      <Status quote={customerChoice} />
    ) : (
      <DeprecatedStatus quote={customerChoice} withIcon />
    )}
  </FlexBox>
);

export default CustomerChoiceHeader;
