import { ICondition, IConditionGroup, IStepWithoutConditionGroups } from '../../interfaces';
import authInfo from '../../services/authInfo';

const VAR_MAPPINGS = {
  first_name: '<highlighted>Lyndsey</highlighted>',
  full_name: '<highlighted>Lyndsey McMahon</highlighted>',
  savings: '<highlighted>100$</highlighted>',
  brand_name: '<highlighted>Mr.Cooper</highlighted>',
  primary_opportunity: '<highlighted>Home</highlighted>',
  marketing_opportunity: '<highlighted>Policy expiration</highlighted>',
  lead_capture_method: '<highlighted>Landing page</highlighted>',
  attributed_marketing_medium: '<highlighted>Email</highlighted>',
  lead_create_date: '<highlighted>2020-10-10</highlighted>',
  prior_policy_expiration_date: '<highlighted>2020-10-10</highlighted>',
  prior_policy_cost: '<highlighted>200$</highlighted>',
  prior_policy_carrier: '<highlighted>Travelers</highlighted>',
  loan_officer_name: '<highlighted>Officer Name</highlighted>',
  loan_closing_date: '<highlighted>2022-10-10</highlighted>',
  engagement_experience_type: '<highlighted>direct sales</highlighted>',
  property_address_state: '<highlighted>OH</highlighted>',
  property_address: '<highlighted>Some street 123, OH</highlighted>',
  garaging_address_state: '<highlighted>OH</highlighted>',
  garaging_address: '<highlighted>Some street with garage 123, OH</highlighted>'
} as Record<any, any>;

export const transformString = (inputString: string) => {
  if (!VAR_MAPPINGS.user_name) {
    VAR_MAPPINGS.user_name = `<highlighted>${authInfo.currentUserName}</highlighted>`;
  }
  return inputString.replace(/\{\{([^}]+)\}\}/g, (match, p1) => {
    const mappingValue = VAR_MAPPINGS[p1.trim()];
    return mappingValue !== undefined ? mappingValue : match;
  });
};

const formatCondition = ({ source, operation, value }: ICondition) => `${source} ${operation.toUpperCase()} ${value}`;

export const formatConditionGroups = (groups: IConditionGroup[]) =>
  `${groups.map(group => group.conditions.map(formatCondition).join(' AND ')).join('\n OR\n')}`;

export const sortSteps = (steps: IStepWithoutConditionGroups[] | undefined) => {
  if (!steps) {
    return steps;
  }

  return [...steps].sort((a, b) => (a.name || a.title || '').localeCompare(b.name || b.title || ''));
};
