import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  IGroupedQuotes,
  IQuotesGroup,
  QuoteGroupingOption,
  QuoteOrderingOption
} from '../../../interfaces/IGroupedQuotes';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { QuoteState, QuoteStatus } from '../../../interfaces/IQuote';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

export const AGGREGATED_QUOTES_QUERY_KEY = 'aggregated_quotes';

interface IAggregateQuotesFilters {
  group_by: QuoteGroupingOption;
  order_by: QuoteOrderingOption;

  page: number;
  per_page: number;
  asset_gids: string[];
  carrier_ids: string[];
  policy_types: PolicyType[];

  quotes_request_gid: string | undefined;
  lead_gid: string | undefined;
  source_products?: string[];
  max_date: string | undefined;
  statuses?: QuoteStatus[];
}

const getAggregatedQuotes = <QuotesGroupType = IQuotesGroup>({
  personGid,
  filters
}: {
  personGid: string;
  filters: IAggregateQuotesFilters;
}): Promise<IGroupedQuotes<QuotesGroupType>> => {
  return api.get(`/api/frontend/people/${personGid}/quotes?${toQueryParams(filters)}`);
};

const quotesRefetchInterval = (data: IGroupedQuotes | undefined) => {
  if (!data?.finished_at) {
    return 6000;
  }
  if (data.groups.some(group => group.quotes.some(quote => quote.state === QuoteState.Started))) {
    return 12000;
  }

  return false;
};

const useAggregatedQuotes = <QuotesGroupType = IQuotesGroup>({
  personGid,
  filters,
  options
}: {
  personGid: string | undefined;
  filters: IAggregateQuotesFilters;
  options?: Omit<
    UseQueryOptions<IGroupedQuotes<QuotesGroupType>, any, IGroupedQuotes<QuotesGroupType>, any>,
    'queryKey'
  >;
}) => {
  return useQuery({
    queryKey: [AGGREGATED_QUOTES_QUERY_KEY, personGid, filters],
    queryFn:
      !!personGid && !!filters.quotes_request_gid
        ? () => getAggregatedQuotes<QuotesGroupType>({ personGid: personGid!, filters })
        : skipToken,
    refetchInterval: query => quotesRefetchInterval(query.state.data as IGroupedQuotes | undefined),
    refetchOnWindowFocus: true,
    ...options
  });
};

export default useAggregatedQuotes;
