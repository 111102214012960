/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../../components/common/Tooltip/NewTooltip';
import ButtonWithoutBorder from '../../../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../../../components/core/FlexBox';
import { HelpIcon } from '../../../../../../components/core/icons';
import Text from '../../../../../../components/core/Text';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';
import useQuotesModalView from '../../_hooks/useModalView';
import HeaderCell from './HeaderCell';

const PREDICTION_TIP_ID = 'premium-prediction-tip';

const PremiumSwitch = ({
  isPremiumAnnual,
  setAnnualPremium
}: {
  isPremiumAnnual: boolean;
  setAnnualPremium: (b: boolean) => void;
}) => (
  <>
    <ButtonWithoutBorder
      onClick={() => {
        setAnnualPremium(true);
      }}
    >
      <Text
        bold
        customCss={css`
          color: ${colors.azure50};
          &[aria-selected='true'] {
            color: ${colors.black};
            border-bottom: 2px solid ${colors.black};
          }

          &:hover {
            color: inherit;
          }
        `}
        aria-selected={isPremiumAnnual}
      >
        yr
      </Text>
    </ButtonWithoutBorder>
    <ButtonWithoutBorder
      onClick={() => {
        setAnnualPremium(false);
      }}
    >
      <Text
        bold
        customCss={css`
          color: ${colors.azure50};
          &[aria-selected='true'] {
            color: ${colors.black};
            border-bottom: 2px solid ${colors.black};
          }

          &:hover {
            color: inherit;
          }
        `}
        aria-selected={!isPremiumAnnual}
      >
        mo
      </Text>
    </ButtonWithoutBorder>
  </>
);

const RealPropertyGroupHeader = ({
  isPremiumAnnual,
  setAnnualPremium
}: {
  isPremiumAnnual?: boolean;
  setAnnualPremium?: (b: boolean) => void;
}) => {
  const { quotesModalView } = useQuotesModalView();

  return (
    <FlexBox
      justifySpaceBetween
      borderBottom
      ph={spacings.px16}
      borderColor={colors.grey30}
      borderWidth={2}
      customCss={css`
        min-width: fit-content;
      `}
    >
      <Tooltip
        id={PREDICTION_TIP_ID}
        theme="light"
        primaryColor={colors.grey60}
        secondaryColor={colors.white}
        customCss={css`
          padding: 8px 6px !important;
          max-width: 263px !important;
        `}
      />
      <HeaderCell bordered={false}>
        <Text color={colors.grey60}>Carrier</Text>
      </HeaderCell>
      <HeaderCell width={150}>
        <Text color={colors.grey60}>Carrier response</Text>
      </HeaderCell>
      <HeaderCell width={180}>
        <Text color={colors.grey60} mr={spacings.px4}>
          Premium
        </Text>
        {isPremiumAnnual !== undefined && setAnnualPremium && (
          <PremiumSwitch isPremiumAnnual={isPremiumAnnual} setAnnualPremium={setAnnualPremium} />
        )}
      </HeaderCell>
      <HeaderCell>
        <Text color={colors.grey60}>Coverage</Text>
      </HeaderCell>
      <HeaderCell>
        <Text color={colors.grey60}>Deductible</Text>
      </HeaderCell>
      <HeaderCell width={210}>
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <Text color={colors.grey60}>Savings</Text>
          <Text color={colors.grey60} bold>
            Actual
          </Text>
          <Text color={colors.grey60}>|</Text>
          <Text color={colors.grey60}>Predictive</Text>
          <HelpIcon
            data-for={PREDICTION_TIP_ID}
            // eslint-disable-next-line max-len
            data-tip="Predicted savings are calculated based on the potential premium for the upcoming term, not the current one."
          />
        </FlexBox>
      </HeaderCell>
      {!quotesModalView && (
        <HeaderCell width={180}>
          <Text color={colors.grey60}>Bridge</Text>
        </HeaderCell>
      )}
    </FlexBox>
  );
};

export default RealPropertyGroupHeader;
