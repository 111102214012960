/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Blockquote from '../../../../components/core/Blockquote';
import DropDown from '../../../../components/core/buttons/DropDown';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import BaseForm from '../../../../components/core/forms/BaseForm';
import Heading from '../../../../components/core/Heading';
import { ArrowDownIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import CurrentPolicies from '../../Summary/Comparison/CurrentPolicies';
import { StepContentProps } from '..';

const HeadSection: React.ComponentProps<typeof CurrentPolicies>['renderHeadSection'] = ({
  policiesOptions,
  onSelected,
  isDataEditingForbidden,
  isLifeLead
}) => {
  return (
    <FlexBox gap={spacings.px12} columnDirection>
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <Heading type="h4">Needs assessment</Heading>
        <DropDown options={policiesOptions} onSelected={onSelected} iconChildren disabled={isDataEditingForbidden}>
          <Paragraph color={isDataEditingForbidden ? colors.grey60 : colors.azure50} bold>
            {isLifeLead ? 'Add policy' : 'Add policy and asset'} <ArrowDownIcon />
          </Paragraph>
        </DropDown>
      </FlexBox>
      <Container ml={spacings.px32}>
        <Blockquote
          text={
            <Paragraph color={colors.grey80}>
              At this time, are you bundling your auto with your Current Carrier? Bundling is the biggest discount
              available, and we are a full-service agency. This means we will take a look at all of your insurance
              needs. What vehicles and drivers do we need to insure?
            </Paragraph>
          }
        />
      </Container>
    </FlexBox>
  );
};

const NeedsAssessmentPage = ({ onSubmit }: StepContentProps) => {
  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      controlsWidth={320}
      submitText="Next"
      submitTestId="submit-review-policies-button"
      initialValues={{}}
      onSubmit={onSubmit}
      renderForm={() => {
        return (
          <Container
            customCss={css`
              max-width: 840px;
            `}
          >
            <CurrentPolicies renderHeadSection={HeadSection} />
          </Container>
        );
      }}
    />
  );
};

export default NeedsAssessmentPage;
