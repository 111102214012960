/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import SystemMessage from '../../../components/core/Alert/SystemMessage';
import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import { WarningIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { useToggle } from '../../../hooks';
import { ILoan, IMaticPolicy, IPerson, ITask } from '../../../interfaces';
import { servicerOrLender } from '../../../interfaces/ILender';
import { findPolicyType } from '../../../interfaces/IPolicyType';
import useLead from '../../../queries/leads/useLead';
import usePersonDocuments from '../../../queries/people/person_documents/usePersonDocuments';
import usePersonLoans, { PERSON_LOANS_QUERY_KEY } from '../../../queries/people/usePersonLoans';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import LoanEditor from '../../Customer/CustomerLoans/LoanEditor';
import EmptyAnswer from '../../GuidedSellingExperience/tabs/CollectedInfoTab/_components/EmptyAnswer';
import QuestionAnswerPair from '../../GuidedSellingExperience/tabs/CollectedInfoTab/_components/QuestionAnswerPair';
import useFaxTasks from '../_contexts/useFaxTasks';
import InvoiceGeneration from './InvoiceGeneration';
import SelectLoanModal from './SelectLoanModal';

const LoanOfficerInformation = ({ question, answer }: { question: string; answer: string | undefined | null }) => {
  return (
    <FlexBox>
      <QuestionAnswerPair
        questionLabel={
          <Text
            color={colors.grey60}
            type="small"
            customCss={css`
              width: fit-content;
            `}
          >
            {question}
          </Text>
        }
        answerLabel={
          answer ? (
            <Copy
              type="small"
              value={answer || ''}
              customCss={css`
                width: fit-content;
              `}
            >
              <Text type="small" className="fs-mask">
                {answer}
              </Text>
            </Copy>
          ) : (
            <>
              <EmptyAnswer />
              <WarningIcon
                css={css`
                  width: ${spacings.px16}px;
                  min-width: ${spacings.px16}px;
                  height: ${spacings.px16}px;
                `}
                color={colors.statusOrange}
                data-for={question}
                // eslint-disable-next-line max-len
                data-tip="To ensure a smooth process, it would be helpful to complete this data. We need to contact the loan officer and provide them with the necessary customer documents."
              />
            </>
          )
        }
      />
      <Tooltip id={question} />
    </FlexBox>
  );
};

interface InvoiceTaskDetailsProps {
  person: IPerson;
  assetGid: string | undefined;
  leadId: number;
  task: ITask;
  policy: IMaticPolicy;
  isDataEditingForbidden: boolean;
}

const InvoiceTaskDetails = ({
  person,
  assetGid,
  leadId,
  task,
  policy,
  isDataEditingForbidden
}: InvoiceTaskDetailsProps) => {
  const queryClient = useQueryClient();
  const [editorOpened, toggleEditorOpened] = useToggle(false);
  const [selectLoanModalOpened, toggleSelectLoanModalOpened] = useToggle(false);

  const { data: lead } = useLead(leadId);
  const { data: loans, isFetching: isLoadingPersonLoans } = usePersonLoans({ personGid: person.gid, assetGid });
  const {
    data: documents,
    isFetching: isFetchingDocuments,
    refetch: refetchDocuments
  } = usePersonDocuments({ personGid: person.gid, policyId: policy.id });
  const invoiceDocuments = documents?.filter(document => document.document_type_key === 'invoice') || [];

  const [selectedLoan, setSelectedLoan] = React.useState<ILoan | null>(null);

  React.useEffect(() => {
    setSelectedLoan(currentLoan => {
      if (currentLoan) {
        return loans?.find(loan => loan.gid === currentLoan.gid) || null;
      }

      return loans?.[0] || null;
    });
  }, [loans]);

  const currentCompany = servicerOrLender({ servicer: selectedLoan?.servicer, lender: selectedLoan?.lender });

  React.useEffect(() => () => useFaxTasks.setState({ faxTasks: [] }), []);

  const generateDisplayedInfo = () => {
    if (selectedLoan?.loan_number && currentCompany && !selectedLoan.officer?.email && loans && loans.length > 1) {
      return (
        <SystemMessage
          type="warning"
          heading={`Missing loan officer information for Loan #${selectedLoan.loan_number}
          with ${currentCompany.name}}`}
          mv={spacings.px8}
          description={
            <>
              <Container>
                Please ask the customer for the correct email address of the loan officer. To finalize origination
                leads, you need to send the invoice to the loan officer via email.
              </Container>
              <Button
                variant={ButtonVariant.Text}
                mt={spacings.px8}
                mr={spacings.px8}
                onClick={toggleEditorOpened}
                disabled={!assetGid || isDataEditingForbidden}
              >
                Add information
              </Button>
              <Button
                variant={ButtonVariant.Text}
                onClick={() => toggleSelectLoanModalOpened()}
                disabled={isDataEditingForbidden}
              >
                Change loan
              </Button>
            </>
          }
        />
      );
    } else if (!currentCompany || !selectedLoan?.loan_number) {
      return (
        <SystemMessage
          type="warning"
          heading="Missing loan information"
          mv={spacings.px8}
          description={
            <>
              <Container>
                Please ask the customer for the correct email address of the loan officer. To finalize origination
                leads, you need to send the invoice to the loan officer via email.
              </Container>
              <Button
                variant={ButtonVariant.Text}
                mt={spacings.px8}
                onClick={toggleEditorOpened}
                disabled={!assetGid || isDataEditingForbidden}
              >
                Add information
              </Button>
            </>
          }
        />
      );
    } else {
      return (
        <FlexBox
          backgroundColor={colors.grey5}
          roundBorder
          p={spacings.px12}
          mv={spacings.px8}
          gap={spacings.px8}
          columnDirection
        >
          {!isFetchingDocuments && invoiceDocuments && !isDataEditingForbidden && (
            <InvoiceGeneration
              policy={policy}
              person={person}
              documents={invoiceDocuments}
              refetchDocuments={refetchDocuments}
              task={task}
            />
          )}
          <FlexBox alignItemsCenter gap={spacings.px8}>
            <Paragraph bold>
              Loan #{selectedLoan.loan_number} with {currentCompany.name}
            </Paragraph>
            <Button
              variant={ButtonVariant.Text}
              onClick={() => toggleSelectLoanModalOpened()}
              disabled={isDataEditingForbidden}
            >
              Change loan
            </Button>
          </FlexBox>
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <Paragraph bold type="small">
              Loan officer information
            </Paragraph>
            <IconButton
              disabled={isDataEditingForbidden}
              icon={ButtonIcons.Edit}
              onClick={toggleEditorOpened}
              customCss={css`
                padding: 0;
              `}
            />
          </FlexBox>

          <FlexBox columnDirection>
            <LoanOfficerInformation question="Loan officer name" answer={selectedLoan?.officer?.name} />
            <LoanOfficerInformation question="Email" answer={selectedLoan?.officer?.email} />
            <LoanOfficerInformation question="Phone" answer={selectedLoan?.officer?.phone} />
          </FlexBox>
        </FlexBox>
      );
    }
  };

  if (isLoadingPersonLoans) {
    return <Skeleton count={2} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} />;
  }

  return (
    <>
      {generateDisplayedInfo()}
      {editorOpened && (assetGid || selectedLoan) && (
        <LoanEditor
          personGid={person.gid}
          assetGid={assetGid}
          cancelHandler={toggleEditorOpened}
          loan={selectedLoan || undefined}
          partner={lead?.source_dimensions?.partner}
          confirmHandler={() => {
            toggleEditorOpened();
            queryClient.invalidateQueries({ queryKey: [PERSON_LOANS_QUERY_KEY, person.gid, assetGid] });
          }}
        />
      )}
      {loans && selectLoanModalOpened && (
        <SelectLoanModal
          selectedLoanGid={selectedLoan?.gid || ''}
          cancelHandler={() => toggleSelectLoanModalOpened()}
          confirmHandler={loan => {
            setSelectedLoan(loan);
            toggleSelectLoanModalOpened();
          }}
          loans={loans}
          title={`Select loan for ${findPolicyType(policy.policy_type)?.name.toLowerCase() || '-'}`}
        />
      )}
    </>
  );
};

export default InvoiceTaskDetails;
