/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { ShieldCheckIcon } from '../../../../../components/core/icons';
import Text from '../../../../../components/core/Text';
import { IQuote } from '../../../../../interfaces';
import { isInsurableInterestVehicle } from '../../../../../interfaces/IPolicyType';
import { QuoteKind } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { moneyFormatter } from '../../../../../utils/formatter';
import Coverage from '../../../GuidedQuoting/Quotes/_components/QuoteRow/Coverage';
import Deductible from '../../../GuidedQuoting/Quotes/_components/QuoteRow/Deductible';

interface ICustomerChoicePremiumAndCoverages {
  customerChoice: IQuote;
  isDataCollectionCompleted?: boolean;
}

const CustomerChoicePremiumAndCoverages = ({
  customerChoice,
  isDataCollectionCompleted = false
}: ICustomerChoicePremiumAndCoverages) => {
  const {
    kind,
    attributes: { premium, bundled_premium, policy_type, bundle_options }
  } = customerChoice;

  const isQuoteFinalized = kind === QuoteKind.RC2;

  const coverageCellTitle = isInsurableInterestVehicle(policy_type) ? 'Property damage' : 'Dwelling';
  const deductibleCellTitle = isInsurableInterestVehicle(policy_type) ? 'Liability' : 'Deductible';

  const formattedPremium = moneyFormatter(premium, true);
  const formattedBundledPremium = moneyFormatter(bundled_premium, true);

  const monolineEnabled = !bundle_options || bundle_options.monoline_enabled;

  return (
    <FlexBox>
      <FlexBox
        columnDirection
        gap={spacings.px4}
        customCss={css`
          width: 140px;
        `}
      >
        <Text type="tiny" bold color={colors.grey60}>
          Premium
        </Text>
        <FlexBox alignItemsCenter>
          <Text type="small" bold color={monolineEnabled ? undefined : colors.statusGreen}>
            {monolineEnabled ? formattedPremium : `${formattedPremium} (if bundle)`}
          </Text>
          {isQuoteFinalized && (
            <Container ml={spacings.px4}>
              <ShieldCheckIcon
                color={colors.statusGreen}
                data-tip="Near final price with reports"
                data-for="near-final-price-tooltip"
              />
            </Container>
          )}
          {!isQuoteFinalized && !isDataCollectionCompleted && <Text type="small">(preliminary)</Text>}
        </FlexBox>
        {!!bundled_premium && (
          <Text type="small" color={colors.statusGreen}>
            With bundle: {formattedBundledPremium}
          </Text>
        )}
      </FlexBox>
      <FlexBox
        columnDirection
        gap={spacings.px4}
        customCss={css`
          width: 140px;
        `}
      >
        <Text type="tiny" bold color={colors.grey60}>
          {coverageCellTitle}
        </Text>
        <Coverage quote={customerChoice} type="small" />
      </FlexBox>
      <FlexBox
        columnDirection
        gap={spacings.px4}
        customCss={css`
          width: 140px;
        `}
      >
        <Text type="tiny" bold color={colors.grey60}>
          {deductibleCellTitle}
        </Text>
        <Deductible quote={customerChoice} type="small" />
      </FlexBox>
    </FlexBox>
  );
};

export default CustomerChoicePremiumAndCoverages;
