import { useMutation, useQuery } from '@tanstack/react-query';

import { IConditionGroup, IScoutingConfigVariable, IScoutingStrategyConfig } from '../../interfaces';
import api from '../../services/api';

export const SSC_QUERY_KEY = 'scouting_strategy_configs';

export const useScoutingStrategyConfigs = () =>
  useQuery({
    queryKey: [SSC_QUERY_KEY],
    queryFn: (): Promise<{ scouting_strategy_configs: IScoutingStrategyConfig[] }> =>
      api.get('/api/frontend/scouting_strategy_configs'),
    select: data => data.scouting_strategy_configs.sort((a, b) => a.priority - b.priority)
  });

export const useScoutingStrategyConfigsVariables = () =>
  useQuery({
    queryKey: ['scouting_strategy_configs_variables'],
    queryFn: (): Promise<IScoutingConfigVariable[]> => api.get('/api/frontend/scouting_strategy_configs/variables'),
    staleTime: 10 * 60 * 1000,
    select: data => new Map(data.map((variable: IScoutingConfigVariable) => [variable.key, variable]))
  });

interface ICreateScoutingStrategyConfig {
  key: string;
  name: string;
  steps: { step_id: number; position: number; condition_groups: IConditionGroup[] }[];
  priority?: number;
  condition_groups?: IConditionGroup[];
}

export const useCreateScoutingStrategyConfig = () =>
  useMutation({
    mutationFn: (
      params: ICreateScoutingStrategyConfig
    ): Promise<{ scouting_strategy_config: IScoutingStrategyConfig }> =>
      api.post('/api/frontend/scouting_strategy_configs', { body: params, allowEmpty: true })
  });

interface IUpdateScoutingStrategyConfig {
  id?: number;
  key: string;
  name?: string;
  steps?: { step_id: number; position: number; condition_groups: IConditionGroup[] }[];
  priority?: number;
  condition_groups?: IConditionGroup[];
}

export const useUpdateScoutingStrategyConfig = () =>
  useMutation({
    mutationFn: (params: IUpdateScoutingStrategyConfig) =>
      api.put(`/api/frontend/scouting_strategy_configs/${params.key}`, { body: params, allowEmpty: true })
  });

export const useDeleteScoutingStrategyConfig = (options?: {
  onSuccess?: (_data: any, variables: number | string) => void;
}) =>
  useMutation({
    mutationFn: (key: string) => api.delete(`/api/frontend/scouting_strategy_configs/${key}`),
    ...options
  });
