import * as React from 'react';

import Modal, { ModalProps, ModalSize } from '../../../../components/core/Modal';
import Paragraph from '../../../../components/core/Paragraph';
import { IPerson } from '../../../../interfaces/IPerson';
import { useRemoveRelatedPerson } from '../../../../queries/people/usePersonRelatedPeople';

interface Props {
  person: IPerson;
  relatedPerson: { gid: string; name: string };
  cancelHandler: ModalProps['cancelHandler'];
  confirmHandler: ModalProps['confirmHandler'];
}

const RemovePersonRelationModal = ({ person, relatedPerson, cancelHandler, confirmHandler }: Props) => {
  const { mutateAsync: removeRelatedPerson, isPending: isRemovingRelatedPerson } = useRemoveRelatedPerson();

  return (
    <Modal
      title="Do you want to delete the customer?"
      dangerModal
      size={ModalSize.large}
      confirmHandler={async () => {
        await removeRelatedPerson({ sourcePersonGid: person.gid, relatedPersonGid: relatedPerson.gid });
        await confirmHandler?.();
        cancelHandler?.();
      }}
      cancelHandler={cancelHandler}
      confirmText="Delete"
      confirmationInProgress={isRemovingRelatedPerson}
    >
      <Paragraph className="fs-mask">
        {`You’re going to exclude ${relatedPerson.name} from the list of related to ${person.name} people.
        They will no longer associate with this customer.`}
      </Paragraph>
    </Modal>
  );
};

export default RemovePersonRelationModal;
