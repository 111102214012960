import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { DataCollection } from '../../../../queries/leads/data_collection/useDataCollection';
import usePersonActiveLead from '../../../../queries/people/usePersonActiveLead';
import { isDataCollectionControlsAvailable } from '../../_helpers';
import { useCurrentDispositionSubscriptionContext } from '../../_hooks/useCurrentDispositionSubscription';
import { DataCollectionStepper } from '../../GuidedDataCollection/_hooks/useDataCollectionStepper';
import CandidateFooter from './CandidateFooter';
import FooterSkeleton from './FooterSkeleton';
import LeadFooter from './LeadFooter';

interface IFooter {
  dataCollection: DataCollection | undefined;
  dataCollectionStepper: DataCollectionStepper;
}

const Footer = ({ dataCollection, dataCollectionStepper }: IFooter) => {
  const {
    personGid,
    leadGid,
    lead: openedLead,
    isPersonLinkedToActiveCall,
    insuranceCategory,
    isLoading: isLoadingContext
  } = useGuidedSellingExperienceContext();
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const { data: activeLead, isPending: isPendingActiveLead } = usePersonActiveLead({
    personGid,
    insuranceCategory
  });
  const leadDataCollectionControlsAvailable = isDataCollectionControlsAvailable({
    activeLead,
    openedLead,
    currentDisposition,
    isPersonLinkedToActiveCall
  });

  if ((leadGid && !dataCollection) || isPendingActiveLead || isLoadingContext) {
    return <FooterSkeleton />;
  }

  if (leadGid && !leadDataCollectionControlsAvailable) {
    return null;
  }

  if (leadGid) {
    return <LeadFooter dataCollection={dataCollection} dataCollectionStepper={dataCollectionStepper} />;
  }

  if (!leadDataCollectionControlsAvailable) {
    return null;
  }

  return <CandidateFooter />;
};

export default Footer;
