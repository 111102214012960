/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { setIn, useFormikContext } from 'formik';
import React from 'react';

import { ButtonSize } from '../../../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../../../components/core/buttons/IconButton';
import Container from '../../../../../../components/core/Container';
import FlexBox from '../../../../../../components/core/FlexBox';
import BaseForm from '../../../../../../components/core/forms/BaseForm';
import { SelectField } from '../../../../../../components/core/forms/fields';
import Paragraph from '../../../../../../components/core/Paragraph';
import Text from '../../../../../../components/core/Text';
import usePopper from '../../../../../../hooks/usePopper';
import { IQuote } from '../../../../../../interfaces';
import { AutoCoveragesOptions } from '../../../../../../interfaces/IAutoCoveragesOptions';
import {
  IOpportunity,
  opportunityCoveragesForAsset,
  OpportunityCoveragesTier,
  VehicleOpportunity
} from '../../../../../../interfaces/IOpportunity';
import { buildVehicleTitle, IVehicle } from '../../../../../../interfaces/IVehicle';
import useAutoCoveragesOptions from '../../../../../../queries/auto_coverages/useAutoCoveragesOptions';
// eslint-disable-next-line max-len
import { useUpdateOpportunityCoverages } from '../../../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import { LEAD_OPPORTUNITIES_QUERY_KEY } from '../../../../../../queries/leads/opportunities/useLeadOpportunities';
import useQuotesRequests from '../../../../../../queries/quotes_requests/useQuotesRequests';
import useStartQuoting from '../../../../../../queries/quotes_requests/useStartQuoting';
import analytics from '../../../../../../services/analytics';
import colors from '../../../../../../theme/colors';
import { LabelSize, spacings } from '../../../../../../theme/variables';
import { labelForCoverageTier, vehicleCoverageTierOptions } from '../../../../../../utils/coveragesFormatter';
// eslint-disable-next-line max-len
import VehicleCoveragesEditor from '../../../../GuidedDataCollection/QuotingConfigurationPage/AutoOpportunity/AutoCoveragesEditor';
import useQuotingAvailability from '../../_hooks/useQuotingAvailability';

interface IVehicleCoverageLevel {
  opportunity: VehicleOpportunity;
  customerChoice: IQuote | undefined;
  leadId: number;
  personGid: string;
  refetchQuotesRequests: ReturnType<typeof useQuotesRequests>['refetch'];
}

const SingleVehicleCoverage = ({
  asset,
  index,
  customerChoice,
  elementRef
}: {
  asset: IVehicle;
  index: number;
  customerChoice: IQuote | undefined;
  elementRef: React.RefObject<HTMLDivElement>;
}) => {
  const [editorOpened, setEditorOpened] = React.useState(false);
  const customerChoiceCoverageTier = customerChoice?.attributes.coverages_tier;

  const { data: coveragesOptions } = useAutoCoveragesOptions({
    state: asset.address.state,
    year: asset.year,
    ownership: asset.ownership
  });

  const { setValues } = useFormikContext();

  return (
    <>
      <SelectField
        inline
        id={`vehicles.${index}.coverages_tier`}
        name={`vehicles.${index}.coverages_tier`}
        options={vehicleCoverageTierOptions({
          coveragesOptions: coveragesOptions || [],
          customerChoiceCoverageTier
        })}
        label={buildVehicleTitle(asset)}
        onChange={({ target }) => {
          setValues((values: { vehicles: IOpportunity['agent_selected_coverages'] }) => {
            const valuesCopy = setIn(values, `vehicles.${index}.coverages_tier`, target.value);
            return setIn(valuesCopy, `vehicles.${index}.coverages`, null);
          });

          if (target.value === OpportunityCoveragesTier.Mixed) {
            setEditorOpened(true);
          }
        }}
        labelSize={LabelSize.Small}
      />
      {editorOpened && (
        <VehicleCoveragesEditor
          vehicle={asset}
          onCancel={() => setEditorOpened(false)}
          coveragesOptions={coveragesOptions || ({} as AutoCoveragesOptions)}
          onSetCoverages={coverages => {
            setValues((values: { vehicles: IOpportunity['agent_selected_coverages'] }) =>
              setIn(values, `vehicles.${index}.coverages`, coverages)
            );

            setEditorOpened(false);
          }}
          elementRef={elementRef}
        />
      )}
    </>
  );
};

const VehicleCoverageLevel = ({
  personGid,
  opportunity,
  customerChoice,
  leadId,
  refetchQuotesRequests
}: IVehicleCoverageLevel) => {
  const { anchorEl, setAnchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    style: { zIndex: 1000, padding: '12px' }
  });

  const { mutateAsync: startQuoting } = useStartQuoting();
  const { mutateAsync: updateCoverages } = useUpdateOpportunityCoverages();
  const queryClient = useQueryClient();

  const opportunityAssetsGids = opportunity.assets!.map(({ gid }) => ({
    asset_gid: gid
  }));

  const agentSelectedCoverages = opportunity?.agent_selected_coverages;

  const customerSelectedTier = customerChoice?.attributes?.coverages_tier;
  const agentSelectedTier = agentSelectedCoverages?.[0]?.coverages_tier;

  const { forbidQuoting } = useQuotingAvailability();

  const initialValues = (): IOpportunity['agent_selected_coverages'] => {
    return (
      opportunity.assets?.map(asset => ({
        asset_gid: asset.gid,
        coverages_tier:
          opportunityCoveragesForAsset(opportunity, asset.gid)?.coverages_tier || OpportunityCoveragesTier.Default,
        coverages: opportunityCoveragesForAsset(opportunity, asset.gid)?.coverages || null
      })) || null
    );
  };

  return (
    <FlexBox justifySpaceBetween>
      <FlexBox
        gap={spacings.px6}
        alignItemsCenter
        css={css`
          position: relative;
        `}
      >
        <Container
          border
          css={css`
            border-radius: 8px;
            white-space: nowrap;
            &:hover {
              cursor: pointer;
            }
          `}
          pl={spacings.px12}
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            triggerPopper(e);
            e.stopPropagation();
          }}
        >
          <Text bold type="small">
            Level of protection: {labelForCoverageTier(customerSelectedTier || agentSelectedTier) || '-'}
          </Text>
          <IconButton focus={!!anchorEl} icon={ButtonIcons.Edit} color={colors.black} m={spacings.px4} />
        </Container>

        <div ref={elementRef}>
          <PopperComponent {...popperProps}>
            <FlexBox
              ph={spacings.px24}
              pv={spacings.px12}
              gap={spacings.px8}
              backgroundColor={colors.white}
              boxShadow
              roundBorder
              border
              columnDirection
              customCss={css`
                min-width: 400px;
              `}
            >
              <Paragraph bold>Edit quoting parameters</Paragraph>
              <BaseForm
                buttonsSize={ButtonSize.Small}
                enableReinitialize
                initialValues={{ vehicles: initialValues() }}
                pt={0}
                pb={0}
                pl={0}
                pr={0}
                disabled={forbidQuoting}
                submitText="Save and quote"
                cancelHandler={() => setAnchorEl(null)}
                onSubmit={values => {
                  return updateCoverages({
                    opportunityId: opportunity.id,
                    leadId,
                    coverages: values.vehicles
                  }).then(() =>
                    startQuoting({
                      leadId,
                      personGid,
                      startQuotingRequest: {
                        autos: [{ opportunity_id: opportunity.id, vehicles: opportunityAssetsGids, drivers_gids: [] }]
                      }
                    }).then(() => {
                      analytics.track('Auto re-quoted', {
                        lead_id: leadId,
                        person_gid: personGid,
                        place: 'guided_selling_experience'
                      });
                      refetchQuotesRequests();
                      queryClient.invalidateQueries({ queryKey: [LEAD_OPPORTUNITIES_QUERY_KEY, leadId.toString()] });
                    })
                  );
                }}
                renderForm={() => (
                  <FlexBox gap={spacings.px12} columnDirection>
                    {opportunity?.assets?.map((asset, index) => {
                      return (
                        <SingleVehicleCoverage
                          key={asset.gid}
                          index={index}
                          asset={asset}
                          customerChoice={customerChoice}
                          elementRef={elementRef}
                        />
                      );
                    })}
                  </FlexBox>
                )}
              />
            </FlexBox>
          </PopperComponent>
        </div>
      </FlexBox>
    </FlexBox>
  );
};

export default VehicleCoverageLevel;
