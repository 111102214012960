/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const ScoutingStrategySkeleton = () => (
  <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
    <Container>
      <Heading
        type="h4"
        customCss={css`
          width: 200px;
        `}
      >
        <Skeleton />
      </Heading>

      <FlexBox
        columnDirection
        gap={spacings.px8}
        mt={spacings.px8}
        customCss={css`
          max-width: 480px;
        `}
      >
        {[1, 2, 3, 4].map(index => (
          <FlexBox key={index} alignItemsCenter>
            <Skeleton
              circle
              style={{
                width: '16px',
                height: '16px'
              }}
            />
            <Text
              pl={spacings.px28}
              type="large"
              customCss={css`
                width: 100%;
              `}
            >
              <Skeleton />
            </Text>
          </FlexBox>
        ))}
      </FlexBox>

      <Heading
        mt={spacings.px32}
        type="h4"
        customCss={css`
          width: 200px;
        `}
      >
        <Skeleton />
      </Heading>

      <FlexBox columnDirection gap={spacings.px8} mt={spacings.px8}>
        {[1, 2, 3, 4].map(index => (
          <Text
            key={index}
            type="large"
            customCss={css`
              width: 100%;
            `}
          >
            <Skeleton />
          </Text>
        ))}
      </FlexBox>
    </Container>
  </SkeletonTheme>
);

export default ScoutingStrategySkeleton;
