/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Loader from '../../../../components/common/Loader';
import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import FlexBox from '../../../../components/core/FlexBox';
import Text from '../../../../components/core/Text';
import { spacings } from '../../../../theme/variables';

const OpportunityQuotingProgressMessage = () => (
  <FlexBox mt={spacings.px24}>
    <SystemMessage
      type="info"
      heading={
        <FlexBox gap={spacings.px12} alignItemsCenter>
          <Text
            customCss={css`
              display: block;
            `}
          >
            Opportunity quoting is in progress
          </Text>
          <Loader width={spacings.px20} height={spacings.px20} />
        </FlexBox>
      }
    />
  </FlexBox>
);

export default OpportunityQuotingProgressMessage;
