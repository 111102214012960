import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';

import { useEventListener } from '../../hooks';
import { PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../interfaces/IPolicyType';
import { PaymentOption, QuoteSource } from '../../interfaces/IQuote';
import { AGGREGATED_QUOTES_QUERY_KEY } from '../../queries/people/person_quotes/useAggregatedQuotes';
import { QuotePayload, useCreateQuote } from '../../queries/people/person_quotes/useCreateQuote';
import { QUOTES_REQUESTS_QUERY_KEY } from '../../queries/quotes_requests/useQuotesRequests';
import { moneyFormatter, typedMoneyFormatter } from '../../utils/formatter';

interface AgentAssistCoverageDataType {
  deductible_type?: string;
  deductible_description?: string;
  deductible_value: number | undefined;
  limit_description?: string;
  limit_type?: string;
  limit_value: number | undefined;
  key: string;
}

interface TabData {
  engagementGid: string;
  quoteGid: string;
  personGid: string;
  carrierKey: string;
  assetGids: string[];
  bridgeUrl: string;
  policyType: string;
}

interface HomeQuote {
  offer_type: 'home';
  premium: number;
  coverages: AgentAssistCoverageDataType[];
  payment_options?: PaymentOption[];
  tabData: TabData;
}

interface AutoQuote {
  offer_type: 'auto';
  premium: number;
  assets?: { asset_id?: string; coverages: AgentAssistCoverageDataType[] }[];
  payment_options?: PaymentOption[];
  tabData: TabData;
}
type IAgentAssistQuote = HomeQuote | AutoQuote;

const prepareCoverages = (coverages: AgentAssistCoverageDataType[]) =>
  coverages.map(c => ({
    ...c,
    key: c.key as PolicyCoverageKey,
    deductible_description:
      c.deductible_description ||
      (c.deductible_value ? typedMoneyFormatter(c.deductible_type, c.deductible_value, true) : ''),
    limit_description: c.limit_description || (c.limit_value ? moneyFormatter(c.limit_value, true) : '')
  }));

const prepareQuoteData = (payload: IAgentAssistQuote) => {
  if (payload.offer_type === 'home') {
    const coverages = prepareCoverages(payload.coverages);
    return {
      personGid: payload.tabData.personGid,
      data: {
        source: QuoteSource.CO,
        policy_type: (payload.tabData.policyType || payload.offer_type) as PolicyType,
        carrier_key: payload.tabData.carrierKey,
        premium: payload.premium,
        original_quote_gid: payload.tabData.quoteGid,
        coverages,
        engagement_gid: payload.tabData.engagementGid,
        payment_options: payload.payment_options,
        assets: payload.tabData.assetGids.map(assetGid => ({
          gid: assetGid,
          coverages
        }))
      } satisfies QuotePayload
    };
  }

  if (payload.offer_type === 'auto') {
    const assets =
      payload.assets?.map((asset, index) => ({
        gid: payload.tabData.assetGids[index]!,
        coverages: prepareCoverages(asset.coverages)
      })) || [];

    return {
      personGid: payload.tabData.personGid,
      data: {
        coverages_tier: 'mixed',
        source: QuoteSource.CO,
        policy_type: payload.offer_type as PolicyType,
        carrier_key: payload.tabData.carrierKey,
        premium: payload.premium,
        original_quote_gid: payload.tabData.quoteGid,
        assets,
        payment_options: payload.payment_options,
        engagement_gid: payload.tabData.engagementGid
      } satisfies QuotePayload
    };
  }
};

const isSendQuoteEvent = (
  event: MessageEvent
): event is MessageEvent<{
  type: 'SEND_QUOTE';
  payload: IAgentAssistQuote;
}> => event.data.type === 'SEND_QUOTE';

const AgentAssistIntegration = () => {
  const { mutateAsync: createQuote } = useCreateQuote();
  const queryClient = useQueryClient();
  useEventListener('message', event => {
    if (isSendQuoteEvent(event)) {
      const { payload } = event.data;
      // eslint-disable-next-line no-console
      console.log('Received SEND_QUOTE event', payload);
      const quoteData = prepareQuoteData(payload);

      if (quoteData) {
        createQuote(quoteData)
          .then(() => {
            queryClient.invalidateQueries({ queryKey: [QUOTES_REQUESTS_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [AGGREGATED_QUOTES_QUERY_KEY] });
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.error('Failed to create quote', e);
            Sentry.captureMessage('Could not create quote from agent assist event', 'error');
          });
      }
    }
  });

  return null;
};

export default AgentAssistIntegration;
