/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../../../../components/core/FlexBox';
import { WarningIcon } from '../../../../../../components/core/icons';
import Paragraph from '../../../../../../components/core/Paragraph';
import { IQuote } from '../../../../../../interfaces';
import { QuoteStatus } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';

// If quote is successful, prioritize eligibility explanation that was provided by CRE if it exists,
// otherwise show message or notice.
// For quotes with "success" status message should always be blank and notice can contain various instructions for agent
// For quotes with "complete_manually" status message or notice will contain information why quote needs to be
// completed manually via carrier portal.
export const successfulQuoteMessage = ({ attributes: { eligibility, notice, message, bundle_options } }: IQuote) => {
  const monolineEnabled = !bundle_options || bundle_options.monoline_enabled;

  const monolineOnlyWarning = monolineEnabled ? undefined : 'Carrier requires an auto bundle in this county.';

  if (eligibility && eligibility.rules.length > 0) {
    const eligibilityWarnings = eligibility?.rules
      ?.map(r => r.explanation || 'Explanation for one of the rules is not provided. Please contact support!')
      ?.join('\n\n');

    return monolineOnlyWarning ? `${monolineOnlyWarning}\n\n${eligibilityWarnings}` : eligibilityWarnings;
  }

  const providedMessage = message || notice;

  if (monolineOnlyWarning && providedMessage) {
    return `${monolineOnlyWarning}\n\n${providedMessage}`;
  }

  return monolineOnlyWarning || providedMessage;
};

// For non-successful quotes "message" should always be prioritized over "eligibility" explanation as it contains
// information about the fact why quote was rejected by QS or carrier API.
export const failingQuoteMessage = ({ attributes: { eligibility, message } }: IQuote) => {
  if (message) {
    return message;
  }

  const eligibilityExplanation = eligibility?.rules
    ?.map(r => r.explanation || 'Explanation for one of the rules is not provided. Please contact support!')
    ?.join('\n\n');

  return eligibility && eligibility.rules.length > 0 ? eligibilityExplanation : undefined;
};

const StatusTooltip = ({ quote, additionalMessage }: { quote: IQuote; additionalMessage?: React.ReactNode }) => {
  const messageToShow = [QuoteStatus.Success, QuoteStatus.CompleteManually].includes(quote.status)
    ? successfulQuoteMessage(quote)
    : failingQuoteMessage(quote);

  return (
    <Tooltip id={`${quote.gid}-eligibility`} theme="light">
      <FlexBox>
        <WarningIcon
          color={colors.statusOrange}
          css={css`
            margin-right: 8px;
          `}
        />

        <Paragraph
          customCss={css`
            max-width: 400px;
          `}
          dangerouslySetInnerHTML={{
            __html: messageToShow || ''
          }}
        />
      </FlexBox>
      {additionalMessage}
    </Tooltip>
  );
};

export default StatusTooltip;
