/** @jsxImportSource @emotion/react */
import * as React from 'react';

import FlexBox from '../../components/core/FlexBox';
import { CarIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import { IOpportunity, IQuote } from '../../interfaces';
import { IProposal } from '../../interfaces/IProposal';
import { isMoreThanOneWeekOld } from '../../interfaces/IQuote';
import { generateAutoDescription, IVehicle } from '../../interfaces/IVehicle';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { useProposalContext } from './_hooks/useProposalContext';
import AutoQuoteForm from './AutoQuoteForm';
import AutoQuoteInfo from './AutoQuoteInfo';
import QuotesSelect from './QuotesSelect';

const AutoQuotes = ({
  opportunity,
  quotes,
  currentProposal,
  isFetchingQuotes
}: {
  opportunity: IOpportunity;
  quotes: IQuote[];
  currentProposal: IProposal | undefined;
  isFetchingQuotes?: boolean;
}) => {
  const [openQuoteEditor, setOpenQuoteEditor] = React.useState(false);
  const getInitialSelectedQuote = () =>
    currentProposal?.proposal_options?.[0]?.quotes.find(
      quote => quote.attributes.asset_gids?.[0] === opportunity.assets?.[0]?.gid
    )?.gid;
  const [selectedQuoteKey, setSelectedQuoteKey] = React.useState(getInitialSelectedQuote() || '');
  const selectedQuote = quotes.find(quote => quote.gid === selectedQuoteKey);

  const { setQuotesForOpportunities } = useProposalContext();

  const opportunityId = opportunity.id;

  React.useEffect(() => {
    setQuotesForOpportunities(opportunityId, selectedQuoteKey);
  }, [opportunityId, selectedQuoteKey, setQuotesForOpportunities]);

  const showResetButton =
    currentProposal?.proposal_options?.[0]?.quotes && currentProposal?.proposal_options?.[0]?.quotes.length > 1;

  const isDisabled =
    !!currentProposal?.sent_at ||
    !!currentProposal?.canceled_at ||
    (currentProposal && isMoreThanOneWeekOld(currentProposal.created_at));

  return (
    <FlexBox mv={spacings.px20} gap={spacings.px16}>
      <CarIcon />
      <FlexBox columnDirection>
        <Paragraph>{opportunity.title}</Paragraph>
        <Paragraph color={colors.grey60}>{generateAutoDescription(opportunity.assets as IVehicle[])}</Paragraph>
        <QuotesSelect
          quotes={isDisabled ? quotes.concat(currentProposal.proposal_options[0]?.quotes || []) : quotes}
          selectId={opportunity.id.toString()}
          setOpenQuoteEditor={setOpenQuoteEditor}
          openQuoteEditor={openQuoteEditor}
          selectedQuoteKey={selectedQuoteKey}
          setSelectedQuoteKey={setSelectedQuoteKey}
          loading={isFetchingQuotes}
          showResetButton={showResetButton}
          disabled={isDisabled}
        />
        {openQuoteEditor && (
          <AutoQuoteForm
            policyType={opportunity.policy_type}
            setOpenQuoteEditor={setOpenQuoteEditor}
            quote={selectedQuote}
            opportunityAssets={opportunity.assets as IVehicle[]}
            setSelectedQuoteKey={setSelectedQuoteKey}
          />
        )}
        {selectedQuoteKey && !openQuoteEditor && selectedQuote && (
          <AutoQuoteInfo
            setOpenQuoteEditor={setOpenQuoteEditor}
            quote={selectedQuote}
            opportunityAssets={opportunity.assets as IVehicle[]}
            disabled={isDisabled}
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default AutoQuotes;
