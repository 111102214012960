/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { isLeadDataEditingForbidden } from '../../../components/DispositionsModals/dispositionsHelper';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import useLeadOpportunities from '../../../queries/leads/opportunities/useLeadOpportunities';
import useOpportunitiesWithPolicies from '../../../queries/leads/opportunities/useOpportunitiesWithPolicies';
import useLead from '../../../queries/leads/useLead';
import usePerson from '../../../queries/people/usePerson';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import usePersonRelatedPeople from '../../../queries/people/usePersonRelatedPeople';
import useQuotesRequests from '../../../queries/quotes_requests/useQuotesRequests';
import { findMatchingQuote } from '../../GuidedSellingExperience/GuidedQuoting/Quotes/_helpers/quoteSavingsHelper';
import useCustomerChoices from '../../GuidedSellingExperience/GuidedQuoting/Quotes/_hooks/useCustomerChoices';
import LeadInfo from './LeadInfo';
import LeadInfoPlaceholder from './LeadInfo/LeadInfoPlaceholder';
import LeadOperations from './LeadOperations';
import LeadOperationsPlaceholder from './LeadOperations/LeadOperationsPlaceholder';
import Opportunities from './Opportunities';
import PrimaryContact from './PrimaryContact';
import PrimaryContactPlaceholder from './PrimaryContact/PrimaryContactPlaceholder';
import PrimaryOpportunityLoan from './PrimaryOpportunityLoan';
import PrimaryOpportunityLoanPlaceholder from './PrimaryOpportunityLoan/PrimaryOpportunityLoanPlaceholder';
import SummaryPlaceholder from './SummaryPlaceholder';

const LeadSummary = ({ personGid, leadId, leadGid }: { personGid: string; leadGid: string; leadId: number }) => {
  const { data: lead, isLoading: isLoadingLead } = useLead(leadGid);
  const {
    data: opportunities,
    isLoading: isLoadingOpportunities,
    refetch: refetchOpportunities
  } = useLeadOpportunities({ leadId });
  const { data: leadDispositions, isLoading: isLoadingDisposition } = useLeadDispositions(leadId);

  const { data: person, isLoading: isLoadingPerson } = usePerson(personGid);
  const { data: relatedPeople, isLoading: isLoadingRelatedPeople } = usePersonRelatedPeople(personGid);

  const primaryOpportunity = opportunities?.find(({ primary }) => primary);
  const primaryOpportunityAsset = primaryOpportunity?.assets?.[0];

  const { data: loans, isLoading: isLoadingLoans } = usePersonLoans({
    personGid,
    assetGid: primaryOpportunityAsset?.gid
  });

  const { opportunitiesWithPolicies } = useOpportunitiesWithPolicies({ personGid, opportunities });

  const { data: quotesRequests } = useQuotesRequests({ personGid, leadGid });
  const { customerChoices } = useCustomerChoices({ personGid, leadGid, quotesRequests });
  const opportunitiesWithPoliciesAndCustomerChoices = opportunitiesWithPolicies?.map(opportunity => ({
    ...opportunity,
    customerChoice: findMatchingQuote(opportunity, customerChoices)
  }));

  const isLoading =
    isLoadingLead ||
    isLoadingOpportunities ||
    isLoadingDisposition ||
    isLoadingPerson ||
    isLoadingRelatedPeople ||
    isLoadingLoans;

  return (
    <FlexBox
      fitParentWidth
      customCss={css`
        max-width: 100%;
      `}
      justifyRight
    >
      <FlexBox fitParentWidth columnDirection>
        {isLoading && <SummaryPlaceholder />}
        {!isLoading && opportunitiesWithPoliciesAndCustomerChoices && leadDispositions && (
          <Opportunities
            personGid={personGid}
            leadId={leadId}
            opportunities={opportunitiesWithPoliciesAndCustomerChoices}
            currentDisposition={leadDispositions.current_disposition}
            allowCreatingOpportunities={
              !isLeadDataEditingForbidden(leadDispositions.current_disposition.disposition_type)
            }
            onOpportunitiesUpdated={refetchOpportunities}
          />
        )}
        <Grid container mt={4}>
          <Grid item xs={4}>
            {isLoading && <PrimaryContactPlaceholder />}
            {!isLoading && person && (
              <PrimaryContact leadGid={leadGid} person={person} relatedPeople={relatedPeople || []} />
            )}
          </Grid>
          <Grid item xs={4}>
            {isLoading && <LeadInfoPlaceholder />}
            {!isLoading && lead && leadDispositions && <LeadInfo lead={lead} />}
          </Grid>
          <Grid item xs={3.5}>
            {isLoading && <PrimaryOpportunityLoanPlaceholder />}
            {!isLoading && primaryOpportunity && loans?.[0] && (
              <PrimaryOpportunityLoan
                loan={loans[0]}
                leadId={leadId}
                leadGid={leadGid}
                primaryOpportunityPolicyType={primaryOpportunity.policy_type}
              />
            )}
          </Grid>
        </Grid>
      </FlexBox>
      <div
        css={css`
          padding-left: 24px;
          width: 385px;
        `}
        data-testid="lead-operations"
      >
        {isLoading && <LeadOperationsPlaceholder />}
        {!isLoading && <LeadOperations personGid={personGid} leadId={leadId} leadGid={leadGid} />}
      </div>
    </FlexBox>
  );
};

export default LeadSummary;
