/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Container from '../../../../../../components/core/Container';
import FlexBox from '../../../../../../components/core/FlexBox';
import { ChevronRight } from '../../../../../../components/core/icons';
import Paragraph from '../../../../../../components/core/Paragraph';
import Tag from '../../../../../../components/core/Tag';
import Text from '../../../../../../components/core/Text';
import featureFlags from '../../../../../../constants/featureFlags';
import useToggle from '../../../../../../hooks/useToggle';
import { IGroupedQuotes, IOpportunity, IQuote } from '../../../../../../interfaces';
import { CarrierKey } from '../../../../../../interfaces/ICarrier';
import {
  isInsurableInterestRealProperty,
  isInsurableInterestVehicle,
  PolicyType
} from '../../../../../../interfaces/IPolicyType';
import { QuoteState, QuoteStatus } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';
import { borderRadius, spacings } from '../../../../../../theme/variables';
import { isQuoteSelectedByCustomer } from '../../_helpers';
import { getCoverageBackgroundColor, getDwellingBackgroundColor } from '../../_helpers/getCoveragesBackgroundColor';
import useQuotesModalView from '../../_hooks/useModalView';
import useSelectedQuote from '../../_hooks/useSelectedQuote';
import AllstateGuide from '../AllstateGuide';
import Bridge from './Bridge';
import Coverage from './Coverage';
import Deductible from './Deductible';
import DeprecatedStatus from './DeprecatedStatus';
import ExpandedAuto from './ExpandedAuto';
import ExpandedHome from './ExpandedHome';
import InProgressQuoteRow from './InProgressQuoteRow';
import Premium from './Premium';
import Savings from './Savings';
import Status from './Status';

interface IQuoteRowProps {
  quote: IQuote;
  assets: IGroupedQuotes['assets'];
  showMpd: boolean;
  isPremiumAnnual?: boolean;
  opportunity?: IOpportunity;
}

const QuoteRow = ({ quote, assets, isPremiumAnnual, opportunity }: IQuoteRowProps) => {
  const { quotesModalView } = useQuotesModalView();
  const { setSelectedQuoteGid } = useSelectedQuote();
  const [isExpanded, toggleExpanded] = useToggle(false);

  const { attributes, state } = quote;
  const { policy_type, carrier } = attributes;

  if (state === QuoteState.Started) {
    return <InProgressQuoteRow quote={quote} />;
  }

  const isQuoteSelected = isQuoteSelectedByCustomer(quote);

  const isQuoteInfoAvailable = QuoteStatus.Success === quote.status;
  const isExtendedCoveragesAvailable =
    isQuoteInfoAvailable && ![PolicyType.PAF, PolicyType.Pet].some(type => type === policy_type);

  return (
    <FlexBox
      columnDirection
      borderBottom={!isQuoteSelected}
      roundBorder
      backgroundColor={colors.white}
      border={!!isQuoteSelected}
      borderColor={isQuoteSelected ? colors.violet : colors.grey10}
      mt={isQuoteSelected ? spacings.px12 : spacings.px0}
      customCss={css`
        min-width: fit-content;
      `}
    >
      <FlexBox
        justifySpaceBetween
        mh={spacings.px16}
        mv={spacings.px6}
        alignItemsCenter
        customCss={css`
          position: relative;
        `}
      >
        {isQuoteSelected && (
          <Tag
            label="Customer’s choice"
            backgroundColor={colors.violet}
            borderRadius={borderRadius}
            customCss={css`
              left: ${spacings.px8}px;
              top: ${spacings['-px12']}px;
              position: absolute;
            `}
          />
        )}
        <Container
          pv={spacings.px4}
          customCss={css`
            min-width: 140px;
          `}
        >
          <span data-tip={carrier?.name} data-for="carrier-name-tooltip">
            <div
              css={css`
                background-position: left;
                background-repeat: no-repeat;
                background-size: contain;
                height: 30px;
                margin: 0 auto;
                width: 100px;
                filter: grayscale(100%);
                ${carrier?.logo ? `background-image: url(${carrier.logo})` : ''};
              `}
              title={carrier?.name}
            />
          </span>
        </Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 150px;
          `}
        >
          {featureFlags.newQuotesUI ? <Status quote={quote} /> : <DeprecatedStatus quote={quote} />}
        </Container>

        <Container
          color={colors.grey60}
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 200px;
          `}
        >
          {isQuoteInfoAvailable && <Premium quote={quote} isPremiumAnnual={isPremiumAnnual} />}
          {!isQuoteInfoAvailable && attributes.promotion_message && (
            <Container
              customCss={css`
                width: 180px;
              `}
            >
              <Text
                customCss={css`
                  white-space: nowrap;
                `}
              >
                {attributes.promotion_message}
              </Text>
            </Container>
          )}
        </Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px8}
          fitParentHeight
          backgroundColor={getCoverageBackgroundColor({ quote, opportunity })}
          customCss={css`
            min-width: 140px;
          `}
        >
          {isQuoteInfoAvailable && <Coverage quote={quote} />}
        </Container>
        <Container
          backgroundColor={colors.white}
          fitParentHeight
          customCss={css`
            width: 1px;
          `}
        ></Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px8}
          fitParentHeight
          backgroundColor={getDwellingBackgroundColor({ quote, opportunity })}
          customCss={css`
            min-width: 140px;
            padding-left: 7px;
          `}
        >
          {isQuoteInfoAvailable && <Deductible quote={quote} />}
        </Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 210px;
          `}
        >
          {isQuoteInfoAvailable && <Savings quote={quote} />}
        </Container>

        {!quotesModalView && (
          <Container
            pl={spacings.px8}
            pv={spacings.px4}
            customCss={css`
              min-width: 180px;
            `}
          >
            <FlexBox alignItemsCenter justifySpaceBetween>
              <Bridge quote={quote} onBridge={quoteGid => setSelectedQuoteGid(quoteGid)} />
              {isExtendedCoveragesAvailable && (
                <FlexBox
                  justifyRight
                  gap={spacings.px4}
                  pl={spacings.px8}
                  alignItemsCenter
                  customCss={css`
                    cursor: pointer;
                  `}
                  onClick={toggleExpanded}
                >
                  <Paragraph>Details</Paragraph>
                  <ChevronRight
                    css={css`
                      transform: rotate(${isExpanded ? '270deg' : '90deg'});
                      transition: transform 0.2s;
                      position: relative;
                      top: 2px;
                    `}
                  />
                </FlexBox>
              )}
              {!isExtendedCoveragesAvailable && carrier?.key === CarrierKey.Allstate && (
                <AllstateGuide boldText={false} />
              )}
            </FlexBox>
          </Container>
        )}
      </FlexBox>
      {isExpanded && (
        <Container
          mt={spacings.px8}
          pt={spacings.px8}
          css={css`
            border-top: solid 1px ${colors.grey10};
          `}
        >
          {isInsurableInterestRealProperty(policy_type) && (
            <ExpandedHome quoteAttributes={attributes} assets={assets} />
          )}
          {isInsurableInterestVehicle(policy_type) && <ExpandedAuto quoteAttributes={attributes} assets={assets} />}
        </Container>
      )}
    </FlexBox>
  );
};

export default QuoteRow;
