/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Paragraph from '../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import {
  IAssetsQuotesGroup,
  QuoteGroupingOption,
  QuoteOrderingOption,
  RESULTS_PER_PAGE
} from '../../interfaces/IGroupedQuotes';
import { isAncillary, isInsurableInterestRealProperty, PolicyType, Requirement } from '../../interfaces/IPolicyType';
import { IProposal } from '../../interfaces/IProposal';
import { QuoteKind, QuoteState, QuoteStatus } from '../../interfaces/IQuote';
import useOpportunitiesWithPolicies from '../../queries/leads/opportunities/useOpportunitiesWithPolicies';
// eslint-disable-next-line max-len
import useOpportunitiesWithPremiumPredictions from '../../queries/leads/opportunities/useOpportunitiesWithPremiumPredictions';
import usePersonOpportunities from '../../queries/people/person_opportunities/usePersonOpportunities';
import useAggregatedQuotes from '../../queries/people/person_quotes/useAggregatedQuotes';
import useQuotesRequests from '../../queries/quotes_requests/useQuotesRequests';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import augmentQuotesGroups from '../GuidedSellingExperience/GuidedQuoting/Quotes/_helpers/quotesAugmentationHelper';
import useSyncProposal from './_hooks/useSyncProposal';
import AutoQuotes from './AutoQuotes';
import HomeQuotes from './HomeQuotes';

const ProposalSummary = ({ currentProposal }: { currentProposal: IProposal | undefined }) => {
  const { personGid, leadGid, lead } = useGuidedSellingExperienceContext();
  const { data: opportunities = [], isLoading: isLoadingOpportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    requirement: Requirement.Essential
  });
  const filteredOpportunities = opportunities.filter(({ policy_type }) => !isAncillary(policy_type));
  const { data: quotesRequests, isLoading: isLoadingQuotesRequests } = useQuotesRequests({
    personGid,
    leadGid
  });

  // TODO: handle case when there are no quotes at all, we still want to be able to create a proposal
  const {
    data: groupedQuotes,
    isLoading: isLoadingQuotes,
    isFetching: isFetchingQuotes
  } = useAggregatedQuotes<IAssetsQuotesGroup>({
    personGid,
    filters: {
      lead_gid: leadGid,
      quotes_request_gid: quotesRequests?.[0]?.gid,
      max_date: undefined,
      group_by: QuoteGroupingOption.Asset,
      order_by: QuoteOrderingOption.CheapestFirst,
      page: 1,
      per_page: RESULTS_PER_PAGE,
      asset_gids: filteredOpportunities.flatMap(({ assets }) => (assets || []).map(({ gid }) => gid)).sort(),
      policy_types: filteredOpportunities.map(({ policy_type }) => policy_type),
      carrier_ids: []
    }
  });

  const { opportunitiesWithPolicies } = useOpportunitiesWithPolicies({
    personGid,
    opportunities: filteredOpportunities
  });
  const { opportunitiesWithPremiumPredictions, isLoading: isLoadingOpportunitiesWithPremiumPrediction } =
    useOpportunitiesWithPremiumPredictions({ opportunitiesWithPolicies });

  const augmentedQuotes = augmentQuotesGroups({
    groupedQuotes,
    opportunitiesWithPremiumPredictions,
    personGid: personGid!,
    leadGid,
    leadId: lead!.id,
    quotesSourceProduct: quotesRequests?.[0]?.source_product
  });

  const isLoading =
    isLoadingOpportunities || isLoadingQuotesRequests || isLoadingQuotes || isLoadingOpportunitiesWithPremiumPrediction;

  useSyncProposal({ currentProposal, personGid: personGid!, leadGid: leadGid! });

  return (
    <FlexBox p={spacings.px12} columnDirection>
      <Paragraph type="large" bold>
        Your proposal summary
      </Paragraph>
      {isLoading ? (
        <Container pt={spacings.px12}>
          <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
            <Skeleton height={108} />
            <Container
              mv={spacings.px20}
              backgroundColor={colors.grey30}
              customCss={css`
                height: 1px;
              `}
            />
            <Skeleton height={108} />
          </SkeletonTheme>
        </Container>
      ) : (
        <>
          {augmentedQuotes?.groups?.map((group, index) => {
            const sortedQuotes = group.quotes
              .sort((a, b) => b.created_at.localeCompare(a.created_at))
              .filter(
                quote =>
                  quote.kind === QuoteKind.RC2 &&
                  quote.state === QuoteState.Completed &&
                  quote.status === QuoteStatus.Success
              );

            const assetIsPresent = Number(group.opportunity.assets?.length) > 0;

            return (
              <Container
                key={group.opportunity.id}
                css={css`
                  position: relative;
                  &::after {
                    content: '';
                    display: ${index < augmentedQuotes.groups.length - 1 && assetIsPresent ? 'block' : 'none'};
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: ${colors.grey30};
                  }
                `}
              >
                {isInsurableInterestRealProperty(group.opportunity.policy_type) && assetIsPresent && (
                  <HomeQuotes
                    opportunity={group.opportunity}
                    key={group.opportunity.id}
                    quotes={sortedQuotes}
                    isFetchingQuotes={isFetchingQuotes}
                    currentProposal={currentProposal}
                  />
                )}
                {group.opportunity.policy_type === PolicyType.Auto && assetIsPresent && (
                  <AutoQuotes
                    opportunity={group.opportunity}
                    key={group.opportunity.id}
                    quotes={sortedQuotes}
                    currentProposal={currentProposal}
                    isFetchingQuotes={isFetchingQuotes}
                  />
                )}
              </Container>
            );
          })}
        </>
      )}
    </FlexBox>
  );
};

export default ProposalSummary;
