import analytics from '../../../services/analytics';
import Quotes from './Quotes';
import { QuotesModalViewContext } from './Quotes/_hooks/useModalView';

const GuidedQuoting = ({
  onConfigureQuoting,
  personGid,
  leadGid,
  leadId
}: {
  onConfigureQuoting: () => void;
  personGid: string;
  leadGid: string | undefined;
  leadId: number | undefined;
}) => {
  return (
    <QuotesModalViewContext.Provider value={{ quotesModalView: false }}>
      <Quotes
        leadId={leadId}
        leadGid={leadGid}
        personGid={personGid}
        onGetQuotes={() => {
          analytics.track('Get quotes clicked', {
            lead_gid: leadGid,
            person_gid: personGid,
            place: 'guided_selling_experience'
          });
          return onConfigureQuoting();
        }}
      />
    </QuotesModalViewContext.Provider>
  );
};

export default GuidedQuoting;
