/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../../../components/core/FlexBox';
import { MultiSelectField } from '../../../../../components/core/forms/fields';
import { isAutoAssetNotQuotable, IVehicle } from '../../../../../interfaces/IVehicle';
import { InputSize, spacings } from '../../../../../theme/variables';
import { IQuotingConfigurationOpportunity } from '../_interfaces/IQuotingConfigurationOpportunity';

const AutoInsuredVehicles = ({ opportunity }: { opportunity: IQuotingConfigurationOpportunity }) => {
  const assetsOptions =
    opportunity.assets
      ?.filter(asset => !isAutoAssetNotQuotable((asset as IVehicle).kind))
      ?.map(({ description_without_icon, gid }) => {
        return { key: gid, value: description_without_icon };
      }) || [];

  return (
    <FlexBox ml={spacings['-px4']} pv={spacings.px4} gap={spacings.px12}>
      <MultiSelectField
        inline
        label="Insured vehicles"
        key={`opportunities.${opportunity.id}.assets_gids`}
        id={`opportunities.${opportunity.id}.assets_gids`}
        name={`opportunities.${opportunity.id}.assets_gids`}
        options={assetsOptions}
        css={css`
          flex-grow: 1;
        `}
        inputSize={InputSize.Large}
      />
    </FlexBox>
  );
};

export default AutoInsuredVehicles;
