import { init } from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { isAxiosError } from 'axios';

import analytics from '../../services/analytics';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';

const setupConfig = (config: Record<string, any>) => {
  if (config && Object.keys(window.__amp_config).length === 0) {
    window.__amp_config = config;
  }
};

const setupAnalytics = (config: Record<string, any>) => analytics.load(config);

const setupMaskHighlight = (appEnv: 'staging' | 'production') => {
  if (appEnv === 'production') {
    return;
  }

  const STYLESHEET_ID = 'fs-mask-stylesheet';

  const highlightMaskStylesheet = document.createElement('style');
  highlightMaskStylesheet.id = STYLESHEET_ID;
  highlightMaskStylesheet.textContent = `
      .fs-mask {
        background: repeating-linear-gradient(
          45deg,
          ${colors.white},
          ${colors.white} ${spacings.px6}px,
          ${colors.grey30} ${spacings.px6}px,
          ${colors.grey30} ${spacings.px12}px
        );
        outline: 1px solid ${colors.grey30};
      }
    `;
  document.addEventListener('keydown', event => {
    // Check if the shift + option + Z key is pressed
    if (event.shiftKey && event.altKey && event.code === 'KeyZ') {
      if (document.getElementById(STYLESHEET_ID)) {
        document.getElementById(STYLESHEET_ID)?.remove();
      } else {
        document.head.appendChild(highlightMaskStylesheet);
      }
    }
  });
};

const IGNORED_ERRORS = [
  'ResizeObserver loop completed with undelivered notifications',
  "Failed to execute 'importScripts' on 'WorkerGlobalScope'"
];

const setupSentry = (config: Record<string, any>) => {
  if (import.meta.env.PROD && config.sentry) {
    Sentry.init({
      dsn: config.sentry_dsn,
      environment: config.sentry_environment,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      normalizeDepth: 10,
      denyUrls: [/^chrome:\/\//i, /^chrome-extension:\/\//i],
      beforeSend: (event, hint) => {
        const error = hint.originalException;
        if (isAxiosError(error)) {
          return null;
        }

        if (IGNORED_ERRORS.some(substring => (error as any)?.message?.includes(substring))) {
          return null;
        }
        return event;
      }
    });
  }
};

const setupFullStory = (config: Record<string, any>) => {
  if (import.meta.env.PROD && config.fullstory) {
    init({ orgId: config.fullstory_org_id });
  }
};

export { setupAnalytics, setupConfig, setupFullStory, setupMaskHighlight, setupSentry };
