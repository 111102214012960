/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import * as yup from 'yup';

import { ButtonSize, ButtonVariant } from '../../../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../../../components/core/buttons/IconButton';
import Container from '../../../../../../components/core/Container';
import FlexBox from '../../../../../../components/core/FlexBox';
import BaseForm from '../../../../../../components/core/forms/BaseForm';
import { NumericField } from '../../../../../../components/core/forms/fields';
import { DollarIcon } from '../../../../../../components/core/icons';
import Paragraph from '../../../../../../components/core/Paragraph';
import Text from '../../../../../../components/core/Text';
import usePopper from '../../../../../../hooks/usePopper';
import { OpportunityCoveragesTier, PAFOpportunity } from '../../../../../../interfaces/IOpportunity';
import { coverageByKey, PolicyCoverageKey } from '../../../../../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../../../../../interfaces/IPolicyType';
import { StartQuotingResponse } from '../../../../../../interfaces/IQuotesRequests';
// eslint-disable-next-line max-len
import { useUpdateOpportunityCoverages } from '../../../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import { createOpportunity } from '../../../../../../queries/leads/opportunities/useLeadOpportunities';
import useStartQuoting from '../../../../../../queries/quotes_requests/useStartQuoting';
import analytics from '../../../../../../services/analytics';
import colors from '../../../../../../theme/colors';
import { LabelSize, spacings } from '../../../../../../theme/variables';
import { moneyFormatter } from '../../../../../../utils/formatter';
import { REQUIRED_MESSAGE } from '../../../../../../utils/yupRules';
import useQuotingAvailability from '../../_hooks/useQuotingAvailability';

interface IPAFCoverageLevel {
  pafOpportunity: PAFOpportunity;
  leadId: number;
  personGid: string;
  refetchQuotesRequests: () => void;
}
const PAFCoverageLevel = ({ personGid, pafOpportunity, leadId, refetchQuotesRequests }: IPAFCoverageLevel) => {
  const { mutateAsync: updateOpportunity } = useUpdateOpportunityCoverages();
  const { anchorEl, setAnchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    style: { padding: '12px', zIndex: 999 }
  });

  const updateOpportunityCoverages = (paf_coverage_level: string) =>
    updateOpportunity({
      opportunityId: pafOpportunity.id,
      leadId,
      coverages: [
        {
          asset_gid: null,
          coverages_tier: OpportunityCoveragesTier.Quoted,
          coverages: [
            {
              key: PolicyCoverageKey.Jewelry,
              limit_value: paf_coverage_level,
              limit_option_gid: null,
              deductible_value: null
            }
          ]
        }
      ]
    });

  const { mutateAsync: startQuoting } = useStartQuoting();

  const updatePAFOpportunity = (paf_coverage_level: string): Promise<StartQuotingResponse> =>
    updateOpportunityCoverages(paf_coverage_level).then(() => {
      analytics.track('PAF re-quoted', {
        lead_id: leadId,
        person_gid: personGid,
        place: 'guided_selling_experience'
      });

      return startQuoting({
        leadId,
        personGid,
        startQuotingRequest: { new_paf: { opportunity_id: pafOpportunity.id } }
      });
    });

  const createPAFOpportunity = (paf_coverage_level: string): Promise<StartQuotingResponse> =>
    createOpportunity({
      leadId,
      payload: {
        policy_type: PolicyType.PAF,
        agent_selected_coverages: [
          {
            asset_gid: null,
            coverages_tier: OpportunityCoveragesTier.Quoted,
            coverages: [
              {
                key: PolicyCoverageKey.Jewelry,
                limit_value: paf_coverage_level,
                limit_option_gid: null,
                deductible_value: null
              }
            ]
          }
        ]
      }
    }).then(data => {
      analytics.track('PAF re-quoted', {
        lead_id: leadId,
        person_gid: personGid,
        place: 'guided_selling_experience'
      });
      return startQuoting({
        leadId,
        personGid,
        startQuotingRequest: { new_paf: { opportunity_id: data.opportunity.id } }
      });
    });

  const validationSchema = yup.object({
    paf_coverage_level: yup.number().positive('Must be a positive number').required(REQUIRED_MESSAGE)
  });

  const coverageLevel = coverageByKey(
    pafOpportunity.agent_selected_coverages?.[0]?.coverages,
    PolicyCoverageKey.Jewelry
  )?.limit_value;

  const { forbidQuoting } = useQuotingAvailability();

  return (
    <FlexBox>
      <FlexBox gap={spacings.px6} alignItemsCenter>
        <Container
          border
          css={css`
            border-radius: 8px;
            white-space: nowrap;
            &:hover {
              cursor: pointer;
            }
          `}
          pl={spacings.px12}
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            triggerPopper(e);
            e.stopPropagation();
          }}
        >
          <Text bold type="small">
            Coverage level: {moneyFormatter(coverageLevel, true) || '-'}
          </Text>
          <IconButton focus={!!anchorEl} icon={ButtonIcons.Edit} color={colors.black} m={spacings.px4} />
        </Container>
        <div ref={elementRef}>
          <PopperComponent {...popperProps}>
            <FlexBox
              ph={spacings.px24}
              pv={spacings.px12}
              gap={spacings.px8}
              boxShadow
              roundBorder
              border
              backgroundColor={colors.white}
              columnDirection
              customCss={css`
                min-width: 400px;
              `}
            >
              <Paragraph bold>Edit quoting parameters</Paragraph>
              <BaseForm
                pt={0}
                pr={0}
                pb={0}
                pl={0}
                initialValues={{
                  paf_coverage_level: coverageLevel || ''
                }}
                disabled={forbidQuoting}
                validationSchema={validationSchema}
                cancelHandler={() => setAnchorEl(null)}
                cancelVariant={ButtonVariant.Secondary}
                submitText="Save and quote"
                onSubmit={async values => {
                  return (
                    pafOpportunity
                      ? updatePAFOpportunity(values.paf_coverage_level!)
                      : createPAFOpportunity(values.paf_coverage_level!)
                  )
                    .then(() => {
                      refetchQuotesRequests();
                    })
                    .then(() => setAnchorEl(null));
                }}
                buttonsSize={ButtonSize.Small}
                renderForm={() => (
                  <NumericField
                    autoFocus
                    required
                    inline
                    label="Coverage"
                    id="paf_coverage_level"
                    name="paf_coverage_level"
                    prefixIcon={<DollarIcon />}
                    valueIsNumber
                    labelSize={LabelSize.Small}
                  />
                )}
              />
            </FlexBox>
          </PopperComponent>
        </div>
      </FlexBox>
    </FlexBox>
  );
};

export default PAFCoverageLevel;
