/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import FlexBox from '../../../../components/core/FlexBox';
import { HeadingProps } from '../../../../components/core/Heading';
import Paragraph from '../../../../components/core/Paragraph';
import useKeyPress from '../../../../hooks/useKeyPress';
import { spacings } from '../../../../theme/variables';
import { IDataCollectionStep } from '../_interfaces/IDataCollectionStep';
import DataCollectionStep from './DataCollectionStep';

export interface DataCollectionNavigationProps {
  steps: IDataCollectionStep[];
  headline?: HeadingProps['children'];
}

const DataCollectionNavigation = ({ steps, headline }: DataCollectionNavigationProps): JSX.Element => {
  const altPressed = useKeyPress('Alt');

  return (
    <FlexBox columnDirection>
      {headline && (
        <Paragraph
          bold
          type="large"
          customCss={css`
            margin-bottom: ${spacings.px12}px;
          `}
        >
          {headline}
        </Paragraph>
      )}
      {steps.map(step => (
        <DataCollectionStep currentStep={step} key={step.key} steps={steps} showIndex={altPressed} />
      ))}
    </FlexBox>
  );
};
export default DataCollectionNavigation;
