/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';

import HomeLinks from '../../../../../components/common/HomeLinks';
import SystemMessage from '../../../../../components/core/Alert/SystemMessage';
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { OpportunityCoveragesTier } from '../../../../../interfaces/IOpportunity';
import { coverageByKey, PolicyCoverageKey } from '../../../../../interfaces/IPolicyCoverage';
import analytics from '../../../../../services/analytics';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { isOpportunityDataCollectionCompleted, isOpportunityQuotingInProgress } from '../../../_helpers';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import { IQuotingConfigurationFormValues } from '../_interfaces/IQuotingConfigurationOpportunity';
import OpportunityCurrentInsurance from '../OpportunityCurrentInsurance';
import OpportunityCustomerChoice from '../OpportunityCustomerChoice';
import OpportunityQuotingProgressMessage from '../OpportunityQuotingProgressMessage';
import RealPropertyCoverage from './RealPropertyCoverage';
import RealPropertyInsuredPeopleWithTheDeed from './RealPropertyInsuredPeopleWithTheDeed';

export const realPropertyOpportunityInitialCoverages = (
  opportunity: ConfigurationComponentProps['opportunity']
): ConfigurationComponentProps['opportunity']['agent_selected_coverages'] => {
  if (opportunity.agent_selected_coverages) {
    return opportunity.agent_selected_coverages;
  }

  const latestPolicyDwellingNode = coverageByKey(opportunity.latestPolicy?.coverages, PolicyCoverageKey.Dwelling);

  return [
    {
      asset_gid: opportunity.assets?.[0]?.gid || null,
      coverages_tier: latestPolicyDwellingNode
        ? OpportunityCoveragesTier.MatchCurrentInsurance
        : OpportunityCoveragesTier.MatchRCE,
      coverages: [
        {
          key: PolicyCoverageKey.Dwelling,
          limit_value: latestPolicyDwellingNode?.limit_value || null,
          deductible_value: latestPolicyDwellingNode?.deductible_value || null,
          limit_option_gid: null
        }
      ]
    }
  ];
};

const RealPropertyOpportunity = ({
  person,
  opportunity,
  quotesRequests,
  refetchCurrentPolicies,
  onMoveToOpportunity
}: ConfigurationComponentProps) => {
  const home = opportunity.assets?.[0];

  const { values } = useFormikContext<IQuotingConfigurationFormValues>();
  const opportunityValues = values.opportunities[opportunity.id.toString()]!;

  const isOpportunityEnabled = opportunityValues.enabled;

  const isDataCollectionCompleted = isOpportunityDataCollectionCompleted({ opportunity });
  const isQuotingInProgress = isOpportunityQuotingInProgress({ opportunity, quotesRequests });

  const noAppointedCarriers = opportunity.disqualification_reason === 'no_carriers_appointments';

  return (
    <FlexBox pv={spacings.px8} ph={spacings.px12} gap={spacings.px12}>
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <FlexBox justifySpaceBetween alignItemsCenter gap={spacings.px12}>
          <FlexBox alignItemsCenter>
            <CheckboxField
              inline
              key={`opportunities.${opportunity.id}.enabled`}
              id={`opportunities.${opportunity.id}.enabled`}
              name={`opportunities.${opportunity.id}.enabled`}
              disabled={!isDataCollectionCompleted || isQuotingInProgress || !!opportunity.disqualification_reason}
              label={
                <FlexBox gap={spacings.px8} alignItemsCenter>
                  <Text bold type="large">
                    {opportunity.title}
                  </Text>
                  {opportunity.primary && (
                    <Tag
                      label="Primary opportunity"
                      transparent
                      backgroundColor={colors.violet}
                      textColor={colors.violet}
                      mt={spacings.px4}
                    />
                  )}
                </FlexBox>
              }
            />
            {home && <HomeLinks personGid={person.gid} homeGid={home.gid} ml={spacings.px12} />}
          </FlexBox>
          {home && (
            <Text className="fs-mask" color={colors.grey60}>
              {home.address.full}
            </Text>
          )}
        </FlexBox>
        {!isDataCollectionCompleted && (
          <FlexBox mt={spacings.px24} columnDirection>
            <Text>Information about home was not collected</Text>
            <Container mt={spacings.px8}>
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Secondary}
                onClick={() => {
                  analytics.track('Collect home profile clicked', {
                    person_gid: person.gid,
                    place: 'guided_selling_experience',
                    page: 'quoting_configuration'
                  });

                  return onMoveToOpportunity(opportunity);
                }}
              >
                Collect home profile
              </Button>
            </Container>
          </FlexBox>
        )}
        {isQuotingInProgress && <OpportunityQuotingProgressMessage />}
        {isOpportunityEnabled && (
          <>
            <RealPropertyInsuredPeopleWithTheDeed person={person} opportunity={opportunity} />
            <RealPropertyCoverage person={person} opportunity={opportunity} />
          </>
        )}
        {noAppointedCarriers && (
          <FlexBox>
            <SystemMessage
              type="warning"
              // eslint-disable-next-line max-len
              heading="You can't quote this opportunity because no integrated or non-integrated carriers in this State are available."
            />
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox gap={spacings.px12} columnDirection>
        <OpportunityCustomerChoice opportunity={opportunity} />
        <OpportunityCurrentInsurance
          person={person}
          opportunity={opportunity}
          refetchCurrentPolicies={refetchCurrentPolicies}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default RealPropertyOpportunity;
