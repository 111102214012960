import { IOpportunity, IOpportunityPolicies, IOpportunityPremiumPrediction } from '../../../../../interfaces';
import { coverageByKey, PolicyCoverageKey } from '../../../../../interfaces/IPolicyCoverage';
import { arePolicyTypesSimilar } from '../../../../../interfaces/IPolicyType';
import { IQuote, QuoteValueChange } from '../../../../../interfaces/IQuote';
import { predictedPremiumIncrease } from '../../../_helpers';

const premiumChangeType = (premiumChange: number | undefined) => {
  if (!premiumChange || premiumChange === 0) {
    return undefined;
  }
  if (premiumChange > 0) {
    return QuoteValueChange.Increase;
  }

  return QuoteValueChange.Decrease;
};

const coverageChangeType = (coverageChange: number | undefined) => {
  if (!coverageChange || coverageChange === 0) {
    return undefined;
  }

  if (coverageChange > 0) {
    return QuoteValueChange.Increase;
  }

  return QuoteValueChange.Decrease;
};

export const applySavingsToQuote = (
  quote: IQuote,
  matchingOpportunity: (IOpportunity & IOpportunityPolicies & IOpportunityPremiumPrediction) | undefined
) => {
  const matchingPolicy = matchingOpportunity?.latestPolicy;
  if (!matchingPolicy || !quote.attributes.premium) {
    return quote;
  }

  const { premium, coverages } = matchingPolicy;

  const matchingPolicyPremium = premium ? parseFloat(premium) : undefined;
  const matchingPolicyPredictedPremium = predictedPremiumIncrease({
    premium,
    premiumPrediction: matchingOpportunity.premium_prediction
  })?.predictedNewPremium;

  const matchingPolicyCoverageNode = coverageByKey(coverages, PolicyCoverageKey.Dwelling);
  const matchingPolicyCoverage = matchingPolicyCoverageNode?.limit_value
    ? parseFloat(matchingPolicyCoverageNode.limit_value)
    : undefined;

  const quotePremium = quote.attributes.premium;
  const quoteCoverageNode = coverageByKey(quote?.attributes?.coverages, PolicyCoverageKey.Dwelling);
  const quoteCoverage = quoteCoverageNode?.limit_value
    ? parseFloat(quoteCoverageNode.limit_value.toString())
    : undefined;

  const premiumChange = matchingPolicyPremium ? Math.round(quotePremium - matchingPolicyPremium) : undefined;
  const premiumChangePercent = premiumChange ? Math.round((premiumChange / matchingPolicyPremium!) * 100) : undefined;
  const predictedPremiumChange = matchingPolicyPredictedPremium
    ? Math.round(quotePremium - matchingPolicyPredictedPremium)
    : undefined;

  const coverageChange =
    quoteCoverage && matchingPolicyCoverage ? Math.round(quoteCoverage - matchingPolicyCoverage) : undefined;
  const coverageChangePercent = coverageChange
    ? Math.round((coverageChange / matchingPolicyCoverage!) * 100)
    : undefined;

  return {
    ...quote,

    premiumChange,
    premiumChangePercent,
    premiumChangeType: premiumChangeType(premiumChange),

    predictedPremiumChange,
    predictedPremiumChangeType: premiumChangeType(predictedPremiumChange),

    coverageChange,
    coverageChangePercent,
    coverageChangeType: coverageChangeType(coverageChange)
  };
};

export const findMatchingQuote = (opportunity: IOpportunity, quotes: IQuote[] | undefined) => {
  if (!opportunity.assets?.length) {
    return quotes?.find(({ attributes: { policy_type } }) =>
      arePolicyTypesSimilar(policy_type, opportunity.policy_type)
    );
  }

  const targetQuotes = quotes?.filter(
    ({ attributes: { policy_type, asset_gids } }) => !!policy_type && !!asset_gids?.length
  );

  return targetQuotes?.find(({ attributes: { policy_type, asset_gids } }) => {
    return (
      arePolicyTypesSimilar(policy_type, opportunity.policy_type) &&
      !!asset_gids?.filter(asset_gid => opportunity.assets?.some(({ gid }) => gid === asset_gid))?.length
    );
  });
};
