/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as yup from 'yup';

import HomeLinks from '../../../components/common/HomeLinks';
import MapView from '../../../components/common/MapView';
import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import Container from '../../../components/core/Container';
import CopyableAddress from '../../../components/core/CopyableAddress';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import buildUIFlow from '../../../components/core/forms/uiFlowBuilder';
import Heading from '../../../components/core/Heading';
import Paragraph from '../../../components/core/Paragraph';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../components/core/QuestionVerificationStatusIcon';
import Tag from '../../../components/core/Tag';
import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IHome, IQuestion, IUIFlowTextBox } from '../../../interfaces';
import { homeUsageTitle } from '../../../interfaces/IHome';
import { Requirement } from '../../../interfaces/IPolicyType';
import { QuestionType } from '../../../interfaces/IWorkflow';
import { useReconcileOpportunities } from '../../../queries/leads/data_collection/useDataCollectionOpportunities';
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../../queries/people/person_opportunities/usePersonOpportunities';
import usePersonAssetUIFlow, {
  HOME_PROFILE_FORM_V3,
  HOME_PROFILE_FORM_V4,
  useSaveAnswers
} from '../../../queries/workflows/useUiFlow';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { StepContentProps } from '../GuidedDataCollection';
import LeadWarnings from './_components/LeadWarnings';
import PropertyInfoSelectOption from './_components/PropertyInfoSelectOption';
import { isPropertyRCEQuestionsRequired } from './_helpers';
import usePersistFormValues, { PersistValues } from './_hooks/usePersistFormValues';

const PropertyInformationPage = ({ onSubmit, dataCollection, page, isDataEditingForbidden }: StepContentProps) => {
  const { personGid, leadGid, lead } = useGuidedSellingExperienceContext();

  const opportunity = page.opportunities?.[0];
  const home = opportunity?.assets?.[0] as IHome | undefined;
  const isHomePrimary = opportunity?.primary;

  const { mutateAsync: saveAnswers } = useSaveAnswers();
  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();

  const queryClient = useQueryClient();

  const homeProfileUIFlowKey = featureFlags.propertyOtherStructuresQuestion
    ? HOME_PROFILE_FORM_V4
    : HOME_PROFILE_FORM_V3;

  const {
    data: uiFlowData,
    isFetchedAfterMount,
    isSuccess
  } = usePersonAssetUIFlow({
    uiFlowKey: homeProfileUIFlowKey,
    personGid,
    assetGid: home?.gid
  });

  const formKey = `${homeProfileUIFlowKey}-${personGid}-${home?.gid}`;

  const { getValues, setValues } = usePersistFormValues();

  if (!isFetchedAfterMount || !home) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  if (!isSuccess) {
    return null;
  }

  const homeInfoUIFlow = buildUIFlow({ uiFlowResponse: uiFlowData });

  const initialValues = getValues(formKey) || homeInfoUIFlow.initialValues();
  const isDisabled = page.is_disqualified || isDataEditingForbidden || !page.is_data_collection_enabled;

  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      initialValues={initialValues}
      onSubmit={async values => {
        await saveAnswers({
          uiFlowKey: homeProfileUIFlowKey,
          answers: homeInfoUIFlow.answersForFormValues({
            formValues: values,
            personGid: personGid!,
            engagementGid: leadGid!,
            assetGid: home.gid
          })
        });

        if (lead) {
          await reconcileOpportunities({
            leadId: lead.id,
            requirement: Requirement.Essential,
            assetsGids: [home.gid]
          });

          await queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });
        }

        await onSubmit();

        setValues(formKey, null);
      }}
      validationSchema={yup.object().shape(homeInfoUIFlow.validations())}
      validate={values =>
        isPropertyRCEQuestionsRequired(values) ? homeInfoUIFlow.validateFormValues(values) : undefined
      }
      disabled={isDisabled}
      initialStatus={isDisabled ? 'disabled' : ''}
      submitText="Next"
      controlsWidth={320}
      submitTestId="submit-home-info-button"
      renderForm={({ values }) => {
        return (
          <FlexBox gap={spacings.px24}>
            <Container fitParentWidth>
              <LeadWarnings dataCollection={dataCollection} />

              <FlexBox gap={spacings.px8} alignItemsCenter>
                <Heading>{homeUsageTitle(home)}</Heading>
                {isHomePrimary && (
                  <Tag
                    label="Primary opportunity"
                    transparent
                    backgroundColor={colors.violet}
                    textColor={colors.violet}
                    mt={spacings.px4}
                  />
                )}
              </FlexBox>

              <FlexBox alignItemsCenter>
                <Paragraph type="large">
                  <CopyableAddress address={home.address} />
                </Paragraph>
                {personGid && <HomeLinks personGid={personGid} homeGid={home.gid} ml={spacings.px12} />}
              </FlexBox>

              <FlexBox columnDirection gap={spacings.px24} mt={spacings.px24}>
                <PersistValues formKey={formKey} />
                {homeInfoUIFlow.containers.map(container => {
                  return (
                    <Container key={container.key}>
                      {container.elements
                        .map(element => {
                          if (element.kind === 'text_box') {
                            const textBox = element.content as IUIFlowTextBox;

                            if (textBox.heading) {
                              return (
                                <Paragraph key={element.key} bold type="large" mb={spacings.px0} pl={spacings.px16}>
                                  {textBox.heading}
                                </Paragraph>
                              );
                            }

                            if (featureFlags.homeDataCollectionHints && textBox.description) {
                              return (
                                <Paragraph
                                  key={element.key}
                                  type="small"
                                  color={colors.grey60}
                                  italic
                                  ml={spacings.px28}
                                  mb={spacings.px0}
                                >
                                  {textBox.description}
                                </Paragraph>
                              );
                            }
                          }

                          const question = element.content as IQuestion;
                          const additionalProps =
                            question.type === QuestionType.Select && featureFlags.homeDataCollectionHints
                              ? { customComponents: { Option: PropertyInfoSelectOption } }
                              : {};

                          const input = homeInfoUIFlow.inputForQuestion({
                            element,
                            formValues: values,
                            propsProvider: question => ({
                              inline: true,
                              key: question.key,
                              description: undefined,
                              required: isPropertyRCEQuestionsRequired(values),
                              ...additionalProps
                            })
                          });

                          return input ? (
                            <FlexBox key={question.key} gap={spacings.px4} pl={spacings.px16} alignItemsCenter>
                              {question.verification_status && (
                                <QuestionVerificationStatusIcon
                                  verificationStatus={question.verification_status}
                                  disabled={isDisabled}
                                  css={css`
                                    margin-left: -${spacings.px20}px;
                                  `}
                                />
                              )}
                              {input}
                            </FlexBox>
                          ) : (
                            <React.Fragment key={question.key} />
                          );
                        })
                        .filter(Boolean)}
                    </Container>
                  );
                })}
                <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
              </FlexBox>
            </Container>

            {personGid && (
              <FlexBox
                mt={spacings['-px24']}
                mr={spacings['-px24']}
                fitParentWidth
                fitParentHeight
                justifyRight
                customCss={css`
                  height: calc(100vh - 132px);
                  position: sticky;
                  top: -24px;
                  z-index: 1;
                `}
              >
                <Container
                  fitParentWidth
                  fitParentHeight
                  customCss={css`
                    max-width: 50vw;
                  `}
                >
                  <MapView personGid={personGid} home={home} />
                </Container>
              </FlexBox>
            )}
          </FlexBox>
        );
      }}
    />
  );
};

export default PropertyInformationPage;
