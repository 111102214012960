/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PopperPlacementType } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ArrowRight2Icon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import featureFlags from '../../../../constants/featureFlags';
import { usePopper } from '../../../../hooks';
import {
  ICallInteraction,
  IEmailInteraction,
  IInteraction,
  IPerson,
  IRelatedPerson,
  ISMSInteraction
} from '../../../../interfaces';
import { InteractionDirection, InteractionType } from '../../../../interfaces/IPersonInteraction';
import usePerson from '../../../../queries/people/usePerson';
import authInfo from '../../../../services/authInfo';
import colors, { opacities } from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { capitalize } from '../../../../utils/formatter';
import { GuidedSellingPathnames } from '../../navigation';
import CallDetails from './CallDetails';
import EmailDetails from './EmailDetails';
import { callDuration, displayedDateAndTime, generateInteractionIcon, pickStartTime } from './helpers';
import SMSDetails from './SMSDetails';

const pickDetailsProvider = ({
  interaction,
  interactionPerson,
  userRole,
  relatedPersonKind
}: {
  interaction: IInteraction;
  interactionPerson: IPerson | undefined;
  userRole: string | null;
  relatedPersonKind?: string;
}) => {
  switch (interaction.type) {
    case InteractionType.Call:
      return (
        <CallDetails
          interaction={interaction as ICallInteraction}
          interactionPerson={interactionPerson}
          relatedPersonKind={relatedPersonKind}
          userRole={userRole}
        />
      );
    case InteractionType.Email:
      return (
        <EmailDetails
          interaction={interaction as IEmailInteraction}
          interactionPerson={interactionPerson}
          relatedPersonKind={relatedPersonKind}
          userRole={userRole as IEmailInteraction['processed_with_role']}
        />
      );
    case InteractionType.SMS:
      return (
        <SMSDetails
          interaction={interaction as ISMSInteraction}
          interactionPerson={interactionPerson}
          relatedPersonKind={relatedPersonKind}
          userRole={userRole}
        />
      );
  }
};

interface ICommunicator {
  interaction: IInteraction;
  interactionPerson: IPerson | undefined;
  relatedPersonKind: string;
  userRole: string | null;
}

const Sender = ({ interaction, interactionPerson, relatedPersonKind, userRole }: ICommunicator) => {
  const tagLabel = interaction.direction === InteractionDirection.Inbound ? relatedPersonKind : userRole;
  const userName = interaction.processed_by ? interaction.processed_by.first_name : 'Matic';
  return (
    <>
      <Paragraph bold className="fs-mask">
        {interaction.direction === InteractionDirection.Inbound ? interactionPerson?.first_name : userName}
      </Paragraph>
      {tagLabel && <Tag label={tagLabel} transparent backgroundColor={colors.grey80} textColor={colors.grey80} />}
    </>
  );
};

const Reciever = ({ interaction, interactionPerson, relatedPersonKind, userRole }: ICommunicator) => {
  const tagLabel = interaction.direction === InteractionDirection.Inbound ? userRole : relatedPersonKind;
  const userName = interaction.processed_by ? interaction.processed_by.first_name : 'Matic';
  return (
    <>
      <Paragraph bold className="fs-mask">
        {interaction.direction === InteractionDirection.Inbound ? userName : interactionPerson?.first_name}
      </Paragraph>
      {tagLabel && <Tag label={tagLabel} transparent backgroundColor={colors.grey80} textColor={colors.grey80} />}
    </>
  );
};

const Interaction = ({
  interaction,
  relatedPeople,
  person,
  popperPlacement = 'left'
}: {
  interaction: IInteraction;
  relatedPeople: IRelatedPerson[] | undefined;
  person: IPerson | undefined;
  popperPlacement?: PopperPlacementType;
}): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const householdMember = relatedPeople?.find(relatedPerson => relatedPerson.gid === interaction.person_gid);

  const { data: relatedPerson } = usePerson(householdMember?.gid, {
    enabled: interaction.person_gid !== person?.gid && !!householdMember?.gid
  });

  const interactionPerson = relatedPerson || person;

  const relatedPersonKind = capitalize(householdMember?.kind);

  const userRole =
    interaction.processed_by?.email === authInfo.currentUserEmail ? 'You' : interaction.processed_with_role;

  const iconProps = generateInteractionIcon({
    type: interaction.type as InteractionType,
    direction: interaction.direction as InteractionDirection
  });

  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps } = usePopper({
    placement: popperPlacement,
    disablePortal: false,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      }
    ]
  });

  const displayShowProposalButton =
    'sent_by_proposal_builder' in interaction &&
    interaction.sent_by_proposal_builder &&
    featureFlags.proposalBuilderPage;

  return (
    <FlexBox
      pv={spacings.px8}
      ph={spacings.px12}
      gap={spacings.px8}
      backgroundColor={anchorEl ? colors.grey5 : undefined}
      alignItemsFlexStart
      customCss={css`
        &:hover {
          background-color: ${colors.grey5};
          cursor: pointer;
        }
      `}
      onMouseEnter={triggerPopper}
      onMouseLeave={() => setAnchorEl(null)}
    >
      <Container>
        <FlexBox
          alignItemsCenter
          justifyCenter
          backgroundColor={`${iconProps?.color}${opacities.opacity_16}`}
          p={spacings.px6}
          customCss={css`
            border-radius: 50%;
          `}
        >
          {iconProps?.icon}
        </FlexBox>
      </Container>
      <FlexBox
        gap={spacings.px4}
        columnDirection
        pr={spacings.px12}
        customCss={css`
          width: 100%;
        `}
      >
        <FlexBox alignItemsCenter gap={spacings.px4}>
          <Sender
            interaction={interaction}
            interactionPerson={interactionPerson}
            relatedPersonKind={relatedPersonKind}
            userRole={userRole}
          />
          <ArrowRight2Icon
            width={spacings.px12}
            css={css`
              margin-top: 2px;
            `}
          />
          <Reciever
            interaction={interaction}
            interactionPerson={interactionPerson}
            relatedPersonKind={relatedPersonKind}
            userRole={userRole}
          />
        </FlexBox>
        {'subject' in interaction && interaction.subject && (
          <Paragraph bold type="small">
            {interaction.subject}
          </Paragraph>
        )}
        {displayShowProposalButton && (
          <Button
            variant={ButtonVariant.Text}
            onClick={() => navigate(`${GuidedSellingPathnames.Proposal}${search}`)}
            customCss={css`
              text-align: left;
            `}
          >
            Show proposal
          </Button>
        )}

        <FlexBox justifySpaceBetween alignItemsBaseline>
          <Paragraph color={colors.grey60} type="small">
            {displayedDateAndTime(moment(pickStartTime(interaction)), true)}
          </Paragraph>
          {interaction.type === InteractionType.Call && interaction.started_at && interaction.ended_at && (
            <Text type="small">{callDuration({ start: interaction.started_at, end: interaction.ended_at })}</Text>
          )}
        </FlexBox>
      </FlexBox>
      <PopperComponent {...popperProps}>
        {pickDetailsProvider({ interaction, interactionPerson, relatedPersonKind, userRole })}
      </PopperComponent>
    </FlexBox>
  );
};

export default Interaction;
