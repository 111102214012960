/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import React from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import featureFlags from '../../../../constants/featureFlags';
import {
  IOpportunity,
  IOpportunityPolicies,
  IOpportunityPremiumPrediction,
  IPersonAsset
} from '../../../../interfaces';
import {
  IAssetsQuotesGroup,
  QuoteGroupingOption,
  QuoteOrderingOption,
  RESULTS_PER_PAGE
} from '../../../../interfaces/IGroupedQuotes';
import { AssetType } from '../../../../interfaces/IPersonAsset';
import { IQuote, QuoteStatus } from '../../../../interfaces/IQuote';
import { IQuotesRequest } from '../../../../interfaces/IQuotesRequests';
import useAggregatedQuotes from '../../../../queries/people/person_quotes/useAggregatedQuotes';
import useQuotesRequests from '../../../../queries/quotes_requests/useQuotesRequests';
import { Subscription } from '../../../../services/ActionCable';
import { spacings } from '../../../../theme/variables';
import { ActionCableContext } from '../../../../utils/actioncable';
import ExternalLinkModal from './_components/ExternalLinkModal';
import QuotesPlaceholder from './_components/QuotesPlaceholder';
import QuotingProgress from './_components/QuotingProgress';
import augmentQuotes from './_helpers/quotesAugmentationHelper';
import useQuotesModalView from './_hooks/useModalView';
import useSelectedQuote from './_hooks/useSelectedQuote';
import CurrentPolicies from './CurrentPolicies';
import CustomerChoices from './CustomerChoices';
import QuotesGroupedByAssets from './GroupedQuotesList/QuotesGroupedByAssets';
import QuotingErrors from './QuotingErrors';

export type AssetsFilter = { gid: string; label: string; type: AssetType.Home | AssetType.Auto } & {
  active: boolean;
};

export const assetsGids = ({
  opportunities,
  assetsFilter
}: {
  opportunities: IOpportunity[] | undefined;
  assetsFilter: Record<string, AssetsFilter>;
}): string[] => {
  if (opportunities?.length) {
    return (
      opportunities.flatMap(({ assets }) => assets?.map(({ gid }) => gid)).filter(Boolean) || []
    ).sort() as string[];
  }

  const gids = Object.keys(assetsFilter)
    .filter(key => assetsFilter[key]!.active)
    .sort();

  return gids.length === 0 ? ['not-taken-gid'] : gids;
};

interface QuotesRequestProps {
  personGid: string;
  leadId: number | undefined;
  leadGid: string | undefined;
  selectedQuotesRequest: IQuotesRequest | undefined;
  customerChoices: IQuote[] | undefined;
  assets: IPersonAsset[];
  opportunitiesWithPremiumPredictions:
    | (IOpportunity & IOpportunityPolicies & IOpportunityPremiumPrediction)[]
    | undefined;
  refetchCurrentPolicies: () => void;
  opportunities: IOpportunity[] | undefined;
  refetchQuotesRequests: ReturnType<typeof useQuotesRequests>['refetch'];
}

const QuotesRequest = ({
  personGid,
  leadId,
  leadGid,
  selectedQuotesRequest,
  customerChoices,
  assets,
  opportunitiesWithPremiumPredictions,
  refetchCurrentPolicies,
  opportunities,
  refetchQuotesRequests
}: QuotesRequestProps) => {
  const { quotesModalView } = useQuotesModalView();

  const [assetsFilter, setAssetsFilter] = React.useState<Record<string, AssetsFilter>>(
    assets.reduce((acc, current) => {
      return {
        ...acc,
        [current.gid]: {
          gid: current.gid,
          active: true,
          label: current.asset_name === AssetType.Auto ? current.description_without_icon : current.address.full
        }
      };
    }, {})
  );
  const { selectedQuoteGid, setSelectedQuoteGid } = useSelectedQuote();

  const maxDate = moment.parseZone(selectedQuotesRequest?.created_at).add(2, 'seconds');

  const {
    data: groupedQuotes,
    refetch: refetchGroupedQuotes,
    isPending
  } = useAggregatedQuotes<IAssetsQuotesGroup>({
    personGid,
    filters: {
      lead_gid: leadGid,
      quotes_request_gid: selectedQuotesRequest?.gid,
      max_date: maxDate.toString(),
      group_by: QuoteGroupingOption.Asset,
      order_by: QuoteOrderingOption.CheapestFirst,
      page: 1,
      per_page: RESULTS_PER_PAGE,
      asset_gids: assetsGids({ opportunities, assetsFilter }),
      carrier_ids: [],
      policy_types: [],
      statuses: [
        QuoteStatus.Success,
        QuoteStatus.CompleteManually,
        QuoteStatus.DeclinedBusiness,
        QuoteStatus.InvalidData
      ]
    }
  });

  React.useEffect(() => {
    if (groupedQuotes) {
      if (groupedQuotes.finished_at && groupedQuotes.assets.length > 0) {
        // TODO: check if we can drop this filter.
        setAssetsFilter(currentFilter =>
          groupedQuotes.assets
            .filter(asset => Object.keys(currentFilter).includes(asset.gid))
            .reduce((acc, current) => {
              return {
                ...acc,
                [current.gid]: {
                  ...current,
                  active: currentFilter[current.gid]!.active
                }
              };
            }, {})
        );
      }
    }
  }, [groupedQuotes]);

  const { data: groupedErrors, refetch: refetchGroupedErrors } = useAggregatedQuotes<IAssetsQuotesGroup>({
    personGid,
    filters: {
      lead_gid: leadGid,
      quotes_request_gid: selectedQuotesRequest?.gid,
      max_date: maxDate.toString(),
      group_by: QuoteGroupingOption.Asset,
      order_by: QuoteOrderingOption.CheapestFirst,
      page: 1,
      per_page: RESULTS_PER_PAGE,
      asset_gids: assetsGids({ opportunities, assetsFilter }),
      carrier_ids: [],
      policy_types: [],
      statuses: [
        QuoteStatus.CarrierError,
        QuoteStatus.InvalidQuote,
        QuoteStatus.QuotingServiceError,
        QuoteStatus.CredentialsExpired,
        QuoteStatus.Unknown
      ]
    }
  });

  const cable = React.useContext(ActionCableContext);
  const selectedQuotesRequestGid = selectedQuotesRequest?.gid;

  React.useEffect(() => {
    let quotingProgressChannel: Subscription;

    if (selectedQuotesRequestGid && cable) {
      quotingProgressChannel = cable.subscriptions.create(
        {
          channel: 'QuotingProgressChannel',
          quotes_request_gid: selectedQuotesRequestGid
        },
        {
          received: () => {
            refetchGroupedQuotes();
            refetchGroupedErrors();
          }
        }
      );
    }

    return () => {
      if (selectedQuotesRequestGid && cable) {
        quotingProgressChannel?.unsubscribe();
      }
    };
  }, [cable, selectedQuotesRequestGid, refetchGroupedQuotes, refetchGroupedErrors]);

  const selectedQuote = groupedQuotes?.groups
    ?.flatMap(({ quotes }) => quotes)
    ?.find(quote => quote.gid === selectedQuoteGid);

  const augmentedQuotes = augmentQuotes({
    groupedQuotes,
    opportunitiesWithPremiumPredictions,
    personGid,
    leadGid,
    leadId,
    quotesSourceProduct: selectedQuotesRequest?.source_product
  });

  return (
    <FlexBox fitParentWidth gap={spacings.px24}>
      {!quotesModalView && !featureFlags.newQuotesUI && (
        <Container
          css={css`
            width: 192px;
            min-width: 192px;
          `}
        >
          <CurrentPolicies
            leadId={leadId}
            personGid={personGid}
            opportunitiesWithPolicies={opportunitiesWithPremiumPredictions}
            refetchCurrentPolicies={refetchCurrentPolicies}
          />
          {!!customerChoices?.length && <CustomerChoices customerChoices={customerChoices} />}
          {selectedQuotesRequest && (
            <QuotingErrors quotesRequest={selectedQuotesRequest} groupedErrors={groupedErrors} />
          )}
        </Container>
      )}

      <FlexBox fitParentWidth columnDirection gap={spacings.px24}>
        <FlexBox
          columnDirection
          gap={spacings.px12}
          customCss={css`
            ${quotesModalView && 'position: relative; overflow-x: auto;'}
          `}
        >
          {groupedQuotes && <QuotingProgress groupedQuotes={groupedQuotes} />}
          {isPending && <QuotesPlaceholder />}

          {selectedQuotesRequest && augmentedQuotes && (
            <>
              <QuotesGroupedByAssets
                groupedQuotes={augmentedQuotes}
                leadId={leadId}
                personGid={personGid}
                refetchQuotesRequests={refetchQuotesRequests}
                quotingFinished={!!selectedQuotesRequest.finished_at}
                quotesRequest={selectedQuotesRequest}
                groupedErrors={groupedErrors}
              />
            </>
          )}
        </FlexBox>
        {selectedQuote && (
          <ExternalLinkModal quote={selectedQuote} cancelBtnHandler={() => setSelectedQuoteGid(undefined)} />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default QuotesRequest;
