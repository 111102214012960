/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Button, { ButtonSize, ButtonVariant } from '../../../../../../components/core/buttons/Button';
import FlexBox from '../../../../../../components/core/FlexBox';
import { OpenInNewIcon } from '../../../../../../components/core/icons';
import featureFlags from '../../../../../../constants/featureFlags';
import { useToggle } from '../../../../../../hooks';
import { IQuote } from '../../../../../../interfaces';
import { CarrierKey } from '../../../../../../interfaces/ICarrier';
import { QuoteKind, QuoteStatus } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';
// eslint-disable-next-line max-len
import ReportsModal from '../../../../../PostSalesExperience/_components/CarrierInstructions/UniversalCarrierConsent/ReportsModal';
import { isQuoteEligibleOrPartlyEligible } from '../../_helpers';

interface IBridge {
  quote: IQuote;
  compact?: boolean;
  onBridge: (quoteGid: string) => void;
}

const LinkView = ({ quote, compact }: { quote: IQuote; compact: boolean }) => {
  const isFinalizeAvailable =
    isQuoteEligibleOrPartlyEligible(quote) &&
    quote.kind === QuoteKind.RC2 &&
    quote.status === QuoteStatus.Success &&
    !compact;

  const appointedAndFF = featureFlags.expiredQuotesUI ? quote.attributes.appointed : true;

  return (
    <FlexBox alignItemsCenter gap={spacings.px4}>
      {isFinalizeAvailable ? (
        <Button variant={ButtonVariant.Secondary} size={ButtonSize.Small} disabled={!appointedAndFF}>
          QuickBind
        </Button>
      ) : (
        <OpenInNewIcon
          css={css`
            cursor: ${appointedAndFF ? 'pointer' : 'not-allowed'};
          `}
          color={appointedAndFF ? colors.azure50 : colors.grey30}
        />
      )}
    </FlexBox>
  );
};

const Bridge = ({ quote, compact = false, onBridge }: IBridge) => {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const {
    gid,
    attributes: { binding_url, external_id, notice, appointed }
  } = quote;

  const appointedAndFF = featureFlags.expiredQuotesUI ? appointed : true;

  if (!binding_url && !external_id && !notice) {
    return null;
  }

  if (notice || external_id) {
    return (
      <>
        <div
          onClick={() => (appointedAndFF ? onBridge(gid) : null)}
          title={appointedAndFF ? '' : 'No carrier appointment in the current state'}
          css={css`
            cursor: ${appointedAndFF ? 'pointer' : 'not-allowed'};
          `}
        >
          <LinkView quote={quote} compact={compact} />
        </div>
      </>
    );
  }

  if (binding_url) {
    return (
      <>
        <a
          href={appointedAndFF ? binding_url : ''}
          target="_blank"
          rel="noopener noreferrer"
          title={appointedAndFF ? '' : 'No carrier appointment in the current state'}
          onClick={e => {
            if (!appointedAndFF) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }

            if (quote.attributes.carrier.key === CarrierKey.Universal) {
              toggleExpanded();
              e.preventDefault();
            }
          }}
        >
          <LinkView quote={quote} compact={compact} />
        </a>
        {isExpanded && quote.attributes.carrier.key === CarrierKey.Universal && (
          <ReportsModal
            cancelHandler={() => toggleExpanded()}
            confirmText="Go to website"
            cancelText="Cancel"
            showCancelButton
            confirmHandler={() => {
              window.open(binding_url, '_blank', 'noopener,noreferrer');
              toggleExpanded();
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export default Bridge;
