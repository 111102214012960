/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import HotKey from '../../components/core/HotKey';
import { CloseIcon } from '../../components/core/icons';
import Input from '../../components/core/inputs/Base';
import Select from '../../components/core/inputs/Select';
import Label from '../../components/core/Label';
import { useEventListener } from '../../hooks';
import useScoutingStrategyScriptDemo from '../../queries/scouting_strategies/useScoutingStrategyConfigDemo';
import { useScoutingStrategyConfigsVariables } from '../../queries/scouting_strategies/useScoutingStrategyConfigs';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { capitalize } from '../../utils/formatter';
import ScoutingStrategy from '../GuidedSellingExperience/ScoutingReport/ScoutingStrategy';

const ScoutingReportScriptPreview = () => {
  const navigate = useNavigate();
  const { configKey } = useParams();

  useEventListener('keydown', event => {
    if (event.key === 'Escape') {
      navigate(-1);
    }
  });

  const { data: variables, isPending: isPendingVariables } = useScoutingStrategyConfigsVariables();

  const sourceValues = React.useMemo(
    () => Array.from(variables?.values() || []).map(variable => ({ value: variable.label, key: variable.key })),
    [variables]
  );

  const [filters, setFilters] = React.useState<Record<string, string>>({});

  const { data: scoutingStrategy, isFetching: isFetchingDemo } = useScoutingStrategyScriptDemo({
    configKey,
    variables: filters
  });

  return (
    <Container
      fitParentHeight
      customCss={css`
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 300px 1fr;
        grid-template-rows: fit-content(116px) 1fr;
        grid-template-areas:
          'header header'
          'left-bar guide';
      `}
    >
      <FlexBox
        p={spacings.px24}
        alignItemsCenter
        justifySpaceBetween
        customCss={css`
          grid-area: header;
          box-shadow: inset 0px -1px 0px ${colors.grey10};
        `}
      >
        <Heading type="h3">{scoutingStrategy?.name || configKey} preview</Heading>
        <FlexBox alignItemsCenter>
          <HotKey
            message="Press ESC"
            customCss={css`
              margin-right: ${spacings.px8}px;
            `}
          />
          <ButtonWithoutBorder onClick={() => navigate(-1)} aria-label="Close">
            <CloseIcon color={colors.black} width={40} height={40} />
          </ButtonWithoutBorder>
        </FlexBox>
      </FlexBox>

      <Container
        fitParentHeight
        customCss={css`
          grid-area: left-bar;
          display: grid;
          border-right: solid 1px ${colors.grey10};
          overflow: hidden;
        `}
      >
        <FlexBox
          columnDirection
          gap={spacings.px8}
          p={spacings.px16}
          customCss={css`
            overflow-y: scroll;

            &:last-child {
              padding-bottom: ${spacings.px24}px;
            }
          `}
        >
          {sourceValues.map(({ key, value }) => {
            const options = variables?.get(key)?.values?.map(v => ({ label: capitalize(v), key: v }));
            const optionsAvailable = !isPendingVariables && options?.length;

            return (
              <FlexBox key={key} columnDirection gap={spacings.px4}>
                <Label htmlFor={key}>{value}</Label>
                {optionsAvailable ? (
                  <Select
                    inputId={key}
                    name={key}
                    value={filters[key] || ''}
                    options={options}
                    onChange={({ target }) => setFilters(current => ({ ...current, [key]: target.value }))}
                  />
                ) : (
                  <Input
                    id={key}
                    name={key}
                    value={filters[key] || ''}
                    onChange={({ target }) => setFilters(current => ({ ...current, [key]: target.value }))}
                  />
                )}
              </FlexBox>
            );
          })}
        </FlexBox>
      </Container>

      <Container
        p={spacings.px16}
        fitParentHeight
        customCss={css`
          opacity: ${isFetchingDemo ? 0.5 : 1};
        `}
      >
        {scoutingStrategy && <ScoutingStrategy scoutingStrategy={scoutingStrategy} />}
      </Container>
    </Container>
  );
};

export default ScoutingReportScriptPreview;
