/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import SystemMessage from '../../../../../components/core/Alert/SystemMessage';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import Paragraph from '../../../../../components/core/Paragraph';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { OpportunityCoveragesTier } from '../../../../../interfaces/IOpportunity';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import OpportunityCurrentInsurance from '../OpportunityCurrentInsurance';
import OpportunityCustomerChoice from '../OpportunityCustomerChoice';

export const motoOpportunityInitialCoverages = (
  opportunity: ConfigurationComponentProps['opportunity']
): ConfigurationComponentProps['opportunity']['agent_selected_coverages'] => {
  return (
    opportunity.agent_selected_coverages ||
    opportunity.assets?.map(asset => ({
      asset_gid: asset.gid,
      coverages_tier: OpportunityCoveragesTier.Quoted,
      coverages: null
    })) ||
    null
  );
};

const MotoOpportunity = ({ person, opportunity, refetchCurrentPolicies }: ConfigurationComponentProps) => {
  const vehicles = opportunity.assets;
  const garagingAddress = vehicles?.[0]?.address?.full;

  const noAppointedCarriers = opportunity.disqualification_reason === 'no_carriers_appointments';

  return (
    <FlexBox pv={spacings.px8} ph={spacings.px12} gap={spacings.px12}>
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <FlexBox justifySpaceBetween alignItemsCenter gap={spacings.px12}>
          <CheckboxField
            inline
            key={`opportunities.${opportunity.id}.enabled`}
            id={`opportunities.${opportunity.id}.enabled`}
            name={`opportunities.${opportunity.id}.enabled`}
            disabled={!!opportunity.disqualification_reason}
            label={
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <Text bold type="large">
                  {opportunity.title}
                </Text>
                {opportunity.primary && (
                  <Tag
                    label="Primary opportunity"
                    transparent
                    backgroundColor={colors.violet}
                    textColor={colors.violet}
                    mt={spacings.px4}
                  />
                )}
              </FlexBox>
            }
          />
          {garagingAddress && (
            <Text className="fs-mask" color={colors.grey60}>
              Garaging address: {garagingAddress}
            </Text>
          )}
        </FlexBox>
        {vehicles!.map(vehicle => {
          return (
            <Paragraph key={`${opportunity.id}.${vehicle.gid}`} bold>
              {vehicle.description_without_icon}
            </Paragraph>
          );
        })}
        {noAppointedCarriers ? (
          <FlexBox>
            <SystemMessage
              type="warning"
              // eslint-disable-next-line max-len
              heading="You can't quote this opportunity because no integrated or non-integrated carriers in this State are available."
            />
          </FlexBox>
        ) : (
          <Text color={colors.grey60}>
            No integration available yet. We will display carriers, which can be quoted manually.
          </Text>
        )}
      </FlexBox>
      <FlexBox gap={spacings.px12} columnDirection>
        <OpportunityCustomerChoice opportunity={opportunity} />
        <OpportunityCurrentInsurance
          person={person}
          opportunity={opportunity}
          refetchCurrentPolicies={refetchCurrentPolicies}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default MotoOpportunity;
