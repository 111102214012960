import React from 'react';
import ReactTooltip from 'react-tooltip';

import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { IOpportunity, IPersonOnlineSession, IQuote } from '../../../../../interfaces';
import { IPersonOnlineSessionFlowType } from '../../../../../interfaces/IPersonOnlineSession';
import { IQuotesRequest } from '../../../../../interfaces/IQuotesRequests';
import usePersonOnlineSessions from '../../../../../queries/people/person_online_sessions/usePersonOnlineSessions';
import usePersonOpportunities from '../../../../../queries/people/person_opportunities/usePersonOpportunities';
import useQuote from '../../../../../queries/people/person_quotes/useQuote';
import useQuotesRequests from '../../../../../queries/quotes_requests/useQuotesRequests';
import { spacings } from '../../../../../theme/variables';
import { secondaryCoreBundleOpportunity } from '../../../_helpers';
import usePersonCoreBundleInterest from '../../../_hooks/usePersonCoreBundleInterest';
import useCustomerChoices from '../../../GuidedQuoting/Quotes/_hooks/useCustomerChoices';
import CustomerChoicesTableSkeleton from './CustomerChoicesTableSkeleton';
import DigitalProfileCustomerChoices from './DigitalProfileCustomerChoice';
import InitialCustomerChoices from './InitialCustomerChoices';
import OnlineBindCustomerChoices from './OnlineBindCustomerChoices';

interface ICustomerChoicesComponent {
  opportunities: IOpportunity[] | undefined;
  recentOnlineSession: IPersonOnlineSession | undefined;
  onlineSessionInitialQuote: IQuote | undefined;
  onlineSessionBestQuote: IQuote | undefined;
  customerChoices: IQuote[] | undefined;
  quotesRequests: IQuotesRequest[] | undefined;
  isPersonInterestedInCoreBundle: boolean;
}

const CustomerChoicesComponent = ({
  opportunities,
  recentOnlineSession,
  onlineSessionInitialQuote,
  onlineSessionBestQuote,
  customerChoices,
  quotesRequests,
  isPersonInterestedInCoreBundle
}: ICustomerChoicesComponent) => {
  const primaryOpportunity = opportunities?.find(({ primary }) => primary);
  const secondaryOpportunity = secondaryCoreBundleOpportunity({ opportunities });

  if (primaryOpportunity && recentOnlineSession?.flow_type === IPersonOnlineSessionFlowType.DigitalProfile) {
    return (
      <DigitalProfileCustomerChoices
        primaryOpportunity={primaryOpportunity}
        secondaryOpportunity={secondaryOpportunity}
        personOnlineSession={recentOnlineSession}
        onlineSessionInitialQuote={onlineSessionInitialQuote}
        onlineSessionBestQuote={onlineSessionBestQuote}
        customerChoices={customerChoices}
        quotesRequests={quotesRequests}
        isPersonInterestedInCoreBundle={isPersonInterestedInCoreBundle}
      />
    );
  }

  if (
    primaryOpportunity &&
    onlineSessionInitialQuote &&
    recentOnlineSession?.flow_type === IPersonOnlineSessionFlowType.OnlineBind
  ) {
    return (
      <OnlineBindCustomerChoices
        opportunity={primaryOpportunity}
        onlineSessionInitialQuote={onlineSessionInitialQuote}
        customerChoices={customerChoices}
      />
    );
  }

  if (customerChoices?.length && opportunities?.length) {
    return <InitialCustomerChoices opportunities={opportunities} customerChoices={customerChoices} />;
  }

  return null;
};

const CustomerChoices = () => {
  const { personGid, leadGid, candidateGid } = useGuidedSellingExperienceContext();
  const { data: opportunities, isLoading: isLoadingOpportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    candidateGid
  });
  const { data: customerOnlineSessions, isLoading: isLoadingPersonOnlineSessions } = usePersonOnlineSessions({
    personGid,
    engagementGid: leadGid || candidateGid
  });
  const recentOnlineSession = customerOnlineSessions?.[0];
  const { data: onlineSessionInitialQuote, isLoading: isLoadingOnlineSessionInitialQuote } = useQuote({
    personGid,
    quoteGid: recentOnlineSession?.original_quote_gid
  });
  const { data: onlineSessionBestQuote, isLoading: isLoadingOnlineSessionBestQuote } = useQuote({
    personGid,
    quoteGid: recentOnlineSession?.best_quote_gid
  });
  const { data: quotesRequests, isLoading: isLoadingQuotesRequests } = useQuotesRequests({ personGid, leadGid });
  const { customerChoices, isLoading: isLoadingCustomerChoices } = useCustomerChoices({
    personGid,
    leadGid,
    quotesRequests
  });
  const { isPersonInterestedInCoreBundle, isLoading: isLoadingPersonInterestInCoreBundle } =
    usePersonCoreBundleInterest({ personGid: personGid! });

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const isLoadingCustomerChoicesData =
    isLoadingPersonOnlineSessions ||
    isLoadingQuotesRequests ||
    isLoadingCustomerChoices ||
    isLoadingPersonInterestInCoreBundle;

  if (isLoadingCustomerChoicesData) {
    // Most of the leads do not have customer choices, so we do not want to show loading animation for them.
    return null;
  }

  if (!recentOnlineSession && !customerChoices?.length) {
    // If there is no recent online session and no customer choices, do not show customer choices UI.
    return null;
  }

  const isLoadingAdditionalData =
    isLoadingOpportunities || isLoadingOnlineSessionInitialQuote || isLoadingOnlineSessionBestQuote;

  return (
    <FlexBox columnDirection gap={spacings.px16}>
      <Paragraph type="large" bold>
        Customer choice
      </Paragraph>
      {isLoadingAdditionalData ? (
        <CustomerChoicesTableSkeleton />
      ) : (
        <CustomerChoicesComponent
          opportunities={opportunities}
          recentOnlineSession={recentOnlineSession}
          onlineSessionInitialQuote={onlineSessionInitialQuote}
          onlineSessionBestQuote={onlineSessionBestQuote}
          customerChoices={customerChoices}
          quotesRequests={quotesRequests}
          isPersonInterestedInCoreBundle={isPersonInterestedInCoreBundle}
        />
      )}
    </FlexBox>
  );
};

export default CustomerChoices;
