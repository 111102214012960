/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as yup from 'yup';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import { AddressField, DateInputField, SelectField } from '../../../components/core/forms/fields';
import buildUIFlow, { verificationStatusForAnswer } from '../../../components/core/forms/uiFlowBuilder';
import Heading from '../../../components/core/Heading';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../components/core/QuestionVerificationStatusIcon';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../constants/addressForm';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useConstant } from '../../../hooks';
import { IAddress, IAddressSuggestion } from '../../../interfaces';
import DatapointKey from '../../../interfaces/IDatapoint';
import { useUpdateLeadOpportunity } from '../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonAddresses from '../../../queries/people/usePersonAddresses';
import { useCreatePersonHome } from '../../../queries/people/usePersonHomes';
import { LIFE_PROFILE_FLOW, usePersonUIFlow, useSaveAnswers } from '../../../queries/workflows/useUiFlow';
import { InputSize, spacings } from '../../../theme/variables';
import { createSmartyStreetsMapping, renameKeys } from '../../../utils/object';
import { minDOBField, REQUIRED_MESSAGE, requiredAddressSchema, requiredField } from '../../../utils/yupRules';
import { StepContentProps } from '.';
import LeadWarnings from './_components/LeadWarnings';

interface FormValues {
  [DatapointKey.PersonLivingAddress]: IAddress;
  [DatapointKey.PersonDateOfBirth]: string;
  [DatapointKey.PersonSex]: string;
  [DatapointKey.PersonTobaccoUse]: string;
  [DatapointKey.PersonHealthStatus]: string;
  [DatapointKey.PersonCoverageLimit]: number;
  [DatapointKey.PersonTermLength]: string;
}

const LifeProfilePage = ({ onSubmit, page, dataCollection, isDataEditingForbidden }: StepContentProps) => {
  const { personGid, leadGid, lead } = useGuidedSellingExperienceContext();
  const { data: personAddresses } = usePersonAddresses(personGid);
  const todayDate = useConstant(() => new Date());

  const { data: uiFlowResponse } = usePersonUIFlow({ uiFlowKey: LIFE_PROFILE_FLOW, personGid });

  const uiFlow = buildUIFlow({ uiFlowResponse });

  const { mutateAsync: saveLifeProfileAnswers } = useSaveAnswers();

  const { mutateAsync: createHome } = useCreatePersonHome();
  const { mutateAsync: updateLeadOpportunity } = useUpdateLeadOpportunity();

  const defaultAddresses = personAddresses?.map(address =>
    renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
  ) as IAddressSuggestion[];

  if (!defaultAddresses || !uiFlow || !personGid || !leadGid) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  const verificationStatuses = uiFlow.answers.reduce(
    (acc, answer) => {
      return {
        ...acc,
        [answer.key]: verificationStatusForAnswer(answer)
      };
    },
    {} as Record<keyof FormValues, ReturnType<typeof verificationStatusForAnswer>>
  );

  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      controlsWidth={320}
      disabled={isDataEditingForbidden}
      submitText="Next"
      submitTestId="submit-life-profile-button"
      cancelVariant={ButtonVariant.Secondary}
      initialValues={uiFlow.initialValues<FormValues>()}
      validationSchema={yup.object().shape({
        [DatapointKey.PersonLivingAddress]: requiredAddressSchema,
        [DatapointKey.PersonDateOfBirth]: minDOBField().required(REQUIRED_MESSAGE),
        [DatapointKey.PersonSex]: requiredField,
        [DatapointKey.PersonTobaccoUse]: yup.string().required(REQUIRED_MESSAGE),
        [DatapointKey.PersonHealthStatus]: yup.string().required(REQUIRED_MESSAGE)
      })}
      onSubmit={async values => {
        await saveLifeProfileAnswers({
          uiFlowKey: LIFE_PROFILE_FLOW,
          answers: Object.keys(values)
            .map(key => ({
              question_key: key,
              person_gid: personGid,
              engagement_gid: leadGid,
              value: values[key as keyof typeof values],
              asset_gid: undefined
            }))
            .filter(({ value }) => !!value)
        });

        const { home } = await createHome({ personGid, data: { address: values[DatapointKey.PersonLivingAddress] } });
        if (lead && page.opportunities) {
          await Promise.all(
            page.opportunities.map(lifeOpportunity =>
              updateLeadOpportunity({
                leadId: lead.id,
                opportunityId: lifeOpportunity.id,
                params: { assets_gids: [home.gid] }
              })
            )
          );
        }

        onSubmit();
      }}
      renderForm={({ values }) => {
        return (
          <>
            <LeadWarnings dataCollection={dataCollection} />
            <FlexBox alignItemsCenter gap={spacings.px8} mb={spacings.px24}>
              <Heading type="h4">Life profile</Heading>
            </FlexBox>
            <FlexBox columnDirection pl={spacings.px16}>
              <FlexBox alignItemsBaseline>
                {verificationStatuses[DatapointKey.PersonLivingAddress] && (
                  <Container>
                    <QuestionVerificationStatusIcon
                      verificationStatus={verificationStatuses[DatapointKey.PersonLivingAddress]}
                      disabled={isDataEditingForbidden}
                      css={css`
                        margin-left: -${spacings.px20}px;
                      `}
                    />
                  </Container>
                )}
                <AddressField
                  inline
                  required
                  id={DatapointKey.PersonLivingAddress}
                  key={DatapointKey.PersonLivingAddress}
                  label="Living address"
                  defaultOptions={defaultAddresses}
                  fallbackNames={createSmartyStreetsMapping(`${DatapointKey.PersonLivingAddress}.`)}
                  defaultValue={
                    renameKeys(
                      API_TO_SMARTY_STREETS_MAPPING,
                      values[DatapointKey.PersonLivingAddress]
                    ) as IAddressSuggestion
                  }
                  inputSize={InputSize.Large}
                />
              </FlexBox>
              <FlexBox alignItemsBaseline>
                {verificationStatuses[DatapointKey.PersonDateOfBirth] && (
                  <Container>
                    <QuestionVerificationStatusIcon
                      verificationStatus={verificationStatuses[DatapointKey.PersonDateOfBirth]}
                      disabled={isDataEditingForbidden}
                      css={css`
                        margin-left: -${spacings.px20}px;
                      `}
                    />
                  </Container>
                )}
                <DateInputField
                  fsMask
                  inline
                  label="DOB"
                  name={DatapointKey.PersonDateOfBirth}
                  id={DatapointKey.PersonDateOfBirth}
                  key={DatapointKey.PersonDateOfBirth}
                  maxDate={todayDate}
                  required
                />
              </FlexBox>
              <FlexBox alignItemsBaseline>
                {verificationStatuses[DatapointKey.PersonSex] && (
                  <Container>
                    <QuestionVerificationStatusIcon
                      verificationStatus={verificationStatuses[DatapointKey.PersonSex]}
                      disabled={isDataEditingForbidden}
                      css={css`
                        margin-left: -${spacings.px20}px;
                      `}
                    />
                  </Container>
                )}
                <SelectField
                  id={DatapointKey.PersonSex}
                  name={DatapointKey.PersonSex}
                  label="Sex"
                  inline
                  required
                  options={(uiFlow.questionForDatapoint(DatapointKey.PersonSex)!.content.variants || []).map(v => ({
                    key: v.value,
                    value: v.label
                  }))}
                />
              </FlexBox>

              <FlexBox alignItemsBaseline>
                {verificationStatuses[DatapointKey.PersonTobaccoUse] && (
                  <Container>
                    <QuestionVerificationStatusIcon
                      verificationStatus={verificationStatuses[DatapointKey.PersonTobaccoUse]}
                      disabled={isDataEditingForbidden}
                      css={css`
                        margin-left: -${spacings.px20}px;
                      `}
                    />
                  </Container>
                )}

                <SelectField
                  label="Tobacco use"
                  inline
                  required
                  id={DatapointKey.PersonTobaccoUse}
                  name={DatapointKey.PersonTobaccoUse}
                  options={(uiFlow.questionForDatapoint(DatapointKey.PersonTobaccoUse)!.content.variants || []).map(
                    v => ({ key: v.value, value: v.label })
                  )}
                />
              </FlexBox>

              <FlexBox alignItemsBaseline>
                {verificationStatuses[DatapointKey.PersonHealthStatus] && (
                  <Container>
                    <QuestionVerificationStatusIcon
                      verificationStatus={verificationStatuses[DatapointKey.PersonHealthStatus]}
                      disabled={isDataEditingForbidden}
                      css={css`
                        margin-left: -${spacings.px20}px;
                      `}
                    />
                  </Container>
                )}

                <SelectField
                  label="Health status"
                  inline
                  required
                  id={DatapointKey.PersonHealthStatus}
                  name={DatapointKey.PersonHealthStatus}
                  options={(uiFlow.questionForDatapoint(DatapointKey.PersonHealthStatus)!.content.variants || []).map(
                    v => ({ key: v.value, value: v.label })
                  )}
                />
              </FlexBox>
            </FlexBox>
            <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
          </>
        );
      }}
    />
  );
};

export default LifeProfilePage;
