/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import FlexBox from '../../../../../../components/core/FlexBox';
import Text, { TextProps } from '../../../../../../components/core/Text';
import { INFO_NOT_PRESENT } from '../../../../../../constants';
import { IQuote } from '../../../../../../interfaces';
import { coverageByKey, PolicyCoverageKey } from '../../../../../../interfaces/IPolicyCoverage';
import {
  isInsurableInterestRealProperty,
  isInsurableInterestVehicle,
  PolicyType
} from '../../../../../../interfaces/IPolicyType';
import { spacings } from '../../../../../../theme/variables';
import { moneyFormatter } from '../../../../../../utils/formatter';

const Coverage = ({
  quote: {
    attributes: { policy_type, coverages }
  },
  type = 'default'
}: {
  quote: IQuote;
  type?: TextProps['type'];
}) => {
  if (isInsurableInterestRealProperty(policy_type)) {
    const dwellingCoverage = coverageByKey(coverages, PolicyCoverageKey.Dwelling);

    return (
      <>
        <FlexBox gap={spacings.px4}>
          <Text
            type={type}
            customCss={css`
              white-space: nowrap;
            `}
          >
            {dwellingCoverage?.limit_description || INFO_NOT_PRESENT}
          </Text>
        </FlexBox>
      </>
    );
  }

  if (isInsurableInterestVehicle(policy_type)) {
    const pdCoverage = coverageByKey(coverages, PolicyCoverageKey.PropertyDamage);
    const pdCoverageLabel = pdCoverage?.limit_value
      ? moneyFormatter(pdCoverage?.limit_value, true, 'compact')
      : INFO_NOT_PRESENT;

    return (
      <FlexBox columnDirection>
        <Text type={type}>{pdCoverageLabel}</Text>
      </FlexBox>
    );
  }

  if (policy_type === PolicyType.PAF) {
    const jewelryCoverage = coverageByKey(coverages, PolicyCoverageKey.Jewelry);

    return <Text type={type}>{jewelryCoverage?.limit_description || INFO_NOT_PRESENT}</Text>;
  }

  if (policy_type === PolicyType.TermLife) {
    const lifeCoverage = coverageByKey(coverages, PolicyCoverageKey.FaceAmount);

    return <Text type={type}>{lifeCoverage?.limit_description || INFO_NOT_PRESENT}</Text>;
  }

  return null;
};

export default Coverage;
