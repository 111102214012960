/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { v4 as uidGenerator } from 'uuid';

import Loader from '../../../components/common/Loader';
import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import SystemMessage from '../../../components/core/Alert/SystemMessage';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Select from '../../../components/core/inputs/Select';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { collectPolicyAssetsAddresses } from '../../../components/GenerateInvoiceModal/helpers';
import generateDocxReport, { CarrierAddress } from '../../../components/PolicyList/generateDocxReport';
import { IMaticPolicy, IPerson, ITask } from '../../../interfaces';
import { DocumentOwnerType, IDocument } from '../../../interfaces/IDocument';
import useFindAssignee from '../../../queries/assignees/useFindAssignee';
import useCarrier from '../../../queries/carriers/useCarrier';
import useDocumentTypes from '../../../queries/document_types/useDocumentTypes';
import {
  DOCS_QUERY_KEY,
  useCreateDocument,
  useDeleteDocument
} from '../../../queries/people/person_documents/usePersonDocuments';
import { PERSON_TASKS_QUERY_KEY } from '../../../queries/people/person_tasks/usePersonTasks';
import useUpdateTask from '../../../queries/people/person_tasks/useUpdateTask';
import { TASK_REMINDERS_QUERY_KEY } from '../../../queries/tasks/useTaskReminders';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import DocumentRow from './DocumentRow';

const GENERATE_INVOICE_TOOLTIP_TEXT =
  'There is no overnight and remittance address. Please, contact agency ops team to update the carrier data.';
const InvoiceGeneration = ({
  policy,
  person,
  documents,
  refetchDocuments,
  task
}: {
  policy: IMaticPolicy;
  person: IPerson;
  documents: IDocument[];
  refetchDocuments: () => void;
  task: ITask;
}) => {
  const queryClient = useQueryClient();
  // IPolicy.carrier does not have addresses exposed, so it is necessary to fetch carrier data via separate query
  const { data: carrier, isFetching } = useCarrier(policy.carrier.id);
  const { data: policyAgent } = useFindAssignee(policy.agent_id);
  const [generated, setGenerated] = React.useState(documents.length > 0);
  const [failed, setFailed] = React.useState(false);
  const { data: docTypes } = useDocumentTypes();
  const { mutateAsync: createDocument, isPending: isPendingFileUpload } = useCreateDocument({ attachDocs: false });
  const { mutateAsync: deleteDocument } = useDeleteDocument({ invalidate: false });
  const { mutate: updateTaskCompletion } = useUpdateTask(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] });
  });
  const [addressToInvoice, setAddressToInvoice] = React.useState<CarrierAddress>(
    carrier?.overnight_address?.full ? CarrierAddress.OvernightAddress : CarrierAddress.RemittanceAddress
  );

  const reportTitle = `${person.first_name}_${person.last_name}_${policy.policy_number}_invoice.docx`;

  const generateInvoice = async (address: CarrierAddress) => {
    await generateDocxReport({
      title: reportTitle,
      person,
      policy,
      carrier: {
        address: carrier?.[address],
        name: carrier?.name
      },
      agentName: policyAgent?.name,
      assetsAddresses: collectPolicyAssetsAddresses(policy),
      saveFile: false
    }).then(blob => {
      if (blob) {
        const invoiceDocTypeId = docTypes?.document_types?.find(document_type => document_type.key === 'invoice')?.id;

        if (invoiceDocTypeId) {
          createDocument({
            personGid: person.gid,
            request: {
              file: new File([blob], reportTitle),
              uid: uidGenerator(),
              owner_id: policy.id,
              owner_type: DocumentOwnerType.Policy,
              document_type_id: invoiceDocTypeId
            }
          })
            .then(() => {
              refetchDocuments();
              queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, person.gid] });
              setGenerated(true);
            })
            .catch(() => {
              setFailed(true);
            });
        }
      } else {
        setFailed(true);
      }
    });
  };

  return (
    <>
      {failed && (
        <>
          <Paragraph bold pb={spacings.px8}>
            Try to generate invoice again
          </Paragraph>
          <SystemMessage
            type="error"
            description="We could not upload the invoice document automatically.
            Please use the link above to download the invoice and manually upload it to the files tab."
            heading="Failed to upload the invoice"
          />
        </>
      )}

      {isPendingFileUpload && (
        <FlexBox gap={spacings.px8} backgroundColor={colors.white} p={spacings.px4} border roundBorder>
          <Loader width="20px" height="20px" />
          <Text color={colors.grey60}>Invoice uploading to files tab</Text>
        </FlexBox>
      )}

      {!isFetching && !isPendingFileUpload && !generated && (
        <>
          <FlexBox
            gap={spacings.px8}
            customCss={css`
              align-items: flex-end;
            `}
          >
            <Container
              css={css`
                width: 320px;
              `}
            >
              <Text pb={spacings.px4}>Address to invoice</Text>
              <Text color={colors.statusRed}>*</Text>
              <Select
                name="address_to_invoice"
                value={addressToInvoice}
                onChange={e => setAddressToInvoice(e.target.value as CarrierAddress)}
                options={[
                  {
                    key: CarrierAddress.OvernightAddress,
                    value: carrier?.overnight_address?.full,
                    description: 'Overnight address'
                  },
                  {
                    key: CarrierAddress.RemittanceAddress,
                    value: carrier?.remittance_address?.full,
                    description: 'Remittance address'
                  }
                ]}
              />
            </Container>
            <Button
              size={ButtonSize.Small}
              customCss={css`
                padding: 6px 12px;
              `}
              onClick={() => {
                generateInvoice(addressToInvoice);
                updateTaskCompletion({
                  personGid: person.gid,
                  taskId: task.id,
                  data: { ...task, completed: !task.completed }
                });
              }}
              disabled={!carrier?.overnight_address?.full && !carrier?.remittance_address?.full}
              data-tip={GENERATE_INVOICE_TOOLTIP_TEXT}
              data-for="generate-invoice"
            >
              <Text singleLine color={colors.white} bold>
                Generate invoice
              </Text>
            </Button>
            {!carrier?.overnight_address?.full && !carrier?.remittance_address?.full && (
              <Tooltip id="generate-invoice" />
            )}
          </FlexBox>
          <Text type="small">
            Once the invoice is generated, it will be automatically attached to the profile and will appear on the file
            tab.
          </Text>
        </>
      )}

      {generated && documents[0] && documents.length > 0 ? (
        <FlexBox columnDirection gap={spacings.px8}>
          <Paragraph bold>You generated invoice</Paragraph>
          <DocumentRow
            file={documents[0]}
            onDelete={() =>
              deleteDocument({ personGid: person.gid, documentId: documents[0]!.id }).then(() => {
                refetchDocuments();
                queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, person.gid] });
              })
            }
            preview={false}
          />
        </FlexBox>
      ) : null}
    </>
  );
};

export default InvoiceGeneration;
