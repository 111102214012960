import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IAddress, IPerson } from '../../interfaces';
import api from '../../services/api';
import { PERSON_QUERY_KEY } from './usePerson';
import { PERSON_RELATED_PEOPLE_QUERY_KEY } from './usePersonRelatedPeople';

const obtainPersonDrivingLicense = ({
  personGid,
  data
}: {
  personGid: string;
  data?: {
    address?: IAddress;
    engagement_gid?: string;
  };
}): Promise<IPerson> => api.post(`/api/frontend/people/${personGid}/obtain_license_number`, { body: data });

const useObtainPersonDrivingLicense = ({
  withRelatedPeopleInvalidation
}: {
  withRelatedPeopleInvalidation: boolean;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: obtainPersonDrivingLicense,
    onSuccess: (person, { personGid }) => {
      queryClient.setQueryData([PERSON_QUERY_KEY, personGid], person);
      if (withRelatedPeopleInvalidation) {
        queryClient.invalidateQueries({ queryKey: [PERSON_RELATED_PEOPLE_QUERY_KEY] });
      }
    }
  });
};

export default useObtainPersonDrivingLicense;
