/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { InfoIcon } from '../../../../../components/core/icons';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import { useToggle } from '../../../../../hooks';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';

const AllstateGuideModal = ({ cancelHandler }: { cancelHandler: () => void }) => (
  <Modal title="Allstate guide" size={ModalSize.extra} cancelHandler={cancelHandler} showCancelButton={false}>
    <FlexBox columnDirection gap={spacings.px12}>
      <Container>
        <Paragraph bold>Allstate marking</Paragraph>
        <Paragraph>
          home cross index: line code 10, company 27, relationship multi-tier auto cross index: line code 70, company
          64, relationship multi-tier
        </Paragraph>
      </Container>
      <Container>
        <Paragraph bold>Discounts</Paragraph>
        <Paragraph>
          Drivewise can add HUGE discounts, and it will never HURT the insured. the worst that can happen is the
          discount will drop off if never utilizedFuture Effective Date Discount 7+ days
        </Paragraph>
      </Container>
      <Container>
        <Paragraph bold>Quoting</Paragraph>
        <Paragraph>
          In order to bind home & auto you must run the MVR on both products. Forgetting to do so will leave the policy
          in a pended bound status. This is located on the final bind screen. It will ask you to bind, then ask
          permission to bind, ask to bind again, and finally ask you to click complete bind. Will let you bind an auto
          policy without running MVR but may cancel post bind. It is at the very bottom of the coverages page. Make sure
          this is ordered. Some states will automatically calculate mileage and lock it in.
        </Paragraph>
      </Container>
      <a
        // eslint-disable-next-line max-len
        href="https://maticinsurance.atlassian.net/wiki/spaces/AG/pages/3740532989/Allstate+Pended+Bound+--+NB-Complete+Steps"
        target="_blank"
        rel="noreferrer"
      >
        <Paragraph
          bold
          customCss={css`
            color: inherit;
          `}
        >
          View the confluence page for pended bound information.
        </Paragraph>
      </a>
    </FlexBox>
  </Modal>
);

const AllstateGuide = ({ boldText }: { boldText: boolean }) => {
  const [modalIsShown, toggleModal] = useToggle(false);
  return (
    <>
      {modalIsShown && <AllstateGuideModal cancelHandler={toggleModal} />}
      <Container>
        <FlexBox
          justifyRight
          customCss={css`
            flex: 1;
          `}
        >
          <ButtonWithoutBorder color={colors.azure50} onClick={toggleModal}>
            <FlexBox alignItemsCenter gap={spacings.px4}>
              <Text
                bold={boldText}
                customCss={css`
                  color: inherit;
                `}
              >
                Guide
              </Text>
              <InfoIcon />
            </FlexBox>
          </ButtonWithoutBorder>
        </FlexBox>
      </Container>
    </>
  );
};

export default AllstateGuide;
