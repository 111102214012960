import * as React from 'react';

const useCleanupLocalStorage = (keyPrefix: string) => {
  React.useEffect(() => {
    const keys = Object.keys(localStorage).filter(key => key.includes(keyPrefix));
    const dayAgo = Date.now() - 24 * 60 * 60 * 1000;
    keys.forEach(key => {
      const timestamp = parseInt(localStorage.getItem(key) || '', 10);
      if (timestamp < dayAgo) {
        localStorage.removeItem(key);
      }
    });
  }, [keyPrefix]);
};

export default useCleanupLocalStorage;
