import { skipToken, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import featureFlags from '../../constants/featureFlags';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface Disclosure {
  gid: string;
  kind: string;
  prompt?: string;
  content: string;
}

export interface Consent {
  kind: string;
  collected_by: string;
  provided_at: string;
  provided_till?: string;
}

interface PersonConsent {
  gid: string;
  person_gid: string;
  kind: string;
  provided_at: string;
  provided_till?: string;
}

export const AMP_POLICIES_DISCLOSURE = 'amp_policies';
export const AMP_FCRA_DISCLOSURE = 'amp_fcra';

export const CREDIT_PULL_CONSENT = 'credit_pull';
export const CLAIMS_PULL_CONSENT = 'claims_pull';
export const POLICY_CONSENT = 'policy';
export const CALLS_CONSENT = 'calls';
export const SMS_CONSENT = 'sms';

const NEW_FCRA_PROMPT = 'The insurance carriers will use your consumer report information to generate a quote.';
export const useFCRADisclosure = () =>
  useQuery({
    queryKey: [AMP_FCRA_DISCLOSURE],
    queryFn: (): Promise<{ disclosures: Disclosure[] }> =>
      api.get(`/api/frontend/disclosures?${toQueryParams({ kinds: [AMP_FCRA_DISCLOSURE] })}`),
    select: data =>
      featureFlags.newFcraPrompt && data.disclosures[0]
        ? {
            ...data.disclosures[0],
            prompt: NEW_FCRA_PROMPT
          }
        : data.disclosures[0]
  });

export const usePolicyDisclosure = () =>
  useQuery({
    queryKey: [AMP_POLICIES_DISCLOSURE],
    queryFn: (): Promise<{ disclosures: Disclosure[] }> =>
      api.get(`/api/frontend/disclosures?${toQueryParams({ kinds: [AMP_POLICIES_DISCLOSURE] })}`),
    select: data => data.disclosures[0],
    staleTime: Infinity
  });

export const acceptDisclosures = ({
  personGid,
  disclosures
}: {
  personGid: string;
  disclosures: Disclosure[];
}): Promise<{ person_consents: PersonConsent[] }> =>
  api.post(`/api/frontend/people/${personGid}/disclosures`, { body: { disclosures } });

export const useAcceptDisclosures = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: acceptDisclosures,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['person_active_consents'] });
      queryClient.invalidateQueries({ queryKey: ['person_active_fcra_consent'] });
      queryClient.invalidateQueries({ queryKey: ['person_active_policy_consent'] });
    }
  });
};

export const useActiveConsents = (personGid?: string) =>
  useQuery({
    queryKey: ['person_active_consents', personGid],
    queryFn: personGid
      ? (): Promise<{ consents: Consent[] }> => api.get(`/api/frontend/people/${personGid}/active_consents`)
      : skipToken,
    select: data => data.consents
  });

export const useActiveFCRAConsent = (personGid?: string) =>
  useQuery({
    queryKey: ['person_active_fcra_consent', personGid],
    queryFn: personGid
      ? (): Promise<{ consents: Consent[] }> => api.get(`/api/frontend/people/${personGid}/active_consents`)
      : skipToken,
    staleTime: Infinity,
    select: data =>
      data.consents.some(consent => consent.kind === CREDIT_PULL_CONSENT) &&
      data.consents.some(consent => consent.kind === CLAIMS_PULL_CONSENT)
  });

export const useActiveTCPAConsent = (personGid?: string) =>
  useQuery({
    queryKey: ['person_active_tcpa_consent', personGid],
    queryFn: personGid
      ? (): Promise<{ consents: Consent[] }> => api.get(`/api/frontend/people/${personGid}/active_consents`)
      : skipToken,
    staleTime: Infinity,
    select: data =>
      data.consents.some(consent => consent.kind === CALLS_CONSENT) ||
      data.consents.some(consent => consent.kind === SMS_CONSENT)
  });

export const useActiveTCPAConsents = ({ peopleGids }: { peopleGids: string[] }) => {
  const queries = useQueries({
    queries: peopleGids.map(personGid => ({
      queryKey: ['person_active_tcpa_consents', personGid],
      queryFn: (): Promise<{ consents: Consent[] }> => api.get(`/api/frontend/people/${personGid}/active_consents`),
      staleTime: Infinity
    }))
  });

  const data = peopleGids.reduce(
    (acc, personGid, index) => {
      acc[personGid] = queries[index]?.data?.consents?.filter(
        consent => consent.kind === CALLS_CONSENT || consent.kind === SMS_CONSENT
      );
      return acc;
    },
    {} as { [personGid: string]: Consent[] | undefined }
  );

  return { data, isPending: queries.some(query => query.isPending) };
};

export const useActivePolicyConsent = (personGid: string) =>
  useQuery({
    queryKey: ['person_active_policy_consent', personGid],
    queryFn: (): Promise<{ consents: Consent[] }> => api.get(`/api/frontend/people/${personGid}/active_consents`),
    select: data => data.consents.find(consent => consent.kind === POLICY_CONSENT)
  });
