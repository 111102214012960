import { IQuote } from '../../../../../interfaces';
import { OpportunityCoveragesTier } from '../../../../../interfaces/IOpportunity';
import { QuoteKind } from '../../../../../interfaces/IQuote';

const isMixedTierQuotePresent = (quotes: IQuote[]) =>
  quotes.find(({ attributes: { coverages_tier } }) => coverages_tier === OpportunityCoveragesTier.Mixed);

export const filterAutoQuotesByTier = (quotes: IQuote[]) => {
  // Quotes requested by agent via AMP.
  if (isMixedTierQuotePresent(quotes)) {
    // Filter quotes by "mixed" & "non_standard" tier. Include non-integrated options (without tier)
    return quotes.filter(
      ({ kind, attributes: { coverages_tier } }) =>
        [OpportunityCoveragesTier.Mixed, OpportunityCoveragesTier.NonStandard].includes(coverages_tier!) ||
        kind === QuoteKind.NonIntegrated
    );
  }

  const selectedQuote = quotes.find(({ attributes: { is_customer_choice } }) => is_customer_choice);

  // Quotes requested by a customer via CXP and customer selected a quote.
  if (selectedQuote?.attributes?.coverages_tier) {
    // Filter quotes by same tier that was selected on CXP. Include non-integrated options (without tier)
    return quotes.filter(
      ({ kind, attributes: { coverages_tier } }) =>
        coverages_tier === selectedQuote?.attributes?.coverages_tier || kind === QuoteKind.NonIntegrated
    );
  }

  // Quotes requested by customer via OLB.
  if (selectedQuote) {
    // Filter quotes by "default" tier. Include non-integrated options (without tier) and OLB quote
    return quotes.filter(
      ({ kind, attributes: { coverages_tier } }) =>
        coverages_tier === OpportunityCoveragesTier.Default ||
        kind === QuoteKind.NonIntegrated ||
        kind === QuoteKind.RC2
    );
  }

  // Quotes requested by a customer via CXP, no quote was selected. Filter quotes by "default" tier.
  return quotes.filter(
    ({ kind, attributes: { coverages_tier } }) =>
      coverages_tier === OpportunityCoveragesTier.Default || kind === QuoteKind.NonIntegrated
  );
};
