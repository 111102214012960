import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { LeadTypes } from '../../../interfaces/ISourceDimensions';
import { isAgent, isISR } from '../../../services/authInfo';

const useDisplayCrossSellWarning = () => {
  const { leadGid, sourceDimensions } = useGuidedSellingExperienceContext();
  const isCrossSell = sourceDimensions?.lead_type === LeadTypes.CrossSell;
  const isWinBack = sourceDimensions?.lead_type === LeadTypes.WinBack;
  const pastPolicyholderAssignmentAllowed = !featureFlags.winBackLeadsChanges && isWinBack;
  const policyholderAssignmentAllowed = !featureFlags.crossSellLeadChanges && isCrossSell;
  const displayWarning = !!(
    (policyholderAssignmentAllowed || pastPolicyholderAssignmentAllowed) &&
    leadGid &&
    (isISR() || isAgent())
  );

  return { displayWarning, leadGid, isCrossSell };
};

export default useDisplayCrossSellWarning;
