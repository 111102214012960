/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import * as yup from 'yup';

import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import {
  DateInputField,
  InputField,
  labelCss,
  NumericField,
  RadioGroupField,
  SelectField
} from '../../components/core/forms/fields';
import { DollarIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import Text from '../../components/core/Text';
import { isEndDisposition } from '../../components/DispositionsModals/dispositionsHelper';
import { Translations } from '../../constants';
import featureFlags from '../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useConstant } from '../../hooks';
import usePremiumConfirmation from '../../hooks/usePremiumConfirmation';
import { DocumentOwnerType, FileToUpload, IDocument, PolicyFileToProcess } from '../../interfaces/IDocument';
import { IOpportunity, OpportunityStatus } from '../../interfaces/IOpportunity';
import { IMaticPolicy, PolicyStatus, PolicyTransactionType } from '../../interfaces/IPolicy';
import {
  doesPolicyTypeRequireAsset,
  findPolicyType,
  InsuranceCategory,
  isNonLifeAncillaryPolicyType
} from '../../interfaces/IPolicyType';
import useAssignees from '../../queries/assignees/useAssignees';
import useCarriers from '../../queries/carriers/useCarriers';
import { useUpdateLeadOpportunity } from '../../queries/leads/opportunities/useLeadOpportunities';
import { createMaticPolicy, CreatePolicyRequest } from '../../queries/people/person_policies/useCreateMaticPolicy';
import usePersonMaticPolicies from '../../queries/people/person_policies/usePersonMaticPolicies';
import { updatePolicy } from '../../queries/people/person_policies/useUpdatePolicy';
import authInfo from '../../services/authInfo';
import { spacings } from '../../theme/variables';
import { dateFormatter, INTERNAL_DATE_FORMAT, moneyFormatter } from '../../utils/formatter';
import {
  existingPolicyEffectiveDateValidation,
  expirationDateValidation,
  newPolicyEffectiveDateValidation,
  REQUIRED_MESSAGE,
  requiredField
} from '../../utils/yupRules';
// eslint-disable-next-line max-len
import { useCurrentDispositionSubscriptionContext } from '../GuidedSellingExperience/_hooks/useCurrentDispositionSubscription';
import DeprecatedDocuments from './_components/DeprecatedDocuments';
import Documents from './_components/Documents';
import PDFFileViewer from './_components/PDFFileViewer';
import PolicyLegalConsent, { LEGAL_CONSENT_FIELD_KEY } from './_components/PolicyLegalConsent';
import {
  getOpportunityStatusInitialValue,
  getPeriodOptions,
  getPredefinedPeriod,
  retrievePolicyFromLocalStorage,
  STATUS_OPTIONS,
  uploadDocuments
} from './_helpers';
import {
  usePolicyDisclosureConsent,
  usePolicyNumberValidation,
  usePostSalesSync,
  useSaveDraftPolicy,
  useSoldPolicyTooltip
} from './_hooks';

interface IDefaultPolicyForm {
  opportunity: IOpportunity;
  policy?: IMaticPolicy;
  onSubmit: (values: DefaultPolicyValues) => void;
  children?: React.ReactNode;
  documents: IDocument[];
  refetchPolicies: ReturnType<typeof usePersonMaticPolicies>['refetch'];
  isDataEditingForbidden: boolean;
  showPolicyDisclosure: boolean;
}

export interface DefaultPolicyValues {
  status: (typeof STATUS_OPTIONS)[number]['value'];
  reason: string;
  carrier_id: string | number;
  policy_number: string;
  primary_coverage: string | number;
  effective_date: string;
  period: ReturnType<typeof getPeriodOptions>[number]['key'];
  payment_method: string;
  expiration_date: string;
  premium: string;
  agent_id: number;
  assets_gids: string[];
  files: (IDocument | PolicyFileToProcess)[];
  filesToUpload: FileToUpload[];
  document_rules_policies_attributes: CreatePolicyRequest['document_rules_policies_attributes'];
  [LEGAL_CONSENT_FIELD_KEY]: 'true' | '';
}

const DefaultFormRenderer = ({
  setFieldValue,
  opportunity,
  policy,
  values,
  children,
  isPolicyRequired,
  showPolicyDisclosure
}: FormikProps<DefaultPolicyValues> & {
  children?: React.ReactNode;
  opportunity: IOpportunity;
  policy?: IMaticPolicy;
  isPolicyRequired: boolean;
  showPolicyDisclosure: boolean;
}) => {
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const { data: carriers, isPending: isPendingFilteredCarriers } = useCarriers({
    policy_type: opportunity.policy_type
  });
  const { data: allCarriers, isPending: isPendingAllCarriers } = useCarriers();
  const carriersList = carriers && carriers.length > 0 ? carriers : allCarriers;
  const carrierOptions = React.useMemo(() => {
    return (carriersList || []).map(({ id, name }) => ({ key: id, value: name }));
  }, [carriersList]);

  const todayDate = useConstant(() => new Date());
  const { ConfirmationModal, validatePremiumCallback } = usePremiumConfirmation({});
  const { warning: warningMessage } = usePolicyNumberValidation({
    policy_number: values.policy_number,
    carrier_id: values.carrier_id,
    policy_type: opportunity.policy_type as string,
    state: opportunity.assets?.[0]?.address.state as string
  });

  const { setPostSalesSyncValues } = usePostSalesSync();

  React.useEffect(() => {
    setPostSalesSyncValues({
      carrierId: Number(values.carrier_id) || null,
      policyType: opportunity.policy_type,
      state: null
    });
  }, [setPostSalesSyncValues, opportunity.policy_type, values.carrier_id]);

  const syncDocumentRulesCallback = React.useCallback(
    (rules: DefaultPolicyValues['document_rules_policies_attributes']) => {
      setFieldValue('document_rules_policies_attributes', rules);
    },
    [setFieldValue]
  );

  useSaveDraftPolicy({ opportunityId: opportunity.id, values });

  const { data: assignees, isPending: isPendingAssignees } = useAssignees();
  const carrier = (carriersList || []).find(carrier => carrier.id === Number(values.carrier_id));
  const { Tip, labelProps } = useSoldPolicyTooltip();

  const showPrimaryFields =
    values.status === 'sold' &&
    (featureFlags.issuePolicyConsent && showPolicyDisclosure ? values[LEGAL_CONSENT_FIELD_KEY] === 'true' : true);

  const showSecondaryFields = carrier && showPrimaryFields;

  const policyType = findPolicyType(opportunity.policy_type);
  const DocumentsBlock = featureFlags.enablePolicyDocumentsUI ? Documents : DeprecatedDocuments;

  return (
    <>
      {children}
      <FlexBox columnDirection gap={spacings.px24} mt={spacings.px32}>
        <Container border p={spacings.px24} roundBorder>
          <FlexBox>
            <FlexBox {...(policy ? labelProps : {})}>
              <RadioGroupField
                required={isPolicyRequired}
                inline
                label={({ required }) => (
                  <Text type="large" bold customCss={labelCss(required)}>
                    Did you sell the policy?
                  </Text>
                )}
                id="status"
                name="status"
                options={
                  policy || isEndDisposition(currentDisposition?.disposition_type)
                    ? STATUS_OPTIONS.map(o => ({ ...o, disabled: true }))
                    : STATUS_OPTIONS
                }
              />
              {policy && <Tip />}
            </FlexBox>
          </FlexBox>
          {values.status === 'lost' && (
            <SelectField
              name="reason"
              id="reason"
              label="Reason"
              required
              options={Translations.opportunityLostReasonOptions}
              inline
            />
          )}
          {values.status === 'sold' && featureFlags.issuePolicyConsent && showPolicyDisclosure && (
            <PolicyLegalConsent disabled={!!policy} />
          )}

          {showPrimaryFields && (
            <SelectField
              name="carrier_id"
              id="carrier_id"
              label="Carrier"
              required
              options={carrierOptions}
              inline
              ordered
              isLoading={isPendingFilteredCarriers || isPendingAllCarriers}
            />
          )}
        </Container>
        {showSecondaryFields && (
          <>
            <Container border p={spacings.px24} roundBorder>
              <DocumentsBlock
                files={[
                  ...values.files.filter((file): file is IDocument => !('deleted' in file)),
                  ...values.filesToUpload
                ]}
                policyType={opportunity.policy_type}
                policyId={policy?.id || null}
                state={opportunity.state || undefined}
                carrier={carrier}
                syncDocumentRules={syncDocumentRulesCallback}
                onAttach={files => {
                  setFieldValue('filesToUpload', [...values.filesToUpload, ...files]);

                  return Promise.resolve();
                }}
                onDelete={fileToDelete => {
                  if ('uid' in fileToDelete) {
                    setFieldValue(
                      'filesToUpload',
                      values.filesToUpload.filter(file => file.uid !== fileToDelete.uid)
                    );
                  } else {
                    setFieldValue(
                      'files',
                      values.files.map(file => (file.id === fileToDelete.id ? { ...file, deleted: true } : file))
                    );
                  }

                  return Promise.resolve();
                }}
              />
            </Container>
            <Container id="policy-details" border p={spacings.px24} roundBorder>
              <Paragraph type="large" bold>
                Details
              </Paragraph>
              <InputField
                required
                inline
                label="Policy number"
                id="policy_number"
                name="policy_number"
                warning={warningMessage ? warningMessage : ''}
                fsMask
              />
              <DateInputField
                inline
                required
                label="Effective date"
                id="effective_date"
                name="effective_date"
                minDate={todayDate}
              />
              <SelectField
                name="period"
                id="period"
                label="Policy period"
                required
                options={getPeriodOptions(opportunity.policy_type)}
                inline
              />
              {values.period === 'custom' && (
                <DateInputField
                  inline
                  required
                  label="Expiration date"
                  id="expiration_date"
                  name="expiration_date"
                  minDate={todayDate}
                />
              )}
              <SelectField
                name="payment_method"
                id="payment_method"
                label="Billing type"
                required
                options={Translations.paymentMethodOptions}
                inline
              />
              <NumericField
                inline
                required
                label="Premium"
                id="premium"
                name="premium"
                valueIsNumber
                prefixIcon={<DollarIcon />}
                fieldProps={{
                  validate: (value: number) => validatePremiumCallback(value || 0)
                }}
              />
              {policyType?.category === InsuranceCategory.Life && (
                <SelectField
                  name="primary_coverage"
                  id="primary_coverage"
                  label="Coverage limit"
                  required
                  createOptionFromSearch
                  formatOptionLabel={({ value }) => moneyFormatter(value, true)}
                  formatCreateLabel={value => moneyFormatter(value, true)}
                  options={[
                    { key: 100_000, value: 100_000 },
                    { key: 150_000, value: 150_000 },
                    { key: 200_000, value: 200_000 },
                    { key: 250_000, value: 250_000 },
                    { key: 300_000, value: 300_000 },
                    { key: 400_000, value: 400_000 },
                    { key: 500_000, value: 500_000 },
                    { key: 600_000, value: 600_000 },
                    { key: 700_000, value: 700_000 },
                    { key: 800_000, value: 800_000 },
                    { key: 900_000, value: 900_000 },
                    { key: 1_000_000, value: 1_000_000 },
                    { key: 5_000_000, value: 5_000_000 },
                    { key: 10_000_000, value: 10_000_000 }
                  ]
                    .concat(
                      policy?.primary_coverage ? [{ key: policy.primary_coverage, value: policy.primary_coverage }] : []
                    )
                    .sort((a, b) => a.key - b.key)}
                  inline
                />
              )}
              <SelectField
                name="agent_id"
                id="agent_id"
                label="Agent of record"
                required
                options={(assignees || []).map(({ id, name }) => ({ key: id, value: name }))}
                inline
                ordered
                isLoading={isPendingAssignees}
              />
            </Container>
            <ConfirmationModal />
          </>
        )}
      </FlexBox>
    </>
  );
};

const buildInitialValues = ({
  opportunity,
  policy,
  files
}: Pick<IDefaultPolicyForm, 'opportunity' | 'policy'> & { files: IDocument[] }) => {
  const {
    status,
    reason,
    carrier_id,
    policy_number,
    effective_date,
    period,
    expiration_date,
    payment_method,
    primary_coverage,
    premium,
    agent_id,
    assets_gids,
    [LEGAL_CONSENT_FIELD_KEY]: legal_consent
  } = retrievePolicyFromLocalStorage(opportunity.id) || {};

  const consentValue = policy ? ('true' as const) : legal_consent || ('' as const);

  return {
    status: getOpportunityStatusInitialValue({ opportunity, policy, cachedStatus: status }),
    reason: reason || opportunity.reason || '',
    carrier_id: policy?.carrier.id || carrier_id || '',
    policy_number: policy?.policy_number || policy_number || '',
    effective_date: policy?.effective_date || effective_date || '',
    period: policy ? getPredefinedPeriod(policy) : period || ('' as ReturnType<typeof getPeriodOptions>[number]['key']),
    expiration_date: policy?.expiration_date || expiration_date || '',
    payment_method: policy?.payment_method || payment_method || '',
    premium: policy?.premium || premium || '',
    primary_coverage: policy?.primary_coverage || primary_coverage || '',
    agent_id: policy?.agent_id || agent_id || authInfo.currentUserId!,
    files: (policy ? policy.files || [] : files) as DefaultPolicyValues['files'],
    filesToUpload: [] as FileToUpload[],
    document_rules_policies_attributes: [] as DefaultPolicyValues['document_rules_policies_attributes'],
    assets_gids: policy?.assets?.map(a => a.gid) || assets_gids || opportunity.assets?.map(a => a.gid) || [],
    [LEGAL_CONSENT_FIELD_KEY]: consentValue
  };
};

const DefaultPolicyForm = ({
  opportunity,
  policy,
  onSubmit,
  children,
  documents,
  refetchPolicies,
  isDataEditingForbidden,
  showPolicyDisclosure
}: IDefaultPolicyForm): JSX.Element => {
  const { lead, personGid } = useGuidedSellingExperienceContext();

  const { mutateAsync: updateOpportunity } = useUpdateLeadOpportunity();
  const opportunityDocs = documents.filter(
    doc => doc.owner_type === DocumentOwnerType.Opportunity && doc.owner_id === opportunity.id
  );
  const isPolicyRequired =
    opportunity.primary || !isNonLifeAncillaryPolicyType({ policy_type: opportunity.policy_type });

  const acceptPolicyDisclosure = usePolicyDisclosureConsent();

  const policyType = findPolicyType(opportunity.policy_type);

  return (
    <BaseForm
      controlsAlignment="right"
      controlsWidth={320}
      pb={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      pt={spacings.px24}
      type="fullPage"
      disabled={isDataEditingForbidden}
      submitText="Save and proceed"
      initialValues={buildInitialValues({ opportunity, policy, files: opportunityDocs })}
      validationSchema={yup.object().shape({
        assets_gids: yup
          .array()
          .ensure()
          .when(['status'], ([status], schema) => {
            if (status === 'sold' && doesPolicyTypeRequireAsset(opportunity.policy_type)) {
              return schema.min(1, 'Select at least one asset');
            }

            return schema;
          }),
        status: isPolicyRequired ? requiredField : yup.string(),
        reason: yup.string().when(['status'], ([status], schema) => {
          if (status === 'lost') {
            return schema.required(REQUIRED_MESSAGE);
          }

          return schema;
        }),
        carrier_id: yup.string().when(['status'], ([status], schema) => {
          if (status === 'sold') {
            return schema.required(REQUIRED_MESSAGE);
          }

          return schema;
        }),
        policy_number: yup.string().when(['status'], ([status], schema) => {
          if (status === 'sold') {
            return schema.required(REQUIRED_MESSAGE);
          }

          return schema;
        }),
        effective_date: yup.date().when(['status'], ([status], schema) => {
          if (status === 'sold') {
            return policy ? existingPolicyEffectiveDateValidation(schema) : newPolicyEffectiveDateValidation(schema);
          }

          return schema;
        }),
        period: yup.string().when(['status'], ([status], schema) => {
          if (status === 'sold') {
            return schema.required(REQUIRED_MESSAGE);
          }

          return schema;
        }),
        payment_method: yup.string().when(['status'], ([status], schema) => {
          if (status === 'sold') {
            return schema.required(REQUIRED_MESSAGE);
          }

          return schema;
        }),
        expiration_date: yup.date().when(['period', 'effective_date'], ([period, effective_date], schema) => {
          if (period === 'custom') {
            return expirationDateValidation(schema, effective_date);
          }

          return schema;
        }),
        premium: yup.string().when(['status'], ([status], schema) => {
          if (status === 'sold') {
            return schema.required(REQUIRED_MESSAGE);
          }

          return schema;
        }),
        primary_coverage: yup.string().when(['status'], ([status], schema) => {
          if (status === 'sold' && policyType?.category === InsuranceCategory.Life) {
            return schema.required(REQUIRED_MESSAGE);
          }

          return schema;
        }),
        agent_id: yup.string().when(['status'], ([status], schema) => {
          if (status === 'sold') {
            return schema.required(REQUIRED_MESSAGE);
          }
          return schema;
        }),
        [LEGAL_CONSENT_FIELD_KEY]: yup.string().when(['status'], ([status], schema) => {
          if (featureFlags.issuePolicyConsent && status === 'sold' && !policy && showPolicyDisclosure) {
            return schema.equals(['true'], 'You must confirm verbal consent').required(REQUIRED_MESSAGE);
          }

          return schema;
        })
      })}
      onSubmit={async values => {
        if (values.status === 'lost' && lead) {
          await updateOpportunity({
            leadId: lead.id,
            opportunityId: opportunity.id,
            params: { status: OpportunityStatus.Lost, reason: values.reason }
          });

          onSubmit(values);
        }

        if (values.status === 'sold' && lead && !policy && personGid) {
          if (showPolicyDisclosure ? !(await acceptPolicyDisclosure({ personGid })) : false) {
            return;
          }

          await updateOpportunity({
            leadId: lead.id,
            opportunityId: opportunity.id,
            params: { status: OpportunityStatus.Sold }
          });

          const { policy: createdPolicy } = await createMaticPolicy({
            person_gid: personGid!,
            lead_id: lead.id,
            transaction_type: PolicyTransactionType.NEW_BUSINESS,
            policy_type: opportunity.policy_type,
            premium: Number(values.premium),
            agent_id: values.agent_id.toString(),
            carrier_id: Number(values.carrier_id),
            status: PolicyStatus.BOUND,
            policy_number: values.policy_number,
            primary_coverage: values.primary_coverage ? Number(values.primary_coverage) : undefined,
            sale_date: dateFormatter(new Date(), INTERNAL_DATE_FORMAT),
            effective_date: values.effective_date,
            expiration_date:
              values.period === 'custom'
                ? values.expiration_date
                : moment(values.effective_date).add(values.period, 'months').format(INTERNAL_DATE_FORMAT),
            payment_method: values.payment_method,
            asset_gids: values.assets_gids,
            document_rules_policies_attributes: values.document_rules_policies_attributes
          });

          await uploadDocuments({
            personGid,
            policyId: createdPolicy.id,
            files: values.files,
            filesToUpload: values.filesToUpload,
            onSuccess: () => onSubmit(values)
          });

          refetchPolicies();
        }

        if (values.status === 'sold' && lead && policy) {
          await updatePolicy({
            person_gid: personGid!,
            policy_id: policy.id,
            premium: Number(values.premium),
            agent_id: values.agent_id,
            policy_number: values.policy_number,
            primary_coverage: values.primary_coverage ? Number(values.primary_coverage) : undefined,
            carrier_id: Number(values.carrier_id),
            effective_date: values.effective_date,
            expiration_date:
              values.period === 'custom'
                ? values.expiration_date
                : moment(values.effective_date).add(values.period, 'months').format(INTERNAL_DATE_FORMAT),
            transaction_type: policy.transaction_type,
            status: policy.status,
            sale_date: policy.sale_date,
            policy_type: opportunity.policy_type,
            document_rules_policies_attributes: values.document_rules_policies_attributes,
            asset_gids: values.assets_gids
          });

          await uploadDocuments({
            personGid: personGid!,
            policyId: policy.id,
            files: values.files,
            filesToUpload: values.filesToUpload,
            onSuccess: () => onSubmit(values)
          });

          refetchPolicies();
        }
      }}
      renderForm={formikBag => (
        <FlexBox gap={spacings.px48}>
          <FlexBox
            columnDirection
            customCss={css`
              flex: 1;
            `}
          >
            <DefaultFormRenderer
              {...formikBag}
              opportunity={opportunity}
              policy={policy}
              isPolicyRequired={isPolicyRequired}
              showPolicyDisclosure={showPolicyDisclosure}
            >
              {children}
            </DefaultFormRenderer>
          </FlexBox>
          <PDFFileViewer />
        </FlexBox>
      )}
    />
  );
};

export default DefaultPolicyForm;
