/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import Container from '../../../../../../components/core/Container';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';

interface IHeaderCell extends React.HTMLProps<HTMLDivElement> {
  bordered?: boolean;
  width?: number;
}

const HeaderCell = ({ bordered = true, width = 140, children }: IHeaderCell) => (
  <Container
    pv={spacings.px8}
    mv={spacings.px8}
    pl={spacings.px8}
    customCss={css`
      min-width: ${width}px;
      ${bordered && `border-left: 1px solid ${colors.grey10}`};
    `}
  >
    {children}
  </Container>
);

export default HeaderCell;
