import { Grid } from '@mui/material';

import Container from '../../../../../../components/core/Container';
import FlexBox from '../../../../../../components/core/FlexBox';
import Text from '../../../../../../components/core/Text';
import { INFO_NOT_PRESENT } from '../../../../../../constants';
import { IGroupedQuotes, IQuoteAttributes } from '../../../../../../interfaces';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';
import { moneyFormatter } from '../../../../../../utils/formatter';

const ExpandedHome = ({
  quoteAttributes: { asset_gids, coverages, premium },
  assets
}: {
  quoteAttributes: IQuoteAttributes;
  assets: IGroupedQuotes['assets'];
}) => {
  const assetGid = asset_gids?.[0];
  const asset = assets.find(({ gid }) => gid === assetGid);
  const assetCoverages = coverages?.filter(coverage => coverage.asset_gid === assetGid);

  return (
    <Container ml={spacings.px32}>
      <Grid container columns={24}>
        <Grid item xs={20}>
          <Text bold>{asset?.label}</Text>
        </Grid>
        <Grid item xs={4}>
          <FlexBox columnDirection justifyRight>
            <>
              {premium ? (
                <Text bold type="small">
                  {`${moneyFormatter(premium, true)} | ${moneyFormatter(premium / 12, true)}/m`}
                </Text>
              ) : (
                <Text type="small">{INFO_NOT_PRESENT}</Text>
              )}
              <Text color={colors.grey60} type="small">
                Premium
              </Text>
            </>
          </FlexBox>
        </Grid>
      </Grid>
      <Grid container columns={24}>
        <Grid item container columns={3} maxWidth="sm" columnSpacing={2}>
          {assetCoverages?.map(coverage => (
            <Grid item xs={1} key={coverage.key}>
              <FlexBox columnDirection pv={spacings.px8}>
                {coverage.limit_description || coverage.deductible_description ? (
                  <Text bold>{coverage.limit_description || coverage.deductible_description}</Text>
                ) : (
                  <Text>{INFO_NOT_PRESENT}</Text>
                )}
                <Text color={colors.grey60}>{coverage?.title}</Text>
              </FlexBox>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExpandedHome;
