/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Fade from '@mui/material/Fade';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ChatSmileIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import { usePopoversQueue, usePopper } from '../../../../hooks';
import colors from '../../../../theme/colors';
import { borderRadius, spacings } from '../../../../theme/variables';
import useDataCollectionStepsState from '../../GuidedDataCollection/_hooks/useDataCollectionStepsState';
import { circleIconCss, DynamicTip, DynamicTipsKeys, getDynamicTips } from './helpers';
import PreviousTips from './PreviousTips';

const CurrentTips = ({
  dynamicTips,
  currentTipKey,
  hoveredOverTips
}: {
  dynamicTips: DynamicTip[];
  currentTipKey: DynamicTipsKeys;
  hoveredOverTips: DynamicTipsKeys[];
}) => {
  return (
    <>
      {dynamicTips.map(tip => {
        return (
          <>
            {tip.displayed && (
              <>
                <FlexBox
                  pv={spacings.px8}
                  ph={spacings.px12}
                  gap={spacings.px8}
                  backgroundColor={hoveredOverTips.includes(currentTipKey) ? undefined : colors.violetBackground}
                  roundBorder
                  customCss={css`
                    transition: background-color 0.3s;
                  `}
                >
                  <Container p={spacings.px8} backgroundColor={colors.violet} customCss={circleIconCss}>
                    <ChatSmileIcon color={colors.white} />
                  </Container>

                  {tip.component}
                </FlexBox>
              </>
            )}
          </>
        );
      })}
    </>
  );
};

const DynamicTips = ({
  isDisclosureAccepted,
  withLifeTip
}: {
  isDisclosureAccepted: boolean;
  withLifeTip: boolean;
}) => {
  const { pathname } = useLocation();
  const { steps } = useDataCollectionStepsState();
  const activeDataCollectionStep = steps?.find(step => step.active);

  const shownTipsRef = React.useRef<DynamicTipsKeys[]>([]);
  const [hoveredOverTips, setHoveredOverTips] = React.useState<DynamicTipsKeys[]>([]);

  const { active: activePopoverInQueue, addPopover, removePopover } = usePopoversQueue();
  const { setAnchorEl, anchorEl, PopperComponent, popperProps } = usePopper({
    placement: 'bottom-end',
    disablePortal: true,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [4, 20]
        }
      }
    ],
    style: { zIndex: 999 }
  });

  const buttonRef = React.useRef<HTMLButtonElement>();
  const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

  const dynamicTips = getDynamicTips({ pathname, isDisclosureAccepted, activeDataCollectionStep, withLifeTip });

  const currentTip = dynamicTips.find(tip => tip.displayed);
  const currentTipKey = currentTip?.key;

  // Add the popover to queue after 3 seconds, if wasn't shown before
  React.useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (currentTipKey && !shownTipsRef.current.includes(currentTipKey)) {
        addPopover(currentTipKey);
      }
    }, 3000);

    return () => {
      clearTimeout(timerRef.current);
      currentTipKey && removePopover(currentTipKey);
      timerRef.current = undefined;
    };
  }, [addPopover, removePopover, currentTipKey]);

  // Automatically show the popover if it's the active one and wasn't shown before
  React.useEffect(() => {
    if (
      activePopoverInQueue &&
      currentTipKey &&
      activePopoverInQueue === currentTipKey &&
      !shownTipsRef.current.includes(currentTipKey) &&
      buttonRef.current
    ) {
      shownTipsRef.current = [...shownTipsRef.current, currentTipKey];
      setAnchorEl(buttonRef.current);
    }

    if (!currentTipKey) {
      setAnchorEl(null);
    }
  }, [currentTipKey, activePopoverInQueue, setAnchorEl]);

  // Make sure UI is synced with the queue
  React.useEffect(() => {
    if (anchorEl && currentTipKey && !activePopoverInQueue) {
      addPopover(currentTipKey);
    }
  }, [activePopoverInQueue, addPopover, currentTipKey, anchorEl]);

  if (!currentTipKey) {
    return null;
  }

  return (
    <Container>
      <ButtonWithoutBorder
        ref={buttonRef}
        onClick={e => {
          if (anchorEl) {
            removePopover(currentTipKey);
            setAnchorEl(null);
            return;
          }

          addPopover(currentTipKey);
          if (!shownTipsRef.current.includes(currentTipKey)) {
            shownTipsRef.current = [...shownTipsRef.current, currentTipKey];
          }

          setAnchorEl(e.currentTarget);

          if (timerRef.current) {
            clearTimeout(timerRef.current);
            if (!shownTipsRef.current.includes(currentTipKey)) {
              shownTipsRef.current = [...shownTipsRef.current, currentTipKey];
            }

            timerRef.current = undefined;
          }
        }}
        p={spacings.px8}
        customCss={css`
          border-radius: ${borderRadius}px;
          ${anchorEl ? `background-color: ${colors.grey5};` : ''}
          &:hover {
            background-color: ${colors.grey5};
          }
        `}
      >
        <ChatSmileIcon color={colors.violet} />
      </ButtonWithoutBorder>
      <PopperComponent {...popperProps} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <FlexBox
              columnDirection
              backgroundColor={colors.white}
              roundBorder
              border
              boxShadow
              gap={spacings.px8}
              p={spacings.px8}
              onMouseOver={() =>
                setHoveredOverTips(current => (current.includes(currentTipKey) ? current : [...current, currentTipKey]))
              }
              customCss={css`
                max-width: 500px;
              `}
            >
              <FlexBox justifySpaceBetween alignItemsCenter>
                <Paragraph bold>Don&apos;t forget to tell...</Paragraph>
                <IconButton
                  color={colors.black}
                  icon={ButtonIcons.Close}
                  onClick={() => {
                    setAnchorEl(null);
                    removePopover(currentTipKey);
                  }}
                />
              </FlexBox>
              <CurrentTips dynamicTips={dynamicTips} currentTipKey={currentTipKey} hoveredOverTips={hoveredOverTips} />

              <PreviousTips dynamicTips={dynamicTips} currentTip={currentTip} anchorEl={anchorEl} />
            </FlexBox>
          </Fade>
        )}
      </PopperComponent>
    </Container>
  );
};

export default DynamicTips;
