/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../components/core/Container';
import colors from '../../theme/colors';

const CustomerViewHeader = () => {
  return (
    <Container
      backgroundColor={colors.grey5}
      fitParentWidth
      borderBottom
      css={css`
        height: 212px;
      `}
    >
      Header
    </Container>
  );
};

export default CustomerViewHeader;
