import React from 'react';

import TablePlaceholder from '../../../../components/TablePlaceholder';
import { QuotingAccessLevel } from '../../../../interfaces/IQuotingAccess';
import useLeadQuotingAccess from '../../../../queries/leads/useLeadQuotingAccess';
import authInfo from '../../../../services/authInfo';
import QuotingRestriction from './_components/QuotingRestriction';
import QuotesRequests from './QuotesRequests';

const RestrictQuotesForLead = (props: {
  leadId: number | undefined;
  leadGid: string | undefined;
  personGid: string;
  onGetQuotes: () => void;
}) => {
  const { data: quotingAccess, isLoading } = useLeadQuotingAccess(props.leadId);

  if (isLoading || !quotingAccess) {
    return <TablePlaceholder testId="leads-quoting-access-loader" />;
  }

  if (
    quotingAccess.access_level !== QuotingAccessLevel.QuotingAccessAllowed &&
    !authInfo.features.review_quotes_outside_pipeline
  ) {
    return <QuotingRestriction accessLevel={quotingAccess.access_level} />;
  }

  return <QuotesRequests {...props} />;
};

const Quotes = (props: {
  leadId: number | undefined;
  leadGid: string | undefined;
  personGid: string;
  onGetQuotes: () => void;
}) => {
  if (typeof props.leadId === 'undefined') {
    return <QuotesRequests {...props} />;
  } else {
    return <RestrictQuotesForLead {...props} />;
  }
};

export default Quotes;
