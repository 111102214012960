import * as React from 'react';

import { IHome, IOpportunity, IPerson, IPersonAddress, IRelatedPerson, IVehicle } from '../../interfaces';
import { IUserLicense } from '../../interfaces/IUserLicenses';
import { Disclosure } from '../../queries/disclosures/useDisclosure';
import { startQuoting } from '../../queries/quotes_requests/useStartQuoting';
import analytics from '../../services/analytics';
import LoadingPage from './_components/LoadingPage';
import { SelectedHome, SelectedVehicle, SelectedVehicleWithCoverages } from './_interfaces/ISelectedAsset';
import AssetsPicker from './AssetsPicker';
import DriversPicker from './DriversPicker';
import VehiclesCoveragesPicker from './VehiclesCoveragesPicker';

enum Step {
  AssetsPicker = 'assets_picker',
  DriversPicker = 'drivers_picker',
  VehiclesCoveragesPicker = 'vehicles_coverages_picker'
}

const buildHomesQuotingRequests = (selectedHomes: SelectedHome[] | undefined) =>
  selectedHomes?.map(home => ({
    asset_gid: home.gid,
    dwelling: home.dwelling_coverage,
    personal_property: home.personal_property,
    deductible_value: home.deductible,
    carrier_keys: home.all_carriers ? undefined : home.carriers,
    coinsured_gid: home.coinsured_gid
  }));

const buildAutoQuotingRequest = (
  drivers: IRelatedPerson[] | undefined,
  vehiclesWithCoverages: SelectedVehicleWithCoverages[]
) => ({
  prior_policy_expiration_date: vehiclesWithCoverages[0]!.prior_policy_expiration_date,
  drivers_gids: drivers?.map(({ gid }) => gid),
  vehicles: vehiclesWithCoverages.map(({ gid, coverages }) => ({
    asset_gid: gid,
    coverages
  }))
});

interface GetQuotesProps {
  isPending: boolean;
  leadId: string | undefined;
  person: IPerson | undefined;
  fcraDisclosure: Disclosure | undefined;
  isFcraDisclosureAccepted: boolean | undefined;
  homes: IHome[] | undefined;
  vehicles: IVehicle[] | undefined;
  selectAllAssets: boolean;
  relatedPeople: IRelatedPerson[] | undefined;
  opportunities: IOpportunity[] | undefined;
  personAddresses: IPersonAddress[] | undefined;
  refetchPerson: () => void;
  refetchVehicles: () => void;
  refetchDrivers: () => void;
  userLicenses: IUserLicense[] | undefined;
  onClose: () => void;
  onQuotingStarted: () => void;
}

const GetQuotes = ({
  isPending,
  leadId,
  person,
  fcraDisclosure,
  isFcraDisclosureAccepted,
  homes,
  vehicles,
  selectAllAssets,
  relatedPeople,
  opportunities,
  personAddresses,
  refetchPerson,
  refetchVehicles,
  refetchDrivers,
  userLicenses,
  onClose,
  onQuotingStarted
}: GetQuotesProps) => {
  const [currentStep, setCurrentStep] = React.useState(Step.AssetsPicker);

  const [selectedHomes, setSelectedHomes] = React.useState<SelectedHome[] | undefined>(
    selectAllAssets ? (homes as SelectedHome[]) : undefined
  );
  const [selectedVehicles, setSelectedVehicles] = React.useState<SelectedVehicle[] | undefined>(
    selectAllAssets ? (vehicles as SelectedVehicle[]) : undefined
  );
  const [selectedJewelry, setSelectedJewelry] = React.useState<number | null>();
  const [drivers, setSelectedDrivers] = React.useState<IRelatedPerson[]>();

  if (isPending) {
    return <LoadingPage onClose={onClose} />;
  }

  return (
    <>
      {currentStep === Step.AssetsPicker && (
        <AssetsPicker
          person={person!}
          leadId={leadId}
          fcraDisclosure={fcraDisclosure!}
          isFcraDisclosureAccepted={isFcraDisclosureAccepted}
          opportunities={opportunities}
          personAddresses={personAddresses}
          homes={homes}
          relatedPeople={relatedPeople}
          vehicles={vehicles}
          selectedHomes={selectedHomes}
          selectedVehicles={selectedVehicles}
          selectedJewelry={selectedJewelry}
          userLicenses={userLicenses}
          onHomesSelected={setSelectedHomes}
          onVehiclesSelected={setSelectedVehicles}
          onCancel={onClose}
          onSubmit={(selectedHomes, selectedVehicles, selectedJewelry) => {
            if (!selectedVehicles?.length) {
              return startQuoting({
                personGid: person!.gid,
                leadId,
                startQuotingRequest: {
                  homes: buildHomesQuotingRequests(selectedHomes),
                  paf: selectedJewelry
                }
              }).then(() => {
                analytics.track('Quotes request started', {
                  leadId,
                  person_gid: person?.gid,
                  place: leadId ? 'old_lead_profile_ui' : 'old_customer_profile_ui'
                });
                return onQuotingStarted();
              });
            }

            setSelectedHomes(selectedHomes);
            setSelectedVehicles(selectedVehicles);
            setSelectedJewelry(selectedJewelry);
            setCurrentStep(Step.DriversPicker);
            refetchVehicles();
          }}
        />
      )}
      {currentStep === Step.DriversPicker && (
        <DriversPicker
          person={person!}
          leadId={leadId}
          personAddresses={personAddresses}
          selectedVehicles={selectedVehicles}
          relatedPeople={relatedPeople}
          refetchDrivers={refetchDrivers}
          onBack={() => setCurrentStep(Step.AssetsPicker)}
          onClose={onClose}
          onSubmit={drivers => {
            setSelectedDrivers(drivers);

            refetchPerson();

            setCurrentStep(Step.VehiclesCoveragesPicker);
          }}
        />
      )}
      {currentStep === Step.VehiclesCoveragesPicker && (
        <VehiclesCoveragesPicker
          person={person!}
          leadId={leadId}
          selectedVehicles={selectedVehicles!}
          onBack={() => setCurrentStep(Step.DriversPicker)}
          onClose={onClose}
          onSubmit={vehiclesWithCoverages =>
            startQuoting({
              personGid: person!.gid,
              leadId,
              startQuotingRequest: {
                homes: buildHomesQuotingRequests(selectedHomes),
                autos: [buildAutoQuotingRequest(drivers, vehiclesWithCoverages)],
                paf: selectedJewelry
              }
            }).then(() => {
              analytics.track('Quotes request started', {
                lead_gid: undefined,
                person_gid: person?.gid,
                place: leadId ? 'old_lead_profile_ui' : 'old_customer_profile_ui'
              });
              return onQuotingStarted();
            })
          }
        />
      )}
    </>
  );
};

export default GetQuotes;
