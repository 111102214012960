/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../components/core/FlexBox';
import { ChevronRight } from '../../../../components/core/icons';
import Note from '../../../../components/core/Note';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { NoteKind } from '../../../../interfaces/INote';
import useInfiniteNotes from '../../../../queries/people/person_notes/useInfiniteNotes';
import useCreatePersonNote from '../../../../queries/people/useCreatePersonNote';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import CreateNoteTextarea from './CreateNoteTextarea';

const Notes = ({ personGid }: { personGid: string }) => {
  const { data, isSuccess, hasNextPage, fetchNextPage, refetch, isFetched, isFetching } = useInfiniteNotes({
    personGid
  });

  const { mutate: createNote } = useCreatePersonNote(refetch);

  const onSubmitNewNote = (note: string) => {
    const isNoteEmpty = note.replace(/\s/g, '') === '';
    if (isNoteEmpty) {
      return;
    }

    createNote({
      kind: NoteKind.Memo,
      description: note,
      person_gid: personGid
    });
  };

  const notes = data?.pages.flatMap(({ notes }) => notes) || [];
  const isNotesPresent = isSuccess && data?.pages && notes.length > 0;

  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Notes</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        <FlexBox mb={spacings.px12} mt={spacings.px4}>
          <CreateNoteTextarea resizeForbidden height={72} onSubmit={onSubmitNewNote} />
        </FlexBox>

        {isFetched && !isNotesPresent && (
          <FlexBox justifyCenter mv={spacings.px6}>
            <Text>No notes added yet</Text>
          </FlexBox>
        )}

        {isFetched && isNotesPresent && (
          <FlexBox pb={spacings.px8} columnDirection gap={spacings.px8}>
            {notes.map(note => (
              <Note note={note} key={note.id} />
            ))}
            {hasNextPage && (
              <ButtonWithoutBorder onClick={() => fetchNextPage()} disabled={isFetching}>
                <>
                  Show more{' '}
                  <ChevronRight
                    css={css`
                      transform: rotate(90deg);
                    `}
                  />
                </>
              </ButtonWithoutBorder>
            )}
          </FlexBox>
        )}
      </VerticalTabContent>
    </>
  );
};
export default Notes;
