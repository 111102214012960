import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { ILead } from '../../../interfaces/ILead';
import { PolicyType } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';

interface CrossSellLeadArgs {
  leadGid: string;
  opportunitiesIds: string[];
  policyTypes: PolicyType[];
}

interface IResult {
  lead: ILead;
}

const crossSellLead = ({ leadGid, opportunitiesIds, policyTypes }: CrossSellLeadArgs): Promise<IResult> => {
  return api.post(`/api/frontend/leads/${leadGid}/cross_sell`, {
    body: { opportunities_ids: opportunitiesIds, policy_types: policyTypes }
  });
};

const useCrossSellLead = (
  options?: Omit<UseMutationOptions<IResult, unknown, CrossSellLeadArgs, unknown>, 'mutationFn'>
) => useMutation({ mutationFn: crossSellLead, ...options });

export default useCrossSellLead;
