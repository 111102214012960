/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useSuspenseScoutingStrategy } from '../../../../queries/scouting_strategies/useScoutingStrategy';

const WhiteLabelWarning = () => {
  const { callLogId, personGid, leadGid, candidateGid } = useGuidedSellingExperienceContext();

  const { data: scoutingStrategy } = useSuspenseScoutingStrategy({
    callLogId,
    personGid,
    leadGid,
    candidateGid,
    scoutingStrategyConfigKey: 'lead_summary_whitelabel_warning'
  });

  const firstStep = scoutingStrategy?.steps?.[0];

  if (!firstStep) {
    return null;
  }

  return (
    <SystemMessage
      type="warning"
      heading={firstStep.description}
      customCss={css`
        width: 400px;
      `}
    />
  );
};

export default WhiteLabelWarning;
