import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Container from '../../../components/core/Container';
import { isLeadDataEditingForbidden } from '../../../components/DispositionsModals/dispositionsHelper';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import {
  DataCollectionStepsKey,
  IDataCollection,
  IDataCollectionOpportunity,
  IDataCollectionPage,
  IDataCollectionPageType
} from '../../../interfaces/IDataCollection';
import { InsuranceCategory } from '../../../interfaces/IPolicyType';
import { useUpdateDataCollection } from '../../../queries/leads/data_collection/useDataCollection';
import analytics from '../../../services/analytics';
import { isISR, isSuperISR } from '../../../services/authInfo';
// eslint-disable-next-line max-len
import { useCurrentDispositionSubscriptionContext } from '../_hooks/useCurrentDispositionSubscription';
import useDataEditingForbidden from '../_hooks/useDataEditingForbidden';
import { GuidedSellingPathnames } from '../navigation';
import { DataCollectionStepper } from './_hooks/useDataCollectionStepper';
import useSuperISRQuoting from './_hooks/useSuperISRQuoting';
import CustomerProfilePage from './CustomerProfilePage';
import HomeEligibilityQuestionsPage from './HomeEligibilityQuestionsPage';
import HomeProfilePage from './HomeProfilePage';
import LifeProfilePage from './LifeProfilePage';
import NeedsAssessmentPage from './NeedsAssessmentPage';
import PropertyInformationPage from './PropertyInformationPage';
import QuotingConfigurationPage from './QuotingConfigurationPage';
import LifeQuotingConfigurationPage from './QuotingConfigurationPage/LifeQuotingConfigurationPage';
import VehicleProfilePage from './VehicleProfilePage';

export type StepContentProps = {
  onSubmit: () => Promise<void> | undefined;
  onMoveToOpportunity: (opportunity: IDataCollectionOpportunity) => void;
  dataCollection: IDataCollection;
  page: IDataCollectionPage;
  isDataEditingForbidden: boolean;
  onLeadClosed: () => void;
};

const pickContentProvider = (pageType: IDataCollectionPageType | undefined, insuranceCategory: InsuranceCategory) => {
  switch (pageType) {
    case IDataCollectionPageType.CustomerProfile:
      return CustomerProfilePage;
    case IDataCollectionPageType.NeedsAssessment:
      return NeedsAssessmentPage;
    case IDataCollectionPageType.HomeProfile:
      return HomeProfilePage;
    case IDataCollectionPageType.HomeEligibility:
      return HomeEligibilityQuestionsPage;
    case IDataCollectionPageType.HomeDetails:
      return PropertyInformationPage;
    case IDataCollectionPageType.VehicleProfile:
      return VehicleProfilePage;
    case IDataCollectionPageType.LifeProfile:
      return LifeProfilePage;
    case IDataCollectionPageType.QuotingConfiguration:
      return insuranceCategory === InsuranceCategory.PersonalLines
        ? QuotingConfigurationPage
        : LifeQuotingConfigurationPage;
    default:
      return null;
  }
};

const GuidedDataCollection = ({
  onDataCollectionCompleted,
  dataCollection,
  dataCollectionStepper
}: {
  onDataCollectionCompleted: () => void;
  dataCollection: IDataCollection;
  dataCollectionStepper: DataCollectionStepper;
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { lead, personGid } = useGuidedSellingExperienceContext();

  const { currentDisposition } = useCurrentDispositionSubscriptionContext();

  const { mutateAsync: updateDataCollection } = useUpdateDataCollection();

  const { steps, isLastStep, moveForward, moveToOpportunity } = dataCollectionStepper;

  const currentStep = steps.find(step => step.active);

  const isLastStepRef = React.useRef(isLastStep);
  isLastStepRef.current = isLastStep;

  const isDataEditingForbidden = useDataEditingForbidden();

  const startSuperISRQuoting = useSuperISRQuoting();

  if (!lead || !currentDisposition || !personGid) {
    return null;
  }

  const isDataCollectionBlocked =
    isLeadDataEditingForbidden(currentDisposition.disposition_type) || isDataEditingForbidden;

  const onStepSubmit = async () => {
    analytics.track('Data collection step submitted', {
      person_gid: personGid,
      lead_gid: lead.gid,
      step_key: currentStep?.key,
      opportunities_count: currentStep?.opportunities?.length ?? null,
      disabled_opportunities_count:
        currentStep?.opportunities?.filter(o => !o.is_data_collection_enabled).length ?? null
    });

    await updateDataCollection({
      leadId: lead!.id,
      last_completed_step: currentStep?.key
    });

    if (isSuperISR() && currentStep?.key === DataCollectionStepsKey.VehicleProfile) {
      await startSuperISRQuoting();
    }

    isLastStepRef.current ? onDataCollectionCompleted() : moveForward();
  };

  const onLeadClosed = () => {
    if (isISR()) {
      navigate(`${GuidedSellingPathnames.ISRFinish}${search}`);
    }
  };

  const StepContentProvider = pickContentProvider(currentStep?.page_type, lead.insurance_category);

  return (
    <Container fitParentHeight>
      {StepContentProvider && currentStep && (
        <StepContentProvider
          onSubmit={onStepSubmit}
          onMoveToOpportunity={moveToOpportunity}
          dataCollection={dataCollection}
          page={currentStep}
          isDataEditingForbidden={isDataCollectionBlocked}
          onLeadClosed={onLeadClosed}
        />
      )}
    </Container>
  );
};

export default GuidedDataCollection;
