import { skipToken, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { IZendeskUser } from '../../interfaces';
import api from '../../services/api';

const useZendeskUser = ({ userId }: { userId: string | undefined }) =>
  useQuery({
    queryKey: ['person_timezone', userId],
    queryFn: userId
      ? (): Promise<{ zendesk_user: IZendeskUser | null }> => api.get(`/api/frontend/zendesk_users/${userId}`)
      : skipToken,
    select: data => data.zendesk_user,
    staleTime: Infinity,
    throwOnError: error => {
      if ((error as AxiosError).response?.status === 404) {
        return false;
      }

      return true;
    }
  });

export default useZendeskUser;
