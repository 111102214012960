/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import NewTooltip from '../../../../../components/common/Tooltip/NewTooltip';
import SystemMessage from '../../../../../components/core/Alert/SystemMessage';
import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { ChevronRight } from '../../../../../components/core/icons';
import Select from '../../../../../components/core/inputs/Select';
import Label from '../../../../../components/core/Label';
import Text from '../../../../../components/core/Text';
import useToggle from '../../../../../hooks/useToggle';
import { IPerson, IVehicle } from '../../../../../interfaces';
import { AutoCoverageOption, mainAutoCoverageOptionDefinitions } from '../../../../../interfaces/IAutoCoveragesOptions';
import {
  opportunityCoveragesForAsset,
  OpportunityCoveragesTier,
  VehicleOpportunity
} from '../../../../../interfaces/IOpportunity';
import useAutoCoveragesOptions from '../../../../../queries/auto_coverages/useAutoCoveragesOptions';
import analytics from '../../../../../services/analytics';
import colors from '../../../../../theme/colors';
import { sizes, spacings } from '../../../../../theme/variables';
import {
  findCoverageOptionsForTier,
  uiDataForCoverageOption,
  vehicleCoverageTierOptions
} from '../../../../../utils/coveragesFormatter';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import {
  IQuotingConfigurationFormValues,
  IQuotingConfigurationOpportunity
} from '../_interfaces/IQuotingConfigurationOpportunity';
import AutoCoveragesEditor from './AutoCoveragesEditor';

const LEFT_COLUMN_SIZE = sizes.mediumLabelSize;
const GAP_SIZE = spacings.px12;
const SELECT_PADDINGS = 11;
const CHEVRON_SIZE = 20;

const AutoCoverage = ({
  person,
  vehicle,
  opportunity,
  setVehiclesWithoutCoverages
}: {
  person: IPerson;
  vehicle: IVehicle;
  opportunity: IQuotingConfigurationOpportunity;
  setVehiclesWithoutCoverages: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { gid: vehicleGid, address, year, ownership } = vehicle;

  const [editorOpened, setEditorOpened] = React.useState(false);
  const [isExpanded, toggleExpanded] = useToggle(false);

  const { values, setFieldValue } = useFormikContext<IQuotingConfigurationFormValues>();

  const customerChoiceCoverageTier = opportunity.customerChoice?.attributes?.coverages_tier;
  const agentSelectedCoverages = values.opportunities[opportunity.id]!
    .agent_selected_coverages as VehicleOpportunity['agent_selected_coverages'];
  const selectedVehicleCoverages = opportunityCoveragesForAsset(
    { agent_selected_coverages: agentSelectedCoverages },
    vehicle.gid
  )!;
  const selectedVehicleCoveragesTier = selectedVehicleCoverages.coverages_tier!;

  const { data: coveragesOptions, isFetching: isFetchingOptions } = useAutoCoveragesOptions({
    state: address.state,
    year,
    ownership
  });

  React.useEffect(() => {
    if (coveragesOptions && coveragesOptions.length === 0) {
      setVehiclesWithoutCoverages(current => [...current, vehicle.gid]);
    }
  }, [setVehiclesWithoutCoverages, coveragesOptions, vehicle.gid]);

  const coverageOptionsForTier = findCoverageOptionsForTier({
    coveragesOptions: coveragesOptions || [],
    tier: selectedVehicleCoveragesTier,
    selectedVehicleCoverages
  });

  const mainCoverages = Object.keys(mainAutoCoverageOptionDefinitions)
    .map(key => coverageOptionsForTier.find(option => option!.key === key))
    .filter(Boolean) as AutoCoverageOption[];
  const additionalCoverages = coverageOptionsForTier.filter(
    option => !Object.keys(mainAutoCoverageOptionDefinitions).includes(option!.key)
  ) as AutoCoverageOption[];

  const openedCoveragesLeftoverSpace = mainCoverages.length % 5;
  const openedCoverages =
    openedCoveragesLeftoverSpace === 0
      ? mainCoverages
      : [...mainCoverages, ...additionalCoverages.slice(0, openedCoveragesLeftoverSpace)];
  const closedCoverages = additionalCoverages.filter(
    coverage => !openedCoverages.some(openedCoverage => openedCoverage.key === coverage.key)
  );

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container ml={spacings['-px4']} pv={spacings.px4}>
      <FlexBox alignItemsCenter gap={GAP_SIZE}>
        <Label
          htmlFor={`${opportunity.id}-${vehicleGid}-coverage-level`}
          pl={spacings.px4}
          customCss={css`
            width: ${LEFT_COLUMN_SIZE}px;
          `}
        >
          Level of protection
        </Label>
        <Select
          inline
          inputId={`${opportunity.id}-${vehicleGid}-coverage-level`}
          name={`${opportunity.id}-${vehicleGid}-coverage-level`}
          value={selectedVehicleCoveragesTier}
          isLoading={isFetchingOptions}
          options={vehicleCoverageTierOptions({ coveragesOptions: coveragesOptions || [], customerChoiceCoverageTier })}
          onChange={({ target }) => {
            if (target.value === OpportunityCoveragesTier.Mixed) {
              setEditorOpened(true);
            } else {
              const updatedAgentSelectedCoverages = (agentSelectedCoverages?.map(vehicleCoverages =>
                vehicleCoverages.asset_gid === vehicle.gid
                  ? { asset_gid: vehicle.gid, coverages_tier: target.value, coverages: null }
                  : vehicleCoverages
              ) || null) satisfies ConfigurationComponentProps['opportunity']['agent_selected_coverages'];

              setFieldValue(`opportunities.${opportunity.id}.agent_selected_coverages`, updatedAgentSelectedCoverages);

              analytics.track('Vehicle coverages updated', {
                person_gid: person.gid,
                place: 'guided_selling_experience',
                page: 'quoting_configuration'
              });
            }
          }}
        />
      </FlexBox>
      {selectedVehicleCoveragesTier === customerChoiceCoverageTier && (
        <FlexBox mt={spacings.px12}>
          <div
            css={css`
              min-width: ${LEFT_COLUMN_SIZE + GAP_SIZE}px;
            `}
          />

          <SystemMessage type="info" heading="Aligned with the customer's choice online" />
        </FlexBox>
      )}
      {!isFetchingOptions && (
        <FlexBox
          mt={spacings.px16}
          customCss={css`
            flex: 1;
          `}
        >
          <div
            css={css`
              min-width: ${LEFT_COLUMN_SIZE +
              GAP_SIZE +
              SELECT_PADDINGS -
              (closedCoverages.length ? CHEVRON_SIZE : 0)}px;
            `}
          />

          {closedCoverages.length ? (
            <div>
              <ChevronRight
                color={colors.grey60}
                onClick={toggleExpanded}
                height={CHEVRON_SIZE}
                width={CHEVRON_SIZE}
                css={css`
                  transform: rotate(${isExpanded ? '90deg' : '0deg'});
                  transition: transform 0.2s;
                  cursor: pointer;
                  padding-right: 4px;
                `}
              />
            </div>
          ) : null}

          <FlexBox
            customCss={css`
              flex: 1;
            `}
          >
            <Grid container columnSpacing={1} rowSpacing={1} columns={5} minWidth={440}>
              {openedCoverages.map(coverageOption => {
                const { key, coverageLabel, limitsLabel } = uiDataForCoverageOption(coverageOption) || {};

                return (
                  <Grid item xs={1} key={key}>
                    <FlexBox gap={spacings.px4} columnDirection>
                      <Text type="small" color={colors.grey60}>
                        {coverageLabel}
                      </Text>
                      <span
                        data-for="coverage-label-tooltip"
                        data-tip={limitsLabel}
                        css={css`
                          display: -webkit-box;
                          -webkit-line-clamp: 3;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          max-width: fit-content;
                        `}
                      >
                        <Text type="small">{limitsLabel}</Text>
                      </span>
                    </FlexBox>
                  </Grid>
                );
              })}
              {isExpanded &&
                closedCoverages.map(coverageOption => {
                  const { key, coverageLabel, limitsLabel } = uiDataForCoverageOption(coverageOption);

                  return (
                    <Grid item xs={1} key={key}>
                      <FlexBox gap={spacings.px4} columnDirection>
                        <span
                          data-for="coverage-label-tooltip"
                          data-tip={coverageLabel}
                          css={css`
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                          `}
                        >
                          <Text type="small" color={colors.grey60}>
                            {coverageLabel}
                          </Text>
                        </span>
                        <span
                          data-for="coverage-label-tooltip"
                          data-tip={limitsLabel}
                          css={css`
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            max-width: fit-content;
                          `}
                        >
                          <Text type="small">{limitsLabel}</Text>
                        </span>
                      </FlexBox>
                    </Grid>
                  );
                })}
            </Grid>
            <IconButton
              onClick={() => setEditorOpened(true)}
              data-testid="edit-vehicle-coverage"
              focus={editorOpened}
              icon={ButtonIcons.Edit}
              customCss={css`
                width: ${spacings.px32}px;
                height: ${spacings.px32}px;
              `}
            />
          </FlexBox>
          <NewTooltip id="coverage-label-tooltip" />
          {editorOpened && (
            <AutoCoveragesEditor
              vehicle={vehicle}
              onCancel={() => setEditorOpened(false)}
              coveragesOptions={coveragesOptions || []}
              onSetCoverages={coverages => {
                const updatedAgentSelectedCoverages = (agentSelectedCoverages?.map(vehicleCoverages =>
                  vehicleCoverages.asset_gid === vehicle.gid
                    ? { asset_gid: vehicle.gid, coverages_tier: OpportunityCoveragesTier.Mixed, coverages }
                    : vehicleCoverages
                ) || null) satisfies ConfigurationComponentProps['opportunity']['agent_selected_coverages'];

                setFieldValue(
                  `opportunities.${opportunity.id}.agent_selected_coverages`,
                  updatedAgentSelectedCoverages
                );

                analytics.track('Vehicle coverages updated', {
                  person_gid: person.gid,
                  place: 'guided_selling_experience',
                  page: 'quoting_configuration'
                });

                setEditorOpened(false);
              }}
            />
          )}
        </FlexBox>
      )}
    </Container>
  );
};

export default AutoCoverage;
