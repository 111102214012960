import * as React from 'react';
import { useMatch } from 'react-router-dom';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import { isEndDisposition } from '../../../../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../../../../constants/featureFlags';
import { isAgent } from '../../../../../services/authInfo';
// eslint-disable-next-line max-len
import { useCurrentDispositionSubscriptionContext } from '../../../_hooks/useCurrentDispositionSubscription';
import useDataEditingForbidden from '../../../_hooks/useDataEditingForbidden';
import { GuidedSellingPathnames } from '../../../navigation';

const useQuotingAvailability = () => {
  const isOnOldQuotesUI = !useMatch(`${GuidedSellingPathnames.ScoutingReport}/*`);
  const oldQuotesUIRequestQuotesDisabled = isOnOldQuotesUI && featureFlags.disableOldQuotesGetQuotes;

  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const isDataEditingForbidden = useDataEditingForbidden();
  const isTerminalDisposition = isEndDisposition(currentDisposition?.disposition_type);
  const forbidQuoting =
    (isAgent() && isTerminalDisposition) || isDataEditingForbidden || oldQuotesUIRequestQuotesDisabled;

  const Tip = React.useCallback(() => <Tooltip id="disabled-quotes-tip" />, []);
  const labelProps = {
    'data-for': 'disabled-quotes-tip',

    'data-tip':
      (isDataEditingForbidden && 'Quoting is disabled') ||
      (oldQuotesUIRequestQuotesDisabled && 'Quoting is disabled on old quotes UI') ||
      (forbidQuoting && 'Quoting is disabled due to lead being in closed disposition') ||
      ''
  };

  return { forbidQuoting, Tip, labelProps };
};

export default useQuotingAvailability;
