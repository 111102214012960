import { FormikValues } from 'formik';
import * as React from 'react';

import DatapointKey, { PROPERTY_TYPE_APARTMENT, PROPERTY_TYPE_CONDO } from '../../../../interfaces/IDatapoint';

export const isPropertyRCEQuestionsRequired = (values: FormikValues) =>
  ![PROPERTY_TYPE_CONDO, PROPERTY_TYPE_APARTMENT].includes(values[DatapointKey.PropertyType]);

export const SyncRefAndValues = ({
  intermediateValuesRef,
  values
}: {
  intermediateValuesRef: React.MutableRefObject<any>;
  values: any;
}) => {
  React.useEffect(() => {
    intermediateValuesRef.current = values;
  });

  return null;
};
