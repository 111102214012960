/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import * as React from 'react';
import { Modal as ReactStrapModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalProps as ReactStrapModalProps } from 'reactstrap/es/Modal';

import colors from '../../theme/colors';
import Button, { ButtonVariant } from './buttons/Button';
import { CloseIcon } from './icons';
import { ModalSize } from './Modal';
import Text from './Text';

export interface FormModalProps<T> extends ReactStrapModalProps {
  size?: ModalSize;
  title?: string;
  customHeader?: JSX.Element;
  confirmText?: string;
  cancelText?: string;
  confirmHandler: (values: T) => void;
  cancelHandler?: () => void;
  showCancelButton?: boolean;
  confirmationInProgress?: boolean;
  initialValues: T;
  validationSchema?: any;
  renderForm: (props: FormikProps<T>) => JSX.Element;
  dangerModal?: boolean;
  cancelTestId?: string;
  submitTestId?: string;
}

const CustomClose = ({ onClick }: { onClick?: () => void }) => (
  <Button
    onClick={onClick}
    aria-label="Close"
    variant={ButtonVariant.Text}
    customCss={css`
      color: ${colors.black};
    `}
  >
    <CloseIcon />
  </Button>
);

const FormModal = <T extends FormikValues>({
  size = ModalSize.medium,
  title,
  confirmText = 'OK',
  cancelText = 'Cancel',
  confirmHandler,
  cancelHandler,
  showCancelButton = true,
  confirmationInProgress = false,
  initialValues,
  validationSchema,
  renderForm,
  dangerModal,
  customHeader,
  cancelTestId,
  submitTestId,
  ...props
}: FormModalProps<T>): JSX.Element => {
  const cancelClick = confirmationInProgress ? () => {} : cancelHandler;
  const confirmClick = confirmationInProgress ? () => {} : confirmHandler;

  return (
    <ReactStrapModal {...props} isOpen toggle={cancelClick} size={size} backdrop="static">
      {(title || customHeader) && (
        <ModalHeader close={<CustomClose onClick={cancelClick} />}>
          {title && (
            <Text bold type="large">
              {title}
            </Text>
          )}
          {customHeader}
        </ModalHeader>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => confirmClick && confirmClick(values)}
      >
        {formProps => {
          return (
            <Form autoComplete="off">
              <ModalBody>{renderForm(formProps)}</ModalBody>
              <ModalFooter>
                {showCancelButton && cancelHandler && (
                  <Button data-testid={cancelTestId} variant={ButtonVariant.Secondary} onClick={cancelClick}>
                    {cancelText}
                  </Button>
                )}
                <Button
                  data-testid={submitTestId}
                  type="submit"
                  loading={confirmationInProgress || formProps.isSubmitting}
                  variant={dangerModal ? ButtonVariant.Danger : ButtonVariant.Default}
                >
                  {confirmText}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </ReactStrapModal>
  );
};

export default FormModal;
