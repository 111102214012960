/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FieldArray } from 'formik';
import * as React from 'react';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import FlexBox from '../../components/core/FlexBox';
import { InputField, MultiSelectField, NumericField, SelectField } from '../../components/core/forms/fields';
import Modal, { ModalSize } from '../../components/core/Modal';
import { IConditionGroup, Operations } from '../../interfaces/IScoutingStrategyConfig';
import { useScoutingStrategyConfigsVariables } from '../../queries/scouting_strategies/useScoutingStrategyConfigs';
import { LabelSize, spacings } from '../../theme/variables';
import { capitalize } from '../../utils/formatter';

const ValueInputForOperation = ({
  operation,
  id,
  name,
  required,
  possibleValuesForSource
}: {
  operation: Operations | '';
  id: string;
  name: string;
  required: boolean;
  possibleValuesForSource: string[] | undefined | null;
}) => {
  const props = { id, name, labelSize: LabelSize.Small, inline: true, label: 'Value', required };

  switch (operation) {
    case Operations.EQUAL:
    case Operations.NOT_EQUAL:
      return possibleValuesForSource ? (
        <SelectField {...props} options={possibleValuesForSource.map(v => ({ label: capitalize(v), key: v }))} />
      ) : (
        <InputField {...props} />
      );
    case Operations.MATCH:
    case Operations.NOT_MATCH:
      return <InputField {...props} />;
    case Operations.FILLED:
    case Operations.NOT_FILLED:
      return <InputField {...props} placeholder='Type "yes"' />;
    case Operations.LESS_THAN:
    case Operations.GREATER_THAN:
    case Operations.BEFORE:
    case Operations.AFTER:
    case Operations.YEARS_NEWER_THAN:
    case Operations.YEARS_OLDER_THAN:
      return <NumericField {...props} />;
    case Operations.ONE_OF:
    case Operations.NOT_ONE_OF:
      return possibleValuesForSource ? (
        <MultiSelectField {...props} options={possibleValuesForSource.map(v => ({ label: capitalize(v), key: v }))} />
      ) : (
        <InputField {...props} placeholder="Provide comma separated list" />
      );
    default:
      return <InputField {...props} />;
  }
};

const ConditionGroupsModal = ({
  values,
  toggleConditionsModal,
  fieldPrefix
}: {
  values: { condition_groups: IConditionGroup[] };
  toggleConditionsModal: () => void;
  fieldPrefix: string;
}) => {
  const { data: variables } = useScoutingStrategyConfigsVariables();

  const sourceValues = React.useMemo(
    () => Array.from(variables?.values() || []).map(variable => ({ value: variable.label, key: variable.key })),
    [variables]
  );

  if (!variables) {
    return null;
  }

  return (
    <Modal
      title="Conditions"
      size={ModalSize.extra}
      cancelHandler={() => toggleConditionsModal()}
      confirmHandler={() => toggleConditionsModal()}
    >
      <FieldArray
        name={fieldPrefix}
        render={arrayHelpers => {
          return (
            <>
              <Button
                variant={ButtonVariant.Secondary}
                size={ButtonSize.Small}
                onClick={() =>
                  arrayHelpers.push({
                    conditions: [{ source: '', value: '', operation: '' }]
                  } as IConditionGroup)
                }
              >
                Add condition group
              </Button>
              {(values.condition_groups || []).map((conditionGroup, groupIndex) => (
                <FlexBox
                  // eslint-disable-next-line react/no-array-index-key
                  key={`condition-group-${groupIndex}`}
                  customCss={css`
                    border: dashed 1px black;
                  `}
                  columnDirection
                  mt={spacings.px12}
                >
                  <FlexBox
                    gap={spacings.px12}
                    p={spacings.px8}
                    mt={spacings.px12}
                    customCss={css`
                      flex-wrap: wrap;
                    `}
                  >
                    {conditionGroup.conditions.map((condition, index) => {
                      const operationsForSource =
                        variables.get(condition.source)?.operations.map(op => ({ value: capitalize(op), key: op })) ||
                        [];

                      const possibleValuesForSource = variables.get(condition.source)?.values;

                      return (
                        <FlexBox
                          // eslint-disable-next-line react/no-array-index-key
                          key={`condition-${index}`}
                          justifySpaceBetween
                          customCss={css`
                            border: dashed 1px black;
                            flex: 0 0 49%;
                          `}
                        >
                          <FlexBox
                            gap={spacings.px12}
                            columnDirection
                            customCss={css`
                              flex: 1;
                            `}
                          >
                            <SelectField
                              inline
                              required
                              labelSize={LabelSize.Small}
                              label="Source"
                              options={sourceValues}
                              id={`${fieldPrefix}.${groupIndex}.conditions.${index}.source`}
                              name={`${fieldPrefix}.${groupIndex}.conditions.${index}.source`}
                            />
                            <SelectField
                              labelSize={LabelSize.Small}
                              inline
                              required
                              options={operationsForSource}
                              label="Operation"
                              id={`${fieldPrefix}.${groupIndex}.conditions.${index}.operation`}
                              name={`${fieldPrefix}.${groupIndex}.conditions.${index}.operation`}
                            />
                            <ValueInputForOperation
                              required
                              operation={condition.operation}
                              id={`${fieldPrefix}.${groupIndex}.conditions.${index}.value`}
                              name={`${fieldPrefix}.${groupIndex}.conditions.${index}.value`}
                              possibleValuesForSource={possibleValuesForSource}
                            />
                          </FlexBox>
                          <div>
                            <IconButton
                              icon={ButtonIcons.Delete}
                              onClick={() => {
                                if (conditionGroup.conditions.length === 1) {
                                  arrayHelpers.remove(groupIndex);
                                } else {
                                  arrayHelpers.replace(groupIndex, {
                                    conditions: values.condition_groups[groupIndex]?.conditions.filter(
                                      (_i, deletionIndex) => deletionIndex !== index
                                    )
                                  });
                                }
                              }}
                            />
                          </div>
                        </FlexBox>
                      );
                    })}
                  </FlexBox>
                  <FlexBox p={spacings.px12} gap={spacings.px12}>
                    <Button
                      size={ButtonSize.Small}
                      variant={ButtonVariant.Secondary}
                      onClick={() =>
                        arrayHelpers.replace(groupIndex, {
                          conditions: values.condition_groups[groupIndex]?.conditions.concat({
                            source: '' as any,
                            value: '',
                            operation: ''
                          })
                        } as IConditionGroup)
                      }
                    >
                      Add condition
                    </Button>
                    <Button
                      size={ButtonSize.Small}
                      variant={ButtonVariant.Danger}
                      onClick={() => arrayHelpers.remove(groupIndex)}
                    >
                      Remove group
                    </Button>
                  </FlexBox>
                </FlexBox>
              ))}
            </>
          );
        }}
      />
    </Modal>
  );
};

export default ConditionGroupsModal;
