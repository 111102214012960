import queryString from 'query-string';

export const applyUtmParamsToQuote = ({
  originalUrl,
  personGid,
  leadGid,
  leadId,
  assetGids,
  policyType
}: {
  originalUrl: string | null;
  personGid: string;
  leadGid: string | undefined;
  leadId: number | undefined;
  assetGids: string[] | null;
  policyType: string;
}) => {
  if (!originalUrl) {
    return originalUrl;
  }

  const cleanUrl = queryString.exclude(originalUrl, ['person_gid', 'lead_gid', 'lead_id', 'asset_gids'], {
    arrayFormat: 'bracket',
    skipNull: true
  });

  return queryString.stringifyUrl(
    {
      url: cleanUrl,
      query: {
        person_gid: personGid,
        lead_gid: leadGid,
        lead_id: leadId,
        asset_gids: assetGids,
        policy_type: policyType
      }
    },
    {
      arrayFormat: 'bracket',
      skipNull: true
    }
  );
};
