/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../../components/core/Container';
import { ChevronRight } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import { Configuration } from '../../../../constants';
import { IPerson } from '../../../../interfaces/IPerson';
import { IEmailInteraction, InteractionDirection } from '../../../../interfaces/IPersonInteraction';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { capitalize } from '../../../../utils/formatter';
import parseHtmlString from '../../../../utils/htmlStringParser';
import { displayedDateAndTime } from './helpers';
import InteractionDetailsLayout, { CommunicatorLayout } from './InteractionDetailsLayout';

const maxDisplayedEmailContentHeight = 274;

const EmailDetails = ({
  interaction,
  interactionPerson,
  relatedPersonKind,
  userRole
}: {
  interaction: IEmailInteraction;
  interactionPerson: IPerson | undefined;
  userRole: string;
  relatedPersonKind?: string;
}): JSX.Element => {
  const [expandEmail, setExpandEmail] = React.useState(false);
  const [emailContentHeight, setEmailContentHeight] = React.useState(0);

  const emailContentRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setEmailContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const personFullName = interactionPerson
    ? `${interactionPerson.first_name} ${interactionPerson.last_name}`
    : 'Unknown';
  const userFullName = interaction.processed_by
    ? `${interaction.processed_by.first_name} ${interaction.processed_by.last_name}`
    : 'Unknown';
  let email = document.createElement('textarea') as HTMLTextAreaElement | null;
  email!.innerHTML = interaction.body;
  const emailContent = email!.value;
  email = null;

  const proposalEmail = parseHtmlString(
    emailContent
      .replace(/<p><br><\/p>/g, '')
      .replace(new RegExp(`(${Configuration.customerPortalPublicUrl}/proposal/[\\w-]+)`, 'g'), '$1?referrer=amp') || '',
    e => {
      // eslint-disable-next-line no-console
      console.error(e);
    },
    true
  );

  return (
    <>
      <InteractionDetailsLayout
        expandBody={expandEmail}
        header={
          <>
            <Paragraph type="small">{`${capitalize(interaction.direction)} ${interaction.type}`}</Paragraph>
            <Paragraph type="small">{displayedDateAndTime(moment(interaction.created_at))}</Paragraph>
          </>
        }
        sender={
          <CommunicatorLayout
            name={interaction.direction === InteractionDirection.Inbound ? personFullName : userFullName}
            tagLabel={interaction.direction === InteractionDirection.Inbound ? relatedPersonKind : userRole}
            info={
              interaction.direction === InteractionDirection.Inbound
                ? interaction.person_email
                : interaction.matic_email
            }
          />
        }
        receiver={
          <CommunicatorLayout
            name={interaction.direction === InteractionDirection.Inbound ? userFullName : personFullName}
            tagLabel={interaction.direction === InteractionDirection.Inbound ? userRole : relatedPersonKind}
            info={
              interaction.direction === InteractionDirection.Inbound
                ? interaction.matic_email
                : interaction.person_email
            }
          />
        }
        body={
          <>
            <div ref={emailContentRef}>
              <Paragraph bold>{interaction.subject}</Paragraph>
              <Paragraph
                customCss={css`
                  white-space: pre-wrap;
                  overflow: scroll;
                `}
              >
                {interaction.sent_by_proposal_builder ? proposalEmail : emailContent}
              </Paragraph>
            </div>

            {emailContentHeight > maxDisplayedEmailContentHeight && (
              <Container
                mv={spacings.px24}
                backgroundColor={colors.white}
                customCss={css`
                  position: sticky;
                  bottom: 0;
                  white-space: pre-wrap;
                  ${expandEmail && 'display:none;'}
                  box-shadow: 0px 0px 40px 40px ${colors.white};
                `}
              >
                <ButtonWithoutBorder
                  onClick={() => setExpandEmail(true)}
                  pl={spacings.px0}
                  customCss={css`
                    width: 100%;
                    text-align: left;
                  `}
                >
                  <>
                    Show more{' '}
                    <ChevronRight
                      css={css`
                        transform: rotate(90deg);
                      `}
                    />
                  </>
                </ButtonWithoutBorder>
              </Container>
            )}
          </>
        }
      />
    </>
  );
};
export default EmailDetails;
