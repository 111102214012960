import { FormikValues } from 'formik';
import moment from 'moment';
import { ObjectShape } from 'yup';

import { IUIFlow, IUIFlowContainer, IUIFlowElement, IUIFlowResponse } from '../../../interfaces/IUIFlow';
import { IAnswer, IQuestion, QuestionType, QuestionVerificationStatus } from '../../../interfaces/IWorkflow';
import { AnswerUpdateRequest } from '../../../queries/workflows/useUiFlow';
import { isQuestionVisible } from './workflowConditions';
import { answerForDatapoint } from './workflowHelpers';
import { initialValueForQuestion } from './workflowInitialValues';
import WorkflowInput from './WorkflowInput';
import { validateFormValues, validationForQuestion } from './workflowValidations';

const findDynamicDefault = (
  answers: IUIFlowResponse['answers'],
  dynamicDefaults: IUIFlowResponse['dynamic_defaults'][string]
) => {
  const valuePresence = dynamicDefaults.map(({ type, value }) => {
    if (['string', 'number'].includes(type)) {
      return value;
    }

    if (type === 'datapoint') {
      return answerForDatapoint(answers, value)?.value;
    }

    return '';
  });

  return valuePresence.find(value => !!value) || '';
};

const applyDefaultAnswers = ({ answers, default_answers, dynamic_defaults }: IUIFlowResponse) => {
  const viableDefaultAnswers = default_answers.filter(({ value, key }) => !!value && !answerForDatapoint(answers, key));
  const viableDynamicAnswers = Object.entries(dynamic_defaults).reduce((acc, [key, dynamicDefaults]) => {
    if (answerForDatapoint(answers, key)?.value) {
      return acc;
    }

    return [...acc, { key, value: findDynamicDefault(answers, dynamicDefaults) }];
  }, [] as any);

  const answerValue = (answer: IAnswer) => {
    if (typeof answer.value === 'number') {
      return answer.value;
    }

    return answer.value ? answer.value : answerForDatapoint(default_answers, answer.key)?.value || null;
  };

  return [
    ...answers.map(answer => ({
      ...answer,
      value: answerValue(answer)
    })),
    ...viableDynamicAnswers,
    ...viableDefaultAnswers
  ];
};

export const isTagPresent = (tags: IUIFlowElement['tags'], tag: string) => {
  return (
    tags === tag ||
    (Array.isArray(tags) && tags.includes(tag)) ||
    (!Array.isArray(tags) && tags?.split(',')?.some(t => t.trim() === tag))
  );
};

export const verificationStatusForAnswer = (answer: IAnswer | undefined) => {
  let verificationStatus;

  if (!answer || !answer.value) {
    verificationStatus = QuestionVerificationStatus.NotProvided;
  } else if (!answer.answered_by || !answer.answered_at) {
    verificationStatus = QuestionVerificationStatus.NeedsVerification;
  } else {
    const answeredAt = moment(answer.answered_at);
    const currentDate = moment(new Date());
    const isAnswerOutdated = currentDate.diff(answeredAt, 'days') > 60;
    verificationStatus = isAnswerOutdated
      ? QuestionVerificationStatus.NeedsVerification
      : QuestionVerificationStatus.Verified;
  }

  return verificationStatus;
};

const questionWithVerificationStatus = ({ content: question, tags }: IUIFlowElement, answers: IAnswer[]) => {
  const existingAnswer = answerForDatapoint(answers, question.key);

  const verificationStatus = verificationStatusForAnswer(existingAnswer);

  let augmentedQuestion = { ...question };

  if (isTagPresent(tags, 'select')) {
    augmentedQuestion = { ...augmentedQuestion, type: QuestionType.Select };
  }
  if (isTagPresent(tags, 'secondary')) {
    augmentedQuestion = { ...augmentedQuestion, secondary: true };
  }

  return { ...augmentedQuestion, verification_status: verificationStatus };
};

const provideElementVerificationStatus = (element: IUIFlowElement, answers: IAnswer[]) => {
  if (element.kind !== 'question') {
    return element;
  }

  return { ...element, content: questionWithVerificationStatus(element, answers) };
};

const provideElementsVerificationStatus = (containers: IUIFlowContainer[], answers: IAnswer[]) => {
  return containers.map(container => {
    const elementsWithVerificationStatus = container.elements.map(element =>
      provideElementVerificationStatus(element, answers)
    );

    return { ...container, elements: elementsWithVerificationStatus };
  });
};

const answersForQuestions = ({
  questions,
  formValues,
  personGid,
  engagementGid,
  assetGid
}: {
  questions: IQuestion[];
  formValues: FormikValues;
  personGid: string;
  engagementGid: string | undefined;
  assetGid: string | undefined;
}) =>
  questions
    .filter(question => isQuestionVisible(question, formValues))
    .map(question => ({
      question_key: question.key,
      person_gid: personGid,
      engagement_gid: engagementGid,
      asset_gid: assetGid,
      value: formValues[question.key]
    }));

type UIFlowReturnType<T> = T extends IUIFlowResponse ? IUIFlow : undefined;

const buildUIFlow = <T extends IUIFlowResponse | undefined>({
  uiFlowResponse
}: {
  uiFlowResponse: T;
}): UIFlowReturnType<T> => {
  if (!uiFlowResponse) {
    return undefined as UIFlowReturnType<T>;
  }

  const { containers: containersResponse } = uiFlowResponse;
  const answers = applyDefaultAnswers(uiFlowResponse);

  const simplifiedAnswers = Object.values(answers).reduce(
    (acc, answer) => ({
      ...acc,
      [answer.key]: answer.value
    }),
    {}
  );

  const containers = provideElementsVerificationStatus(containersResponse, answers);
  const questionsElements = containers
    .flatMap(({ elements }) => elements)
    .filter(({ kind }) => kind === 'question')
    .map(element => element as IUIFlowElement & { content: IQuestion });
  const questions = questionsElements.map(({ content }) => content as IQuestion);

  return {
    answers,
    containers,
    visibleQuestions(): (IUIFlowElement & { content: IQuestion })[] {
      return questionsElements.filter(({ content }) => isQuestionVisible(content, simplifiedAnswers));
    },
    questionForDatapoint(datapointKey: string): (IUIFlowElement & { content: IQuestion }) | undefined {
      return questionsElements.find(({ content }) => content.key === datapointKey);
    },
    initialValues(): Record<string, any> {
      return questions.reduce((acc, question) => {
        return { ...acc, ...{ [question.key]: initialValueForQuestion(question, simplifiedAnswers) } };
      }, {});
    },
    inputForQuestion({
      element,
      formValues,
      propsProvider,
      renderer
    }: {
      element: IUIFlowElement;
      formValues: FormikValues;
      propsProvider?: (question: IQuestion) => any;
      renderer?: (input: JSX.Element, question: IQuestion) => JSX.Element;
    }): JSX.Element | null {
      const formValuesAndShadowAnswers = { ...simplifiedAnswers, ...formValues };

      const question = element.content as IQuestion;

      if (
        !isQuestionVisible(
          {
            visibility_conditions: element.visibility_conditions?.length
              ? element.visibility_conditions
              : question.visibility_conditions
          },
          formValuesAndShadowAnswers
        )
      ) {
        return null;
      }

      const inputProps = {
        fsMask: isTagPresent(element.tags, 'mask'),
        secondary: isTagPresent(element.tags, 'secondary'),
        ...(propsProvider?.(question) || {})
      };
      const input = (
        <WorkflowInput
          key={inputProps.key}
          question={question}
          inputProps={inputProps}
          answers={formValuesAndShadowAnswers}
        />
      );

      return renderer?.(input, question) || input;
    },
    validations(): ObjectShape {
      return questions.reduce((previous, current) => {
        return {
          ...previous,
          ...{ [current.key]: validationForQuestion(current) }
        };
      }, {});
    },
    validateFormValues(formValues: FormikValues): ObjectShape {
      const formValuesAndShadowAnswers = { ...simplifiedAnswers, ...formValues };

      return validateFormValues(questions, formValuesAndShadowAnswers);
    },
    answersForFormValues({
      formValues,
      personGid,
      engagementGid,
      assetGid
    }: {
      formValues: FormikValues;
      personGid: string;
      engagementGid: string | undefined;
      assetGid: string | undefined;
    }): AnswerUpdateRequest[] {
      const formValuesAndShadowAnswers = { ...simplifiedAnswers, ...formValues };

      return answersForQuestions({
        questions,
        formValues: formValuesAndShadowAnswers,
        personGid,
        engagementGid,
        assetGid
      });
    }
  } as UIFlowReturnType<T>;
};

export default buildUIFlow;
