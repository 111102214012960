/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import React from 'react';

import NewTooltip from '../../../../../components/common/Tooltip/NewTooltip';
import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../../components/core/FlexBox';
import { InfoIcon, WarningIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import PriorPolicyEditor from '../../../../../components/PriorPolicyEditor';
import { IOpportunityPolicies } from '../../../../../interfaces';
import { NewPolicyOptions } from '../../../../../interfaces/INewPolicyOptions';
import { IOpportunity } from '../../../../../interfaces/IOpportunity';
import { AgencyName, IPolicy, IPriorPolicy } from '../../../../../interfaces/IPolicy';
import { coverageByKey, PolicyCoverageKey } from '../../../../../interfaces/IPolicyCoverage';
import analytics from '../../../../../services/analytics';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { dateFormatter, DISPLAY_DATE_FORMAT, moneyFormatter } from '../../../../../utils/formatter';
import PriorPolicyAssetsPicker from '../../../../Customer/CustomerPriorPolicies/PriorPolicyAssetsPicker';
import useDataEditingForbidden from '../../../_hooks/useDataEditingForbidden';

const CurrentPolicies = ({
  personGid,
  leadId,
  opportunitiesWithPolicies,
  refetchCurrentPolicies
}: {
  personGid: string;
  leadId: number | undefined;
  opportunitiesWithPolicies: (IOpportunity & IOpportunityPolicies)[] | undefined;
  refetchCurrentPolicies: () => void;
}) => {
  const [newPolicyOptions, setNewPolicyOptions] = React.useState<NewPolicyOptions>();
  const [assetPickerOpened, setAssetPickerOpened] = React.useState(false);

  const [editedPolicy, setEditedPolicy] = React.useState<IPolicy>();
  const [editorOpened, setEditorOpened] = React.useState(false);

  const isDataEditingForbidden = useDataEditingForbidden();

  const hidePriorPolicyEditor = () => {
    setEditedPolicy(undefined);
    setEditorOpened(false);
  };

  const hideAssetPicker = () => {
    setNewPolicyOptions(undefined);
    setAssetPickerOpened(false);
  };

  const opportunitiesWithPresentPolicies = opportunitiesWithPolicies?.filter(({ latestPolicy }) => !!latestPolicy);

  return (
    <FlexBox columnDirection mb={spacings.px24} gap={spacings.px8}>
      <FlexBox alignItemsBaseline gap={spacings.px8}>
        <Paragraph bold>Current insurance</Paragraph>
        <ButtonWithoutBorder
          data-testid="add-current-insurance"
          onClick={() => setAssetPickerOpened(true)}
          disabled={isDataEditingForbidden}
        >
          Add
        </ButtonWithoutBorder>
      </FlexBox>
      {!!opportunitiesWithPresentPolicies?.length && (
        <FlexBox columnDirection gap={spacings.px8}>
          {opportunitiesWithPresentPolicies.map(({ id, title, latestPolicy }) => {
            const { carrier, coverages, expiration_date, premium, lender_placed_insurance_issue_date } = latestPolicy!;
            const dwelling = coverageByKey(coverages, PolicyCoverageKey.Dwelling);
            const jewelry = coverageByKey(coverages, PolicyCoverageKey.Jewelry);

            const placedDate = lender_placed_insurance_issue_date ? moment(lender_placed_insurance_issue_date) : null;
            const duration = placedDate ? moment(new Date()).diff(placedDate, 'days') : null;

            return (
              <FlexBox key={id} columnDirection gap={spacings.px4}>
                <FlexBox justifySpaceBetween alignItemsCenter>
                  <FlexBox gap={spacings.px4}>
                    <Paragraph bold type="small">
                      {title}
                    </Paragraph>
                    {!!placedDate && !!duration && (
                      <span
                        data-for="lender-placed-insurance"
                        data-tip={`Lender placed insurance\neffective date: ${placedDate.format(
                          DISPLAY_DATE_FORMAT
                        )}\nduration: ${duration} days`}
                      >
                        <WarningIcon color={colors.statusOrange} />
                      </span>
                    )}
                  </FlexBox>
                  {latestPolicy?.agency_name !== AgencyName.Matic ? (
                    <IconButton
                      onClick={() => {
                        setEditedPolicy(latestPolicy);
                        setEditorOpened(true);
                      }}
                      focus={editorOpened}
                      icon={ButtonIcons.Edit}
                      data-testid="edit-current-insurance"
                      disabled={isDataEditingForbidden}
                    />
                  ) : (
                    <>
                      <span
                        data-for="policy-sold-by-matic"
                        data-tip="You can't edit this policy because it's a policy with Matic"
                      >
                        <InfoIcon />
                      </span>
                      <NewTooltip id="policy-sold-by-matic" />
                    </>
                  )}
                </FlexBox>
                <FlexBox justifySpaceBetween gap={spacings.px4}>
                  <Text type="small" color={colors.grey60}>
                    Carrier
                  </Text>
                  <Text
                    type="small"
                    customCss={css`
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 160px;
                      text-align: end;
                    `}
                  >
                    {carrier.name}
                  </Text>
                </FlexBox>
                {expiration_date && (
                  <FlexBox justifySpaceBetween>
                    <Text type="small" color={colors.grey60}>
                      Exp date
                    </Text>
                    <Text type="small">{dateFormatter(expiration_date)}</Text>
                  </FlexBox>
                )}
                {premium && (
                  <FlexBox justifySpaceBetween>
                    <Text type="small" color={colors.grey60}>
                      Premium
                    </Text>
                    <Text type="small">{moneyFormatter(premium, true)}</Text>
                  </FlexBox>
                )}
                {dwelling?.limit_value && (
                  <FlexBox justifySpaceBetween>
                    <Text type="small" color={colors.grey60}>
                      Dwelling
                    </Text>
                    <Text type="small">{moneyFormatter(dwelling.limit_value, true)}</Text>
                  </FlexBox>
                )}
                {dwelling?.deductible_value && (
                  <FlexBox justifySpaceBetween>
                    <Text type="small" color={colors.grey60}>
                      Deductible
                    </Text>
                    <Text type="small">{moneyFormatter(dwelling.deductible_value, true)}</Text>
                  </FlexBox>
                )}
                {jewelry?.limit_value && (
                  <FlexBox justifySpaceBetween>
                    <Text type="small" color={colors.grey60}>
                      Coverage
                    </Text>
                    <Text type="small">{moneyFormatter(jewelry?.limit_value, true)}</Text>
                  </FlexBox>
                )}
              </FlexBox>
            );
          })}
        </FlexBox>
      )}
      <NewTooltip id="lender-placed-insurance" />
      {assetPickerOpened && (
        <PriorPolicyAssetsPicker
          leadId={leadId}
          personGid={personGid}
          cancelHandler={hideAssetPicker}
          confirmHandler={options => {
            hideAssetPicker();
            setNewPolicyOptions(options);
            setEditorOpened(true);
          }}
        />
      )}
      {editorOpened && (
        <PriorPolicyEditor
          personGid={personGid}
          policyType={newPolicyOptions?.policyType}
          assets={newPolicyOptions?.assets}
          priorPolicy={editedPolicy as IPriorPolicy}
          cancelHandler={hidePriorPolicyEditor}
          confirmHandler={() => {
            hidePriorPolicyEditor();
            hideAssetPicker();
            analytics.track(`Prior policy ${editedPolicy ? 'updated' : 'added'}`, {
              lead_id: leadId,
              person_gid: personGid,
              place: 'guided_selling_experience',
              page: 'quotes'
            });
            refetchCurrentPolicies();
          }}
        />
      )}
    </FlexBox>
  );
};

export default CurrentPolicies;
