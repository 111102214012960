import { IPerson } from '../../../../../interfaces';
import { InsurableInterest, PolicyType } from '../../../../../interfaces/IPolicyType';
import { IQuotesRequest } from '../../../../../interfaces/IQuotesRequests';
import { dig } from '../../../../../utils/object';
import {
  isOpportunityAssetQuotable,
  isOpportunityDataCollectionCompleted,
  isOpportunityQuotedByCustomer,
  isOpportunityQuotedFromAmp,
  isOpportunityQuotingInProgress
} from '../../../_helpers';
import {
  IQuotingConfigurationFormValues,
  IQuotingConfigurationOpportunity
} from '../_interfaces/IQuotingConfigurationOpportunity';
import { autoOpportunityInitialCoverages } from '../AutoOpportunity';
import { motoOpportunityInitialCoverages } from '../MotoOpportunity';
import { pafOpportunityInitialCoverages } from '../PAFOpportunity';
import { petOpportunityInitialCoverages } from '../PetOpportunity';
import { realPropertyOpportunityInitialCoverages } from '../RealPropertyOpportunity';
import { rentersOpportunityInitialCoverages } from '../RentersOpportunity';
import { rvOpportunityInitialCoverages } from '../RVOpportunity';
import { umbrellaOpportunityInitialCoverages } from '../UmbrellaOpportunity';

export const STUB_PAF_OPPORTUNITY_ID = 'stub_paf_opportunity';
export const STUB_FLOOD_PREFIX = 'stub-flood';
export const PAF_TIP =
  // eslint-disable-next-line max-len
  'Since this customer is married, they may be interested in purchasing a PAF. The average wedding ring costs $5,000, and these high-value items are not typically included in the standard home policy.';
export const PET_TIP = 'Pet opportunity suggested because the customer has a dog or a cat.';
export const FLOOD_TIP = 'Neptune will send quotes if the customer is in an area that is prone to flooding.';

const opportunityCoveragesInitialValues = (opportunity: IQuotingConfigurationOpportunity) => {
  if (opportunity.policy_type === PolicyType.Umbrella) {
    return umbrellaOpportunityInitialCoverages(opportunity);
  }

  if (opportunity.policy_type === PolicyType.PAF) {
    return pafOpportunityInitialCoverages(opportunity);
  }

  if (opportunity.policy_type === PolicyType.Moto) {
    return motoOpportunityInitialCoverages(opportunity);
  }

  if (opportunity.policy_type === PolicyType.RV) {
    return rvOpportunityInitialCoverages(opportunity);
  }

  if (opportunity.policy_type === PolicyType.Pet) {
    return petOpportunityInitialCoverages(opportunity);
  }

  if (opportunity.policy_type === PolicyType.Renters) {
    return rentersOpportunityInitialCoverages(opportunity);
  }

  if (opportunity.insurable_interest === InsurableInterest.RealProperty) {
    return realPropertyOpportunityInitialCoverages(opportunity);
  }

  if (opportunity.insurable_interest === InsurableInterest.Vehicle) {
    return autoOpportunityInitialCoverages(opportunity);
  }
};

export const opportunitiesInitialValues = ({
  person,
  opportunities,
  quotesRequests
}: {
  person: IPerson;
  opportunities: IQuotingConfigurationOpportunity[] | undefined;
  quotesRequests: IQuotesRequest[] | undefined;
}): IQuotingConfigurationFormValues['opportunities'] | undefined =>
  opportunities?.reduce((acc, opportunity) => {
    // Will need to think of a better way to evaluate if opportunity was quoted via OLB or Digital Profile flow.
    return {
      ...acc,
      [opportunity.id.toString()]: {
        enabled:
          isOpportunityDataCollectionCompleted({ opportunity }) &&
          !isOpportunityQuotingInProgress({ opportunity, quotesRequests }) &&
          !isOpportunityQuotedFromAmp({ opportunity, quotesRequests }) &&
          !opportunity.disqualification_reason &&
          !isOpportunityQuotedByCustomer({ opportunity, quotesRequests }),
        insured_gids: [person.gid, ...(opportunity.coinsureds?.map(({ gid }) => gid) || [])],
        primary_insured_gid: person.gid,
        assets_gids: opportunity.assets
          ?.filter(asset => isOpportunityAssetQuotable({ opportunity, asset }))
          ?.map(({ gid }) => gid),
        agent_selected_coverages: opportunityCoveragesInitialValues(opportunity),
        insurable_interest: opportunity.insurable_interest
      }
    };
  }, {});

export const getPAFOpportunityId = (opportunities: IQuotingConfigurationOpportunity[] | undefined) => {
  return opportunities?.find(opportunity => opportunity.policy_type === PolicyType.PAF)?.id.toString();
};

export const getCoverageValue = (coverages: any[], key: string, property = 'limit_value'): string =>
  dig(
    coverages.find(coverage => coverage.key === key),
    property
  ) as string;
