import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { IScoutingStrategy } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const getScoutingStrategyConfigDemo = ({
  configKey,
  variables
}: {
  configKey: string | undefined;
  variables: Record<string, any> | undefined;
}): Promise<{ scouting_strategy_config: IScoutingStrategy }> =>
  api.get(`/api/frontend/scouting_strategy_configs/${configKey}/demo?${toQueryParams(variables)}`);

const useScoutingStrategyConfigDemo = ({
  configKey,
  variables
}: {
  configKey: string | undefined;
  variables: Record<string, any> | undefined;
}) =>
  useQuery({
    queryKey: ['scouting_strategy_config_demo', configKey, variables],
    queryFn: () => getScoutingStrategyConfigDemo({ configKey, variables }),
    enabled: !!configKey,
    select: data => data.scouting_strategy_config,
    placeholderData: keepPreviousData
  });

export default useScoutingStrategyConfigDemo;
