/* eslint-disable max-len */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Blockquote from '../../../../../components/core/Blockquote';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Modal, { ModalSize } from '../../../../../components/core/Modal';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import { spacings } from '../../../../../theme/variables';

const HowToQuotePetModal = ({ cancelHandler }: { cancelHandler: () => void }) => {
  return (
    <Modal
      title="Recommendations how to quote"
      cancelHandler={cancelHandler}
      showCancelButton={false}
      size={ModalSize.extra}
    >
      <FlexBox
        ph={spacings.px24}
        pb={spacings.px12}
        columnDirection
        gap={spacings.px12}
        customCss={css`
          white-space: pre-line;
        `}
      >
        <Container>
          <Paragraph bold>Pitch coverages</Paragraph>
          <Blockquote
            text={`It covers accidents, illnesses, and conditions. Anything except pre-existing conditions. Dental illness, breed-specific or genetic conditions, chronic conditions, cancer, orthopedic conditions, preventable conditions.
  They cover prescription, drug, diagnostic testing, specialist care such as an oncologist, they cover ambulance and surgery, emergency care, complimentary treatment and reabiltation such as occupancture.`}
          />
        </Container>
        <Container>
          <Paragraph bold>Select coverages</Paragraph>
          <Paragraph>
            <Text highlighted>Reimbursement</Text>
            {` is a maximum amount that embrace will reimburce you each year for vet staff or illneses. They can do $5K, $8K, $10k, $15k, $30K.
            The most popular is $10K.
            And the `}
            <Text highlighted>annual deductible</Text>
            {` is the amount you pay yearly before Embrace starts reimurcing you. The most popular is $750, but we have $200, $300, $500, $750 or $1,000.
            The last choice is `}
            <Text highlighted>reimbursing percentage</Text>. It’s amount that Embrace will reimburce you after you’ve
            met your deductible. The most popular is 70%, but we also have 80% and 90% reimbursement.
          </Paragraph>
        </Container>
        <Container>
          <Paragraph bold>Eligibility</Paragraph>
          <Paragraph>Has %pet_name% been to the vet in the last 12 months?</Paragraph>
        </Container>
        <Container>
          <Paragraph bold>Waiting period</Paragraph>
          <Blockquote
            text="Usually pet insurance companies have waiting period. The waiting period is a short period of time at the beginning of the policy when coverage is restricted.
  For accidents it is 48 hours. All dogs have a 6-month waiting period for orthopedic conditions. You can reduce the waiting period for orthopedic conditions to 14 days by following the Orthopedic
  Exam and Waiver Process. Unfortunately, if your pet has a health issue during the waiting period then your claim will not be covered, even if it was an unforeseeable emergency."
          />
        </Container>
      </FlexBox>
    </Modal>
  );
};
export default HowToQuotePetModal;
