import * as React from 'react';

import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import { Qualification } from '../../../../queries/people/usePersonAssetQualification';
import { spacings } from '../../../../theme/variables';

const DisqualificationMessage = ({
  qualification,
  questionKey
}: {
  qualification: Qualification;
  questionKey: string;
}) => {
  const showTip =
    qualification.result === 'disqualified' && qualification.disqualification_datapoints?.includes(questionKey);

  return showTip ? (
    <SystemMessage
      type="error"
      heading="Asset is not eligible and will not be quoted"
      description={qualification.agent_explanation}
      mt={spacings.px8}
    />
  ) : null;
};

export default DisqualificationMessage;
