/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';

import Button, { ButtonVariant } from '../../../../../components/core/buttons/Button';
import FlexBox from '../../../../../components/core/FlexBox';
import { MultiSelectField } from '../../../../../components/core/forms/fields';
import appTranslations from '../../../../../constants/appTranslations';
import { useToggle } from '../../../../../hooks';
import DatapointKey, { DatapointsUsageKey } from '../../../../../interfaces/IDatapoint';
import { IOpportunity } from '../../../../../interfaces/IOpportunity';
import { IPerson } from '../../../../../interfaces/IPerson';
import { useAnswers } from '../../../../../queries/answers/useAnswers';
import usePersonRelatedPeople, { useCreateRelatedPerson } from '../../../../../queries/people/usePersonRelatedPeople';
import analytics from '../../../../../services/analytics';
import { spacings } from '../../../../../theme/variables';
import { LLC_VALUE, llcTrustPair, TRUST_VALUE } from '../../../_helpers';
import RelatedPersonFormModal from '../../_components/RelatedPersonFormModal';
import { IQuotingConfigurationFormValues } from '../_interfaces/IQuotingConfigurationOpportunity';

const RealPropertyInsuredPeopleWithTheDeed = ({
  person,
  opportunity
}: {
  person: IPerson;
  opportunity: IOpportunity;
}) => {
  const { setFieldValue, values: formValues } = useFormikContext<IQuotingConfigurationFormValues>();

  const { mutateAsync: addRelatedPerson, isPending: isAddingRelatedPerson } = useCreateRelatedPerson();
  const [addRelatedPersonModalShown, toggleAddRelatedPersonModal] = useToggle(false);

  const {
    data: relatedPeople = [],
    isPending: isPendingRelatedPeople,
    refetch: refetchRelatedPeople
  } = usePersonRelatedPeople(person.gid);
  const { data: answers = [], isPending: isPendingOnTheDeedList } = useAnswers({
    datapointsUsageKey: DatapointsUsageKey.PropertyOnTheDeed,
    personGid: person.gid,
    assetGid: opportunity.assets?.[0]?.gid || null,
    relatedPersonGid: null
  });

  const personGidsOnTheDeed =
    (answers.find(answer => answer.key === DatapointKey.PropertyOnTheDeed)?.value as string[] | undefined) || [];

  const onTheDeedOptions = personGidsOnTheDeed
    .map(gidOrString => {
      if (gidOrString === person.gid) {
        return {
          key: gidOrString,
          value: person.name,
          description: 'Primary contact, On the deed',
          isDisabled: true
        };
      }

      if ([LLC_VALUE, TRUST_VALUE].includes(gidOrString)) {
        return {
          key: person.gid,
          value: llcTrustPair[gidOrString as typeof LLC_VALUE | typeof TRUST_VALUE],
          description: 'On the deed',
          isDisabled: true
        };
      }

      const relatedPerson = relatedPeople.find(p => p.gid === gidOrString);

      if (!relatedPerson) {
        return null;
      }

      return {
        key: gidOrString,
        value: relatedPerson.name,
        description: `${appTranslations.relationKind(relatedPerson.kind)}, On the deed`,
        isDisabled: true
      };
    })
    .filter(Boolean);

  if (!personGidsOnTheDeed.includes(person.gid)) {
    onTheDeedOptions.unshift({
      key: person.gid,
      value: person.name,
      description: 'Primary contact, On the deed',
      isDisabled: true
    });
  }

  const relatedPeopleOptions = relatedPeople.map(({ name, gid, kind }) => ({
    key: gid,
    value: name,
    description: appTranslations.relationKind(kind),
    isDisabled: false
  }));

  const insuredGidsOptions = onTheDeedOptions.concat(
    relatedPeopleOptions.filter(option => !personGidsOnTheDeed.includes(option.key))
  );

  return (
    <FlexBox columnDirection ml={spacings['-px4']} pv={spacings.px4} gap={spacings.px12}>
      <MultiSelectField
        inline
        fsMask
        label="Insured people"
        key={`opportunities.${opportunity.id}.insured_gids`}
        id={`opportunities.${opportunity.id}.insured_gids`}
        name={`opportunities.${opportunity.id}.insured_gids`}
        options={insuredGidsOptions}
        disabled={isPendingRelatedPeople || isPendingOnTheDeedList}
        isLoading={isPendingRelatedPeople || isPendingOnTheDeedList}
        menuOptions={
          <Button
            onClick={toggleAddRelatedPersonModal}
            variant={ButtonVariant.Text}
            data-testid={`opportunities.${opportunity.id}-add-another-person-to-coinsureds-button`}
            customCss={css`
              padding: ${spacings.px12}px;
            `}
          >
            + Add another person
          </Button>
        }
      />
      {addRelatedPersonModalShown && (
        <RelatedPersonFormModal
          cancelHandler={toggleAddRelatedPersonModal}
          isLoading={isAddingRelatedPerson}
          confirmHandler={async values => {
            const {
              related_person: { gid }
            } = await addRelatedPerson({
              sourcePersonGid: person.gid,
              data: values
            });

            refetchRelatedPeople();

            analytics.track('Related person added', {
              person_gid: person.gid,
              place: 'quoting_configuration_page'
            });

            const currentValue = formValues.opportunities[opportunity.id.toString()]!.insured_gids || [];

            setFieldValue(`opportunities.${opportunity.id}.insured_gids`, currentValue.concat(gid));

            toggleAddRelatedPersonModal();
          }}
        />
      )}
    </FlexBox>
  );
};

export default RealPropertyInsuredPeopleWithTheDeed;
