import { Branded } from '.';

export enum Operations {
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  FILLED = 'filled',
  NOT_FILLED = 'not_filled',
  ONE_OF = 'one_of',
  NOT_ONE_OF = 'not_one_of',
  LESS_THAN = 'less_than',
  GREATER_THAN = 'greater_than',
  BEFORE = 'before',
  AFTER = 'after',
  YEARS_OLDER_THAN = 'years_older_than',
  YEARS_NEWER_THAN = 'years_newer_than',
  MATCH = 'match',
  NOT_MATCH = 'not_match'
}

type ConfigVariableKey = Branded<string, 'ConfigVariableKey'>;

export interface IScoutingConfigVariable {
  key: ConfigVariableKey;
  label: string;
  operations: Operations[];
  values: null | string[];
}

export interface ICondition {
  source: ConfigVariableKey;
  value: string | number | Array<string> | Array<number>;
  operation: Operations | '';
}

export interface IConditionGroup {
  conditions: ICondition[];
}

export interface IScoutingStrategyConfigStep {
  id: number;
  position: number;
  title: string | undefined;
  description: string;
  name: null | string;
  condition_groups: IConditionGroup[];
}

export type IStepWithoutConditionGroups = Omit<IScoutingStrategyConfigStep, 'condition_groups'>;

export interface IScoutingStrategyConfig {
  id: number;
  name: string;
  key: string;
  scouting_strategy_steps: IScoutingStrategyConfigStep[];
  priority: number;
  condition_groups?: IConditionGroup[];
}
