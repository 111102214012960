import * as React from 'react';

import { PDFContext } from '../../../components/common/PDFViewer';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { DocumentWithTickIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import DeleteDocumentModal from '../../../components/FileModals/DeleteModal';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../hooks';
import { IDocument } from '../../../interfaces';
import { FileToUpload } from '../../../interfaces/IDocument';
import { useRetrievePersonDocumentServiceUrl } from '../../../queries/people/person_documents/usePersonDocuments';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { fileSizeFormatter } from '../../../utils/formatter';
import { useVerticalTabsCallbacks } from '../../GuidedSellingExperience/_hooks';

// to be extended to meet more design requirements

const Document = ({
  file,
  onDelete,
  preview = true
}: {
  file: IDocument | FileToUpload;
  onDelete: (file: IDocument | FileToUpload) => Promise<unknown>;
  preview?: boolean;
}) => {
  const { personGid } = useGuidedSellingExperienceContext();
  const documentTypeTitle = file.document_type_title?.toString();
  const [fileToPreview, setFileToPreview] = React.useContext(PDFContext);
  const [deleteFile, toggleDeleteFile] = useToggle(false);
  const { onTabClose } = useVerticalTabsCallbacks();
  const fileIsPreviewed = file.filesize === fileToPreview?.filesize;

  const { mutateAsync: retrieveServiceUrl } = useRetrievePersonDocumentServiceUrl();

  return (
    <>
      <FlexBox
        pv={spacings.px6}
        alignItemsCenter
        justifySpaceBetween
        border
        roundBorder
        mt={spacings.px12}
        p={spacings.px12}
      >
        <FlexBox gap={spacings.px12} alignItemsCenter>
          <Container border roundBorder p={spacings.px4}>
            <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
              <DocumentWithTickIcon color={colors.statusGreen} height={24} width={24} />
            </Container>
          </Container>

          <FlexBox columnDirection>
            <FlexBox alignItemsCenter fitParentWidth gap={spacings.px8}>
              <Text className="fs-mask" singleLine pl={spacings.px8} title={file.name}>
                {file.name}
              </Text>
              <Tag
                transparent
                label={documentTypeTitle || 'Unknown'}
                textColor={documentTypeTitle ? colors.violet : colors.grey60}
                backgroundColor={documentTypeTitle ? colors.violet : colors.grey60}
                border
                bold
              />
            </FlexBox>
            <Paragraph type="small" color={colors.grey60} pl={spacings.px8} singleLine>
              {fileSizeFormatter(file.filesize)}
            </Paragraph>
          </FlexBox>
        </FlexBox>
        <FlexBox gap={spacings.px8} alignItemsCenter>
          {preview && (
            <IconButton
              focus={fileIsPreviewed}
              color={colors.azure50}
              icon={ButtonIcons.PictureInPicture}
              onClick={() => {
                if (fileIsPreviewed) {
                  setFileToPreview(undefined);
                } else {
                  if ('file' in file) {
                    setFileToPreview(file);
                  } else {
                    personGid &&
                      retrieveServiceUrl({ personGid, documentId: file.id }).then(url => {
                        setFileToPreview({ ...file, link: url });
                      });
                  }
                }
                onTabClose();
              }}
            />
          )}
          <IconButton
            color={colors.black}
            icon={ButtonIcons.Delete}
            onClick={() => {
              toggleDeleteFile();
            }}
          />
        </FlexBox>
      </FlexBox>
      {deleteFile && (
        <DeleteDocumentModal document={file} toggleDeleteDocument={toggleDeleteFile} onDelete={() => onDelete(file)} />
      )}
    </>
  );
};

export default Document;
