import { useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { IScoutingStrategy } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const getScoutingStrategy = ({
  callLogId,
  personGid,
  leadGid,
  candidateGid,
  scoutingStrategyConfigKey
}: {
  callLogId?: string | number;
  personGid?: string;
  leadGid?: string;
  candidateGid?: string;
  scoutingStrategyConfigKey?: string;
}) => {
  return api.get(
    `/api/frontend/scouting_strategy_configs/pick_config?${toQueryParams({
      call_log_id: callLogId,
      person_gid: personGid,
      lead_gid: leadGid,
      candidate_gid: candidateGid,
      scouting_strategy_config_key: scoutingStrategyConfigKey
    })}`
  );
};

export const useScoutingStrategy = ({
  callLogId,
  personGid,
  leadGid,
  candidateGid,
  scoutingStrategyConfigKey,
  options
}: {
  callLogId?: string | number;
  personGid?: string;
  leadGid?: string;
  candidateGid?: string;
  scoutingStrategyConfigKey?: string;
  options?: Omit<UseQueryOptions<{ scouting_strategy_config: IScoutingStrategy }, any, any>, 'queryKey'>;
}) =>
  useQuery({
    queryKey: ['scouting_strategy', callLogId, personGid, leadGid, candidateGid, scoutingStrategyConfigKey],
    queryFn: (): Promise<{ scouting_strategy_config: IScoutingStrategy }> =>
      getScoutingStrategy({ callLogId, personGid, leadGid, candidateGid, scoutingStrategyConfigKey }),
    ...options,
    enabled: !!(callLogId || personGid || leadGid || candidateGid),
    select: data => data.scouting_strategy_config
  });

export const useSuspenseScoutingStrategy = ({
  callLogId,
  personGid,
  leadGid,
  candidateGid,
  scoutingStrategyConfigKey
}: {
  callLogId?: string | number;
  personGid?: string;
  leadGid?: string;
  candidateGid?: string;
  scoutingStrategyConfigKey: string;
}) =>
  useSuspenseQuery({
    queryKey: ['scouting_strategy', callLogId, personGid, leadGid, candidateGid, scoutingStrategyConfigKey],
    queryFn: (): Promise<{ scouting_strategy_config: IScoutingStrategy }> =>
      getScoutingStrategy({ callLogId, personGid, leadGid, candidateGid, scoutingStrategyConfigKey }),
    select: data => data.scouting_strategy_config
  });
