/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import FlexBox from '../../../../../../components/core/FlexBox';
import { ShieldCheckIcon } from '../../../../../../components/core/icons';
import Text from '../../../../../../components/core/Text';
import { IQuote } from '../../../../../../interfaces';
import { QuoteKind } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';
import { moneyFormatter } from '../../../../../../utils/formatter';

const Premium = ({
  quote: {
    kind,
    attributes: { premium, bundled_premium, bundle_options }
  },
  isPremiumAnnual
}: {
  quote: IQuote;
  isPremiumAnnual?: boolean;
}) => {
  if (!premium && !bundled_premium) {
    return null;
  }

  const monolineEnabled = !bundle_options || bundle_options.monoline_enabled;
  const formattedPremium = premium && moneyFormatter(isPremiumAnnual === true ? premium : premium / 12, true);
  const formattedBundledPremium =
    bundled_premium && moneyFormatter(isPremiumAnnual === true ? bundled_premium : bundled_premium / 12, true);

  return (
    <>
      <FlexBox gap={spacings.px4} alignItemsCenter>
        {kind === QuoteKind.RC2 && (
          <ShieldCheckIcon
            color={colors.statusGreen}
            data-tip="Near final price with reports"
            data-for="near-final-price-tooltip"
          />
        )}
        {monolineEnabled ? (
          <>
            {!!formattedPremium && (
              <Text
                customCss={css`
                  white-space: nowrap;
                `}
              >
                {formattedPremium}
              </Text>
            )}
            {!!formattedBundledPremium && (
              <Text
                customCss={css`
                  white-space: nowrap;
                `}
                color={colors.statusGreen}
                ml={formattedPremium ? spacings.px4 : undefined}
              >
                Bundle: {formattedBundledPremium}
              </Text>
            )}
          </>
        ) : (
          <>
            {!!formattedBundledPremium && (
              <Text
                customCss={css`
                  white-space: nowrap;
                `}
                color={colors.statusGreen}
              >
                Bundle: {formattedBundledPremium}
              </Text>
            )}
            {!!formattedPremium && !formattedBundledPremium && (
              <Text
                customCss={css`
                  white-space: nowrap;
                `}
                color={colors.statusGreen}
              >
                {formattedPremium}
              </Text>
            )}
          </>
        )}
      </FlexBox>
    </>
  );
};

export default Premium;
