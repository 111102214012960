/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import Container from '../../../../components/core/Container';
import { Translations } from '../../../../constants';
import { ILeadLicensingStatus } from '../../../../queries/leads/useLeadLicensingStatus';
import { isISR } from '../../../../services/authInfo';
import { Spacings } from '../../../../theme/variables';

type LeadLicensingWarningProps = Spacings & ILeadLicensingStatus;

const LeadLicensingWarning = ({ is_user_licensed, primary_state, ...rest }: LeadLicensingWarningProps) => {
  if (is_user_licensed || isISR()) {
    return null;
  }

  return (
    <Container
      {...rest}
      customCss={css`
        max-width: 880px;
      `}
    >
      <SystemMessage
        type="warning"
        heading="You're not licensed in this state"
        description={`Either your license expired or you're not licensed in ${Translations.usaState(
          primary_state
        )}. Initiate new transfer to the right skill in Telephony.`}
      />
    </Container>
  );
};

export default LeadLicensingWarning;
