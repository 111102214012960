/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Translations } from '../../../constants';
import { DispositionType } from '../../../interfaces/IDisposition';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import { defaultFontValues } from '../../../theme/typography';
import { requiredField } from '../../../utils/yupRules';
import { ErrorCol } from '../../common';
import { Select } from '../../common/FormikComponents';
import FormModal from '../../core/FormModal';
import { ModalSize } from '../../core/Modal';

interface CouldNotContactProps {
  leadId: string | number;
  closeModal: () => void;
  onDispositionUpdated?: (dispositionType: DispositionType) => void;
}

const CouldNotContactModal: React.FC<CouldNotContactProps> = ({ leadId, closeModal, onDispositionUpdated }) => {
  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  return (
    <FormModal
      returnFocusAfterClose={false}
      initialValues={{
        reason: Translations.couldNotContactReasonOptions[0]!.key
      }}
      validationSchema={yup.object().shape({
        reason: requiredField
      })}
      confirmHandler={values => {
        return updateDisposition({
          leadId,
          disposition_type: DispositionType.CouldNotContact,
          disposition_reason: values.reason
        }).then(() => {
          closeModal();
          onDispositionUpdated?.(DispositionType.CouldNotContact);
        });
      }}
      cancelHandler={() => {
        closeModal();
      }}
      title='Confirm a "Could Not Contact" reason'
      confirmText="Confirm"
      size={ModalSize.medium}
      renderForm={({ errors, touched }) => (
        <div
          css={css`
            ${defaultFontValues}
          `}
        >
          <ErrorCol
            style={{ marginTop: '12px' }}
            name="reason"
            error={errors['reason']}
            when={Boolean(errors['reason'] && touched['reason'])}
          >
            <Field
              name="reason"
              component={Select}
              options={Translations.couldNotContactReasonOptions}
              title="Reason"
              placeholder="Please select a reason"
              labelName="value"
              valueName="key"
              data-testid="select-could-not-contact-reason"
            />
          </ErrorCol>
        </div>
      )}
    />
  );
};

export default CouldNotContactModal;
