/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';

import SystemMessage from '../../../../../components/core/Alert/SystemMessage';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import Paragraph from '../../../../../components/core/Paragraph';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { IOpportunity } from '../../../../../interfaces/IOpportunity';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import LifeCoverage from './LifeCoverage';

const LifeOpportunity = ({ opportunity }: { opportunity: IOpportunity }) => {
  const noAppointedCarriers = opportunity.disqualification_reason === 'no_carriers_appointments';
  const address = opportunity.assets?.[0]?.address;

  const { values } = useFormikContext();

  const isOpportunityEnabled = !!(values as any)?.opportunities?.[opportunity.id.toString()]?.enabled;

  return (
    <FlexBox pv={spacings.px8} ph={spacings.px12} gap={spacings.px12}>
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <FlexBox alignItemsCenter>
          <CheckboxField
            inline
            key={`opportunities.${opportunity.id}.enabled`}
            id={`opportunities.${opportunity.id}.enabled`}
            name={`opportunities.${opportunity.id}.enabled`}
            disabled={!!opportunity.disqualification_reason}
            label={
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <Text bold type="large">
                  {opportunity.title}
                </Text>
                {opportunity.primary && (
                  <Tag
                    label="Primary opportunity"
                    transparent
                    backgroundColor={colors.violet}
                    textColor={colors.violet}
                    mt={spacings.px4}
                  />
                )}
              </FlexBox>
            }
          />
        </FlexBox>
        {address && <Paragraph className="fs-mask">{address.full}</Paragraph>}
        {noAppointedCarriers ? (
          <FlexBox>
            <SystemMessage
              type="warning"
              // eslint-disable-next-line max-len
              heading="You can't quote this opportunity because no integrated or non-integrated carriers in this State are available."
            />
          </FlexBox>
        ) : (
          <>{isOpportunityEnabled && <LifeCoverage opportunity={opportunity} />}</>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default LifeOpportunity;
