/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import FlexBox from '../../components/core/FlexBox';
import { NoteIcon } from '../../components/core/icons';
import VerticalTabs from '../GuidedSellingExperience/_components/VerticalTabs';
import CustomerViewHeader from './CustomerViewHeader';
import Notes from './Tabs/Notes/intex';

const CustomerView = () => {
  const { gid: personGid } = useParams();

  if (!personGid) {
    return null;
  }

  return (
    <FlexBox
      css={css`
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr auto;
        grid-template-areas:
          'header  tabs'
          'main tabs';

        height: 100%;
      `}
    >
      <CustomerViewHeader />
      <FlexBox
        css={css`
          overflow: hidden;
          grid-area: 'tabs';
        `}
      >
        <VerticalTabs
          collapsedByDefault
          tabs={[
            {
              tabIcon: (
                <React.Fragment key="note">
                  <NoteIcon />
                </React.Fragment>
              ),
              tabName: 'Notes',
              content: <Notes personGid={personGid} />
            }
          ]}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default CustomerView;
