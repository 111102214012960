import { css } from '@emotion/react';
import * as React from 'react';

import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import { isEndDisposition } from '../../../../components/DispositionsModals/dispositionsHelper';
import { IOpportunity } from '../../../../interfaces';
// eslint-disable-next-line max-len
import { useDisqualifyDataCollectionOpportunity } from '../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import { Qualification } from '../../../../queries/people/usePersonAssetQualification';
import { spacings } from '../../../../theme/variables';

type DisqualifySectionProps = {
  personAssetQualification: Qualification | false;
  opportunityId: IOpportunity['id'];
  leadId: number;
  onDisqualify: () => void;
  onLeadClosed: () => void;
};

const DisqualifySection = ({
  personAssetQualification,
  opportunityId,
  leadId,
  onDisqualify,
  onLeadClosed
}: DisqualifySectionProps) => {
  const { mutateAsync: disqualifyOpportunity, isPending: isDisqualifyingOpportunity } =
    useDisqualifyDataCollectionOpportunity();

  if (!personAssetQualification || personAssetQualification.result !== 'disqualified') {
    return null;
  }

  return (
    <>
      <SystemMessage
        type="error"
        mt={spacings.px8}
        mb={spacings.px8}
        customCss={css`
          max-width: 700px;
        `}
        description={personAssetQualification.agent_explanation}
      />
      <Button
        loading={isDisqualifyingOpportunity}
        size={ButtonSize.Small}
        variant={ButtonVariant.SecondaryDanger}
        onClick={async () => {
          const response = await disqualifyOpportunity({
            leadId: leadId!,
            opportunityId,
            disqualificationReason: personAssetQualification.code,
            disqualificationMessage: personAssetQualification.agent_explanation
          });

          onDisqualify();
          if (isEndDisposition(response.data_collection.current_disposition.disposition_type)) {
            onLeadClosed();
          }
        }}
      >
        Disqualify home
      </Button>
    </>
  );
};

export default DisqualifySection;
