import { IGroupedQuotes, IQuote } from '../../../../../interfaces';
import { IAssetsQuotesGroup } from '../../../../../interfaces/IGroupedQuotes';
import { EligibilityResults, QuoteStatus } from '../../../../../interfaces/IQuote';
import { IQuotesRequest } from '../../../../../interfaces/IQuotesRequests';
import { SourceProducts } from '../../../../../interfaces/ISourceDimensions';

export const findLatestCxQuotesRequest = (quotesRequests: IQuotesRequest[] | undefined) => {
  if (!quotesRequests?.length) {
    return undefined;
  }

  return [...quotesRequests].find(({ source_product }) => source_product !== SourceProducts.AgentManual);
};

export const isQuoteSelectedByCustomer = ({ attributes: { is_customer_choice } }: IQuote) => is_customer_choice;

export const customerChoicesFromQuotes = (groupedQuotes: IGroupedQuotes<IAssetsQuotesGroup> | undefined) =>
  groupedQuotes?.groups?.flatMap(({ quotes }) => quotes).filter(quote => isQuoteSelectedByCustomer(quote));

export const isQuoteRequiresDataFix = ({ status }: IQuote) => status === QuoteStatus.InvalidData;

export const isQuoteEligibleOrPartlyEligible = ({ status, attributes: { eligibility } }: IQuote) => {
  return (
    [QuoteStatus.Success, QuoteStatus.CompleteManually].includes(status) &&
    eligibility &&
    [EligibilityResults.Eligible, EligibilityResults.Uncertain].includes(eligibility.result)
  );
};

export const isQuoteNonEligible = ({ status, attributes: { eligibility } }: IQuote) => {
  if (status === QuoteStatus.DeclinedBusiness) {
    return true;
  }

  return (
    [QuoteStatus.Success, QuoteStatus.CompleteManually].includes(status) &&
    eligibility?.result === EligibilityResults.Ineligible
  );
};
