/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import CollapsingContainer from '../../../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../../../components/core/FlexBox';
import Paragraph, { ParagraphType } from '../../../../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../../../../components/core/PolicyTypeIcon';
import Text from '../../../../../../components/core/Text';
import featureFlags from '../../../../../../constants/featureFlags';
import { VehicleOpportunity } from '../../../../../../interfaces/IOpportunity';
import { PolicyType } from '../../../../../../interfaces/IPolicyType';
import { QuoteStatus } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';
import { spacings } from '../../../../../../theme/variables';
import {
  isQuoteEligibleOrPartlyEligible,
  isQuoteNonEligible,
  isQuoteRequiresDataFix,
  isQuoteSelectedByCustomer
} from '../../_helpers';
import useLicenseLookupAlert from '../../_hooks/useLicenseLookupAlert';
import useQuotesModalView from '../../_hooks/useModalView';
import QuotingErrorsByAssets from '../../QuotingErrors/QuotingErrorsByAssets';
import DeprecatedVehicleCoverageLevel from '../CoverageLevel/DeprecatedVehicleCoverageLevel';
import VehicleCoverageLevel from '../CoverageLevel/VehicleCoverageLevel';
import VehiclesGroupHeader from '../GroupHeaders/VehiclesGroupHeader';
import QuoteRow from '../QuoteRow/QuoteRow';
import { QuoteGroupProps } from '.';

const VehiclesQuotesGroup = ({
  quotesGroup: { quotes, opportunity: autoOpportunity },
  assets,
  leadId,
  personGid,
  refetchQuotesRequests,
  quotingFinished,
  errors,
  quotesRequest
}: QuoteGroupProps) => {
  const { quotesModalView } = useQuotesModalView();
  const [isPremiumAnnual, setAnnualPremium] = React.useState(true);
  const realQuotes = quotes.filter(({ status }) => status !== QuoteStatus.CompleteManually);

  const eligibleQuotes = realQuotes.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const invalidDataQuotes = realQuotes.filter(quote => isQuoteRequiresDataFix(quote));
  const notEligibleQuotes = realQuotes.filter(quote => isQuoteNonEligible(quote));

  const carriersRecommendations = quotes.filter(({ status }) => status === QuoteStatus.CompleteManually);

  const eligibleRecommendations = carriersRecommendations.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleRecommendations = carriersRecommendations.filter(quote => isQuoteNonEligible(quote));

  const customerChoice = realQuotes.find(quote => isQuoteSelectedByCustomer(quote));

  const showAutoCoverageConfig = leadId && quotes[0]?.attributes?.policy_type === PolicyType.Auto;

  const eligibleEntriesCount = eligibleQuotes.length + invalidDataQuotes.length + eligibleRecommendations.length;
  const nonEligibleEntriesCount = notEligibleQuotes.length + notEligibleRecommendations.length;

  useLicenseLookupAlert(!!eligibleEntriesCount);

  return (
    <FlexBox columnDirection gap={spacings.px8}>
      {!quotesModalView && (
        <>
          <FlexBox justifySpaceBetween gap={spacings.px8}>
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <PolicyTypeIcon policyType={autoOpportunity.policy_type} />
              <Text
                bold={!featureFlags.newQuotesUI}
                type="large"
                customCss={css`
                  white-space: pre-wrap;
                `}
                singleLine
              >
                Auto ({quotes.length})
              </Text>
              <Text className="fs-mask" ml={spacings.px6} type="large" color={colors.grey60} singleLine>
                {autoOpportunity.assets?.[0]?.address.full}
              </Text>
            </FlexBox>

            {featureFlags.newQuotesUI && (
              <FlexBox alignItemsCenter>
                {showAutoCoverageConfig && (
                  <VehicleCoverageLevel
                    refetchQuotesRequests={refetchQuotesRequests}
                    opportunity={autoOpportunity as VehicleOpportunity}
                    customerChoice={customerChoice}
                    leadId={leadId}
                    personGid={personGid}
                  />
                )}
                {errors && errors.length > 0 && <QuotingErrorsByAssets errors={errors} quotesRequest={quotesRequest} />}
              </FlexBox>
            )}
          </FlexBox>

          {showAutoCoverageConfig && !featureFlags.newQuotesUI && (
            <DeprecatedVehicleCoverageLevel
              refetchQuotesRequests={refetchQuotesRequests}
              opportunity={autoOpportunity as VehicleOpportunity}
              customerChoice={customerChoice}
              leadId={leadId}
              personGid={personGid}
            />
          )}
        </>
      )}

      {eligibleEntriesCount > 0 && (
        <VehiclesGroupHeader isPremiumAnnual={isPremiumAnnual} setAnnualPremium={setAnnualPremium} />
      )}
      {eligibleEntriesCount > 0 && (
        <CollapsingContainer
          title={`Eligible and partly eligible (${eligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          {eligibleQuotes.map(quote => {
            return (
              <QuoteRow
                key={quote.gid}
                quote={quote}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
                opportunity={autoOpportunity}
              />
            );
          })}
          {invalidDataQuotes.map(quote => {
            return (
              <QuoteRow
                key={quote.gid}
                quote={quote}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
          {eligibleRecommendations.map(recommendation => {
            return (
              <QuoteRow
                key={recommendation.gid}
                quote={recommendation}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
        </CollapsingContainer>
      )}
      {quotingFinished && eligibleEntriesCount === 0 && nonEligibleEntriesCount ? (
        <CollapsingContainer
          title={`Eligible and partly eligible (${eligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          <FlexBox justifyCenter pv={spacings.px24}>
            <Paragraph color={colors.grey60}>No quotes available</Paragraph>
          </FlexBox>
        </CollapsingContainer>
      ) : null}
      {nonEligibleEntriesCount > 0 && (
        <CollapsingContainer
          title={`Not eligible (${nonEligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
          openedByDefault={false}
        >
          {notEligibleQuotes.map(quote => {
            return (
              <QuoteRow
                key={quote.gid}
                quote={quote}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
          {notEligibleRecommendations.map(recommendation => {
            return (
              <QuoteRow
                key={recommendation.gid}
                quote={recommendation}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            );
          })}
        </CollapsingContainer>
      )}
      {quotingFinished && !eligibleEntriesCount && !nonEligibleEntriesCount && (
        <FlexBox justifyCenter pv={spacings.px24}>
          <Paragraph color={colors.grey60}>No quotes available</Paragraph>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default VehiclesQuotesGroup;
