/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import transferImage from '../../../assets/img/transfer.svg';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import { SelectField } from '../../../components/core/forms/fields';
import buildUIFlow from '../../../components/core/forms/uiFlowBuilder';
import Heading from '../../../components/core/Heading';
import Paragraph from '../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { DataCollectionStepsKey, IDataCollection } from '../../../interfaces/IDataCollection';
import DatapointKey from '../../../interfaces/IDatapoint';
import { DispositionType } from '../../../interfaces/IDisposition';
import { OpportunityStatus, OpportunityVerificationStatus } from '../../../interfaces/IOpportunity';
import { isNonLifeAncillaryPolicyType } from '../../../interfaces/IPolicyType';
import { HOME_CROSS_SALE_INTENT_FLOW_V1, saveAnswers, usePersonUIFlow } from '../../../queries/workflows/useUiFlow';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';
import { capitalize } from '../../../utils/formatter';
import { isImageCached } from '../../../utils/object';
import { requiredField } from '../../../utils/yupRules';
import { GuidedSellingPathnames } from '../navigation';

const ISRFinishPage = ({ dataCollection }: { dataCollection?: IDataCollection | null }): JSX.Element => {
  const [imageLoaded, setImageLoaded] = React.useState(() => isImageCached(transferImage));
  const navigate = useNavigate();
  const { search } = useLocation();
  const { personGid, leadGid } = useGuidedSellingExperienceContext();

  const homePage = dataCollection?.pages?.find(page => page.key === DataCollectionStepsKey.HomeProfile);

  const homeOpportunities = homePage?.opportunities?.filter(opportunity => !isNonLifeAncillaryPolicyType(opportunity));

  const isLeadDisqualified = dataCollection?.current_disposition.disposition_type === DispositionType.NotQualified;

  const { data: homeCrossSaleIntentUIFlowData } = usePersonUIFlow({
    uiFlowKey: homePage?.is_skippable ? HOME_CROSS_SALE_INTENT_FLOW_V1 : '',
    personGid
  });

  const homeCrossSaleIntentUIFlow = React.useMemo(
    () => buildUIFlow({ uiFlowResponse: homeCrossSaleIntentUIFlowData }),
    [homeCrossSaleIntentUIFlowData]
  );

  const submitHomeCrossSaleIntentUIFlow = (formValues: Record<string, any>) =>
    saveAnswers({
      uiFlowKey: HOME_CROSS_SALE_INTENT_FLOW_V1,
      answers:
        homeCrossSaleIntentUIFlow!.answersForFormValues({
          personGid: personGid!,
          engagementGid: leadGid!,
          assetGid: undefined,
          formValues
        }) || []
    });

  const opportunityQualificationOptions = [
    { value: 'Qualified', key: OpportunityVerificationStatus.Qualified },
    { value: 'Ineligible', key: OpportunityVerificationStatus.Ineligible },
    { value: 'Not verified', key: OpportunityVerificationStatus.NotVerified },
    { value: 'Skipped', key: OpportunityVerificationStatus.Skipped }
  ];

  const initialHomeOpportunitiesValues = homeOpportunities?.reduce(
    (acc, opportunity) => ({
      ...acc,
      [`${opportunity.id}_eligibility`]: opportunity.disqualification_reason
        ? OpportunityVerificationStatus.Ineligible
        : OpportunityVerificationStatus.Qualified,
      [`${opportunity.id}_reason`]: opportunity.disqualification_reason
    }),
    {}
  );

  const validationSchema =
    homeCrossSaleIntentUIFlow &&
    yup.object().shape({
      [DatapointKey.PersonHomeInsuranceRejectedReason]: yup
        .string()
        .when(DatapointKey.PersonIsInterestedInHomeInsurance, {
          is: OpportunityVerificationStatus.Skipped,
          then: schema => schema.concat(requiredField)
        })
    });

  if (!homeCrossSaleIntentUIFlowData && homePage?.is_skippable) {
    return (
      <Container m={spacings.px24}>
        <Skeleton height={300} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
        <Skeleton count={6} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
      </Container>
    );
  }

  return (
    <>
      {dataCollection && (
        <BaseForm
          pb={spacings.px24}
          pt={spacings.px24}
          pl={spacings.px24}
          pr={spacings.px24}
          controlsAlignment="right"
          type="fullPage"
          controlsWidth={sizes.mediumFormInputWidth}
          initialValues={
            {
              auto_opportunity: OpportunityVerificationStatus.NotVerified,
              ...homeCrossSaleIntentUIFlow?.initialValues(),
              ...initialHomeOpportunitiesValues
            } as Record<string, string>
          }
          validationSchema={validationSchema}
          submitText="Got it! Close"
          submitTestId="close-data-collection-button"
          onSubmit={async values => {
            if (homeCrossSaleIntentUIFlow) {
              await submitHomeCrossSaleIntentUIFlow(values);
            }
            return navigate(`${GuidedSellingPathnames.ScoutingReport}${search}`);
          }}
          renderForm={({ values }) => {
            const showSkippedInput =
              homeCrossSaleIntentUIFlow &&
              values[DatapointKey.PersonIsInterestedInHomeInsurance] === OpportunityVerificationStatus.Skipped;

            return (
              <FlexBox columnDirection gap={spacings.px24}>
                <img src={transferImage} alt="Transfer" width={660} onLoad={() => setImageLoaded(true)} />
                {imageLoaded && (
                  <FlexBox ml={spacings.px24} gap={spacings.px12} columnDirection>
                    {isLeadDisqualified ? (
                      <Heading>Lead is disqualified</Heading>
                    ) : (
                      <>
                        <Heading>{"You've collected all the info! 🎉"}</Heading>
                        <Paragraph>
                          {`Great job, ${authInfo.currentUserFirstName}!
          You’ve collected everything that an agent needs to continue sales.
          Let us know how it went.`}
                        </Paragraph>
                      </>
                    )}
                    {showSkippedInput ? (
                      <>
                        {homeCrossSaleIntentUIFlow?.containers
                          ?.flatMap(({ elements }) => elements)
                          .filter(({ kind }) => kind === 'question')
                          .map(element => {
                            return homeCrossSaleIntentUIFlow.inputForQuestion({
                              element,
                              formValues: values,
                              propsProvider: ({ key }) => ({
                                inline: true,
                                required: key === DatapointKey.PersonHomeInsuranceRejectedReason,
                                key,
                                disabled:
                                  key === DatapointKey.PersonIsInterestedInHomeInsurance &&
                                  values[DatapointKey.PersonIsInterestedInHomeInsurance],
                                secondary: key === DatapointKey.PersonHomeInsuranceRejectedReason
                              })
                            });
                          })}
                      </>
                    ) : (
                      <>
                        {homeOpportunities?.map(opportunity => {
                          return (
                            <FlexBox key={opportunity.id} mb={spacings.px8} columnDirection gap={spacings.px4}>
                              <SelectField
                                name={`${opportunity.id}_eligibility`}
                                id={`${opportunity.id}_eligibility`}
                                label={opportunity.title}
                                options={opportunityQualificationOptions}
                                inline
                                disabled
                              />
                              {opportunity.status === OpportunityStatus.NotQualified && (
                                <SelectField
                                  name={`${opportunity.id}_reason`}
                                  id={`${opportunity.id}_reason`}
                                  label="Reason"
                                  options={[
                                    {
                                      value: capitalize(opportunity.disqualification_reason),
                                      key: opportunity.disqualification_reason
                                    }
                                  ]}
                                  inline
                                  disabled
                                  secondary
                                />
                              )}
                            </FlexBox>
                          );
                        })}
                      </>
                    )}
                    <SelectField
                      label="Auto opportunity"
                      id="auto_opportunity"
                      name="auto_opportunity"
                      options={opportunityQualificationOptions}
                      inline
                      disabled
                    />
                  </FlexBox>
                )}
              </FlexBox>
            );
          }}
        />
      )}
    </>
  );
};

export default ISRFinishPage;
