/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../../hooks';
import { AssetType } from '../../../../interfaces/IPersonAsset';
import usePersonAssets from '../../../../queries/people/person_assets/usePersonAssets';
import usePersonLoans from '../../../../queries/people/usePersonLoans';
import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import LoanAssetPicker from '../../../Customer/CustomerLoans/LoanAssetPicker';
import LoanEditor from '../../../Customer/CustomerLoans/LoanEditor';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import useDataEditingForbidden from '../../_hooks/useDataEditingForbidden';
import TabSkeleton from '../_components/TabSkeleton';
import Loan from './Loan';

const PersonLoansTab = () => {
  const [pickedAssetGid, setPickedAssetGid] = React.useState<string>();
  const [assetPickerOpened, toggleAssetPickerOpened] = useToggle(false);

  const [editorOpened, toggleEditorOpened] = useToggle(false);
  const { personGid, lead } = useGuidedSellingExperienceContext();

  const { data: loans, isFetchedAfterMount: isFetchedLoans } = usePersonLoans({ personGid });
  const { data: personAssets, isFetched: isFetchedAssets } = usePersonAssets({ personGid });
  const vehicles = personAssets?.filter(asset => asset.asset_name === AssetType.Auto);
  const dataEditingForbidden = useDataEditingForbidden();

  const hideAssetPicker = () => {
    setPickedAssetGid(undefined);
    toggleAssetPickerOpened();
  };

  return (
    <>
      <VerticalTabHeader>
        <FlexBox gap={spacings.px8} alignItemsBaseline>
          <Paragraph type="large">Loans </Paragraph>
          <ButtonWithoutBorder
            data-testid="add-loan-button"
            onClick={() => {
              toggleAssetPickerOpened();
              analytics.track('Add loan clicked', {
                person_gid: personGid,
                place: 'guided_selling_side_panel'
              });
            }}
            disabled={!personAssets?.length || dataEditingForbidden}
          >
            Add
          </ButtonWithoutBorder>
        </FlexBox>
      </VerticalTabHeader>
      {isFetchedLoans && isFetchedAssets ? (
        <VerticalTabContent>
          {!!loans?.length && (
            <FlexBox columnDirection gap={spacings.px20}>
              {loans?.map(loan => (
                <Loan
                  key={loan.gid}
                  loan={loan}
                  vehicles={vehicles}
                  personGid={personGid}
                  partner={lead?.source_dimensions?.partner}
                  disabled={dataEditingForbidden}
                />
              ))}
            </FlexBox>
          )}
          {!loans?.length && (
            <Paragraph
              mt={spacings.px24}
              color={colors.grey60}
              customCss={css`
                text-align: center;
              `}
            >
              No loan information found
            </Paragraph>
          )}
          {assetPickerOpened && (
            <LoanAssetPicker
              leadId={lead?.id}
              assets={personAssets!}
              cancelHandler={hideAssetPicker}
              guidedSellingContext
              confirmHandler={assetGid => {
                hideAssetPicker();
                setPickedAssetGid(assetGid);
                toggleEditorOpened();
              }}
            />
          )}
          {editorOpened && pickedAssetGid && (
            <LoanEditor
              personGid={personGid!}
              assetGid={pickedAssetGid}
              partner={lead?.source_dimensions?.partner}
              cancelHandler={toggleEditorOpened}
              confirmHandler={() => toggleEditorOpened()}
            />
          )}
        </VerticalTabContent>
      ) : (
        <TabSkeleton ph={spacings.px12} pb={spacings.px12} />
      )}
    </>
  );
};

export default PersonLoansTab;
