/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import { VisibilityOffIcon, WarningIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import colors from '../../../../theme/colors';
import { borderRadius, spacings } from '../../../../theme/variables';
import { IDataCollectionStep } from '../_interfaces/IDataCollectionStep';

interface DataCollectionSubStepProps {
  subStep: IDataCollectionStep;
  showIndex: boolean;
}

export const stepColor = (step: IDataCollectionStep) => {
  if (step.is_navigation_disabled || !step.is_data_collection_enabled) {
    return colors.grey30;
  }

  if (step.active) {
    return colors.azure50;
  }

  return colors.black;
};

const DataCollectionSubStep = ({ subStep, showIndex }: DataCollectionSubStepProps) => {
  return (
    <div
      key={subStep.key}
      css={css`
        padding-left: ${spacings.px28}px;
      `}
    >
      <Button
        onClick={event => {
          subStep.onClick();
          event.stopPropagation();
        }}
        variant={ButtonVariant.Text}
        disabled={subStep.is_navigation_disabled || !subStep.is_data_collection_enabled}
        customCss={css`
          display: flex;
          width: 100%;
          align-items: center;
          padding: ${spacings.px8}px;
          gap: ${spacings.px8}px;
          background-color: ${subStep.active ? colors.grey5 : undefined};
          &:hover {
            background-color: ${colors.grey5};
          }
          border-radius: ${borderRadius}px;
        `}
      >
        {!subStep.is_disqualified && !subStep.is_data_collection_enabled && (
          <VisibilityOffIcon
            color={colors.grey60}
            width={spacings.px20}
            height={spacings.px20}
            css={css`
              margin-left: -${spacings.px28}px;
              overflow: visible;
            `}
          />
        )}

        <Paragraph
          customCss={css`
            text-align: start;
            white-space: nowrap;
            ${showIndex &&
            `&:after {
              margin-left: 4px;
              content: '${subStep.index + 1}';
            }`}
          `}
          type="small"
          color={stepColor(subStep)}
        >
          {subStep.label}
        </Paragraph>
        {subStep.is_disqualified && (
          <>
            <WarningIcon
              data-tip
              data-for={`${subStep.key}-tip`}
              color={colors.statusRed}
              css={css`
                overflow: visible;
              `}
            />
            <Tooltip id={`${subStep.key}-tip`} theme="light">
              <Paragraph
                customCss={css`
                  max-width: 300px;
                `}
              >
                {subStep.opportunities?.[0]?.disqualification_message}
              </Paragraph>
            </Tooltip>
          </>
        )}
      </Button>
    </div>
  );
};

export default DataCollectionSubStep;
