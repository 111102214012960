/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import { LinearProgress } from '@mui/material';

import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import { IGroupedQuotes } from '../../../../../interfaces';
import { IAssetsQuotesGroup } from '../../../../../interfaces/IGroupedQuotes';
import { QuoteState } from '../../../../../interfaces/IQuote';
import colors, { opacities } from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';

const QuotingProgress = ({ groupedQuotes }: { groupedQuotes: IGroupedQuotes<IAssetsQuotesGroup> }) => {
  if (groupedQuotes.finished_at && groupedQuotes.groups.length === 0) {
    return (
      <FlexBox fitParentWidth justifyCenter>
        <Text type="large">No quotes available</Text>
      </FlexBox>
    );
  }

  const isQuotingFinished =
    groupedQuotes.finished_at &&
    !groupedQuotes.groups.some(group => group.quotes.some(quote => quote.state === QuoteState.Started));

  return (
    <>
      <Global
        styles={() => css`
          .MuiLinearProgress-bar {
            background: linear-gradient(to right, ${colors.white}, ${colors.statusGreen});
          }
        `}
      />
      <FlexBox
        fitParentWidth
        mt={isQuotingFinished ? 0 : spacings.px8}
        alignItemsCenter
        gap={spacings.px4}
        p={isQuotingFinished ? 0 : spacings.px12}
        backgroundColor={`${colors.statusGreen}${opacities.opacity_10}`}
        roundBorder
        customCss={css`
          opacity: ${isQuotingFinished ? '0' : '1'};
          transition:
            opacity 0.3s ease-in-out,
            height 0.3s ease-in-out 0.3s;
          ${isQuotingFinished && 'height: 0;'}
        `}
        columnDirection
      >
        <Container>
          <span role="img" aria-label="unicorn">
            🦄{' '}
          </span>
          <Text bold>Hang Tight, Magic in Progress! </Text>
          <Text>
            While we&apos;re securing your bindable quotes with some behind-the-scenes wizardry. Please avoid direct
            carrier site visits now; it might mix up our magic and cause quirky errors!
          </Text>
        </Container>
        <Container fitParentWidth>
          <LinearProgress sx={{ backgroundColor: colors.white }} />
        </Container>
      </FlexBox>
    </>
  );
};

export default QuotingProgress;
