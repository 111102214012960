/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import CircledPolicyTypeIcon from '../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ErrorIcon, ShieldCheckIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import featureFlags from '../../../../constants/featureFlags';
import { IPersonOnlineSession, IQuote } from '../../../../interfaces';
import { IPersonOnlineSessionStatus } from '../../../../interfaces/IPersonOnlineSession';
import { QuoteKind } from '../../../../interfaces/IQuote';
import useQuote from '../../../../queries/people/person_quotes/useQuote';
import useQuotesRequests from '../../../../queries/quotes_requests/useQuotesRequests';
import colors from '../../../../theme/colors';
import { borderRadius, spacings } from '../../../../theme/variables';
import { moneyFormatter, relativeTimeFormatter } from '../../../../utils/formatter';
import ExternalLinkModal from '../../GuidedQuoting/Quotes/_components/ExternalLinkModal';
import Bridge from '../../GuidedQuoting/Quotes/_components/QuoteRow/Bridge';
import DeprecatedStatus from '../../GuidedQuoting/Quotes/_components/QuoteRow/DeprecatedStatus';
import Status from '../../GuidedQuoting/Quotes/_components/QuoteRow/Status';
import { isQuoteEligibleOrPartlyEligible } from '../../GuidedQuoting/Quotes/_helpers';
import useCustomerChoices from '../../GuidedQuoting/Quotes/_hooks/useCustomerChoices';

interface DigitalProfileSessionContextInfo {
  personGid: string;
  leadGid: string;
  personOnlineSession: IPersonOnlineSession;
}

const buildOnlineSessionStatus = (personOnlineSession: IPersonOnlineSession) => {
  if (personOnlineSession.current_page_key === 'final_quote') {
    return 'Customer requested quotes via online portal';
  }

  return 'Customer is in progress of collecting data to buy policy via online portal';
};

const DigitalProfileSessionContextInfo = ({
  personGid,
  leadGid,
  personOnlineSession
}: DigitalProfileSessionContextInfo) => {
  const { data: initialQuote } = useQuote({ personGid, quoteGid: personOnlineSession.original_quote_gid });
  const { data: digitalProfileBestQuote } = useQuote({ personGid, quoteGid: personOnlineSession.best_quote_gid });
  const { data: quotesRequests } = useQuotesRequests({ personGid, leadGid });
  const { customerChoices } = useCustomerChoices({ personGid, leadGid, quotesRequests });

  const customerChoiceQuote = customerChoices?.[0];
  const isCustomerChoiceFinalized = personOnlineSession.status === IPersonOnlineSessionStatus.Success;

  const [showBridgeModalForQuote, setShowBridgeModalForQuote] = React.useState<IQuote | null>(null);

  const onlineSessionStatus = buildOnlineSessionStatus(personOnlineSession);
  const initialPremium = initialQuote?.attributes?.premium;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container data-testid="context">
      <Paragraph type="large" bold>
        Context
      </Paragraph>

      <FlexBox mt={spacings.px8} gap={spacings.px8} justifySpaceBetween>
        <Paragraph>{onlineSessionStatus}</Paragraph>
        <Text
          color={colors.grey60}
          type="small"
          customCss={css`
            min-width: 80px;
            text-align: right;
          `}
        >
          {relativeTimeFormatter(personOnlineSession.last_interaction_at)}
        </Text>
      </FlexBox>

      {initialPremium && (
        <Paragraph type="small" color={colors.grey60} mt={spacings.px8}>
          Initial quote price they saw was {moneyFormatter(initialPremium, true)} with{' '}
          {initialQuote.attributes.carrier.name}
        </Paragraph>
      )}

      {customerChoiceQuote && (
        <FlexBox mt={spacings.px32} gap={spacings.px8}>
          <FlexBox id="policy-type">
            <CircledPolicyTypeIcon policyType={customerChoiceQuote.attributes.policy_type} />
          </FlexBox>
          <FlexBox fitParentWidth gap={spacings.px4} id="else" columnDirection>
            <FlexBox id="carrier + tags" justifySpaceBetween>
              <FlexBox
                columnDirection
                customCss={css`
                  width: 120px;
                `}
                id="carrier-name + premium"
              >
                <FlexBox gap={spacings.px4} alignItemsCenter>
                  <span
                    data-tip={customerChoiceQuote.attributes.carrier.name}
                    data-for="customers-choice-carrier-name-tooltip"
                    css={css`
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    `}
                  >
                    <Text bold>{customerChoiceQuote.attributes.carrier.name}</Text>
                  </span>
                  <Bridge
                    quote={customerChoiceQuote}
                    compact
                    onBridge={() => setShowBridgeModalForQuote(customerChoiceQuote)}
                  />
                </FlexBox>
                {isCustomerChoiceFinalized && isQuoteEligibleOrPartlyEligible(customerChoiceQuote) && (
                  <FlexBox columnDirection>
                    <FlexBox alignItemsCenter gap={spacings.px4}>
                      {customerChoiceQuote.kind === QuoteKind.RC2 && (
                        <ShieldCheckIcon
                          color={colors.statusGreen}
                          data-tip="Near final price with reports"
                          data-for="near-final-price-tooltip"
                        />
                      )}
                      <Text>{moneyFormatter(customerChoiceQuote.attributes.premium, true)}</Text>
                    </FlexBox>
                    {!!customerChoiceQuote.attributes.bundled_premium && (
                      <Text color={colors.statusGreen}>
                        With bundle: {moneyFormatter(customerChoiceQuote.attributes.bundled_premium, true)}
                      </Text>
                    )}
                  </FlexBox>
                )}
              </FlexBox>
              <FlexBox
                gap={spacings.px4}
                columnDirection
                customCss={css`
                  align-items: flex-end;
                `}
              >
                <Tag label="Customer's choice" backgroundColor={colors.violet} borderRadius={borderRadius} />
                {digitalProfileBestQuote?.gid === customerChoiceQuote.gid && (
                  <Tag label="Best premium" backgroundColor={colors.statusGreen} borderRadius={borderRadius} />
                )}
              </FlexBox>
            </FlexBox>

            {featureFlags.newQuotesUI ? (
              <Status quote={customerChoiceQuote} />
            ) : (
              <DeprecatedStatus
                quote={customerChoiceQuote}
                withIcon
                tagCss={css`
                  width: 100%;
                `}
              />
            )}
            {personOnlineSession.failure_reason === 'primary_insured_disqualified' && (
              <Tag
                transparent
                textColor={colors.statusRed}
                backgroundColor={colors.statusRed}
                prefix={<ErrorIcon color={colors.statusRed} />}
                label={`Declined business by ${customerChoiceQuote.attributes.carrier.name}.`}
                singleLine={false}
                customCss={css`
                  width: 100%;
                `}
              />
            )}
            {!isCustomerChoiceFinalized && (
              <Text>The final premium is not yet ready as data collection is not yet complete.</Text>
            )}
          </FlexBox>
        </FlexBox>
      )}
      {digitalProfileBestQuote && digitalProfileBestQuote.gid !== customerChoiceQuote?.gid && (
        <FlexBox mt={spacings.px32} gap={spacings.px8}>
          <FlexBox id="policy-type">
            <CircledPolicyTypeIcon policyType={digitalProfileBestQuote.attributes.policy_type} />
          </FlexBox>
          <FlexBox fitParentWidth gap={spacings.px4} id="else" columnDirection>
            <FlexBox id="carrier + tags" justifySpaceBetween>
              <FlexBox
                columnDirection
                customCss={css`
                  width: 120px;
                `}
                id="carrier-name + premium"
              >
                <FlexBox gap={spacings.px4}>
                  <span
                    data-tip={digitalProfileBestQuote.attributes.carrier.name}
                    data-for="customers-choice-carrier-name-tooltip"
                    css={css`
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    `}
                  >
                    <Text bold>{digitalProfileBestQuote.attributes.carrier.name}</Text>
                  </span>
                  <Bridge
                    quote={digitalProfileBestQuote}
                    compact
                    onBridge={() => setShowBridgeModalForQuote(digitalProfileBestQuote)}
                  />
                </FlexBox>
                {isQuoteEligibleOrPartlyEligible(digitalProfileBestQuote) && (
                  <FlexBox columnDirection>
                    <FlexBox alignItemsCenter gap={spacings.px4}>
                      {digitalProfileBestQuote.kind === QuoteKind.RC2 && (
                        <ShieldCheckIcon
                          color={colors.statusGreen}
                          data-tip="Near final price with reports"
                          data-for="near-final-price-tooltip"
                        />
                      )}
                      <Text>{moneyFormatter(digitalProfileBestQuote.attributes.premium, true)}</Text>
                    </FlexBox>
                    {!!digitalProfileBestQuote.attributes.bundled_premium && (
                      <Text color={colors.statusGreen}>
                        With bundle: {moneyFormatter(digitalProfileBestQuote.attributes.bundled_premium, true)}
                      </Text>
                    )}
                  </FlexBox>
                )}
              </FlexBox>
              <FlexBox
                gap={spacings.px4}
                columnDirection
                customCss={css`
                  align-items: flex-end;
                `}
              >
                <Tag label="Best premium" backgroundColor={colors.statusGreen} borderRadius={borderRadius} />
              </FlexBox>
            </FlexBox>
            {featureFlags.newQuotesUI ? (
              <Status quote={digitalProfileBestQuote} />
            ) : (
              <DeprecatedStatus
                quote={digitalProfileBestQuote}
                withIcon
                tagCss={css`
                  width: 100%;
                `}
              />
            )}
          </FlexBox>
        </FlexBox>
      )}
      <Tooltip id="customers-choice-carrier-name-tooltip" />
      <Tooltip id="near-final-price-tooltip" />
      {showBridgeModalForQuote && (
        <ExternalLinkModal quote={showBridgeModalForQuote} cancelBtnHandler={() => setShowBridgeModalForQuote(null)} />
      )}
    </Container>
  );
};

export default DigitalProfileSessionContextInfo;
