/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';

import { ErrorIcon } from '../../../../../../components/core/icons';
import Tag from '../../../../../../components/core/Tag';
import { IQuote } from '../../../../../../interfaces';
import { EligibilityResults, QuoteKind, QuoteStatus } from '../../../../../../interfaces/IQuote';
import colors from '../../../../../../theme/colors';
import PromotionTooltip from './PromotionTooltip';
import StatusTooltip from './StatusTooltip';

const DeprecatedStatus = ({
  quote,
  withIcon = false,
  tagCss
}: {
  quote: IQuote;
  withIcon?: boolean;
  tagCss?: SerializedStyles;
}) => {
  const {
    gid: quoteGid,
    kind,
    status,
    attributes: { eligibility, bundle_options }
  } = quote;

  if (status === QuoteStatus.DeclinedBusiness) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <Tag
          data-for={`${quoteGid}-eligibility`}
          data-tip
          label="Declined business"
          prefix={withIcon ? <ErrorIcon color={colors.statusRed} /> : undefined}
          transparent
          backgroundColor={colors.statusRed}
          textColor={colors.statusRed}
          customCss={tagCss}
        />
      </>
    );
  }

  if (status === QuoteStatus.InvalidData) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <Tag
          data-for={`${quoteGid}-eligibility`}
          data-tip
          label="Data not valid"
          prefix={withIcon ? <ErrorIcon color={colors.statusOrange} /> : undefined}
          transparent
          backgroundColor={colors.statusOrange}
          textColor={colors.statusOrange}
          customCss={tagCss}
        />
      </>
    );
  }

  if (eligibility?.result === EligibilityResults.Ineligible) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <Tag
          data-for={`${quoteGid}-eligibility`}
          data-tip
          label="Ineligible"
          prefix={withIcon ? <ErrorIcon color={colors.statusRed} /> : undefined}
          transparent
          backgroundColor={colors.statusRed}
          textColor={colors.statusRed}
          customCss={tagCss}
        />
      </>
    );
  }

  if (eligibility?.result === EligibilityResults.Uncertain || (bundle_options && !bundle_options.monoline_enabled)) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <Tag
          data-for={`${quoteGid}-eligibility`}
          data-tip
          label="Partly eligible"
          prefix={withIcon ? <ErrorIcon color={colors.statusOrange} /> : undefined}
          transparent
          backgroundColor={colors.statusOrange}
          textColor={colors.statusOrange}
          customCss={tagCss}
        />
      </>
    );
  }

  if (kind === QuoteKind.NonIntegrated || status === QuoteStatus.CompleteManually) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <Tag
          data-for={`${quoteGid}-eligibility`}
          data-tip
          label="Manual quote required"
          prefix={withIcon ? <ErrorIcon color={colors.azure50} /> : undefined}
          transparent
          backgroundColor={colors.azure50}
          textColor={colors.azure50}
          customCss={tagCss}
        />
      </>
    );
  }

  if (quote.attributes.promotion_message && quote.attributes.promotion_rank === 1) {
    return (
      <>
        <PromotionTooltip
          promotionMessage={quote.attributes.promotion_message}
          quoteGid={quote.gid}
          promotionTitle="Carrier growth area"
        />
        <Tag
          data-for={`${quoteGid}-promotion`}
          data-tip
          label="Carrier growth area"
          prefix={withIcon ? <ErrorIcon color={colors.statusGreen} /> : undefined}
          transparent
          backgroundColor={colors.statusGreen}
          textColor={colors.statusGreen}
          customCss={tagCss}
        />
      </>
    );
  }

  if (status === QuoteStatus.Success) {
    return null;
  }

  return (
    <>
      <StatusTooltip quote={quote} />
      <Tag
        data-for={`${quote.gid}-eligibility`}
        data-tip
        transparent
        textColor={colors.statusRed}
        backgroundColor={colors.statusRed}
        prefix={withIcon ? <ErrorIcon color={colors.statusRed} /> : undefined}
        label="Unknown error"
        customCss={tagCss}
      />
    </>
  );
};

export default DeprecatedStatus;
