/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';

import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import { SelectField } from '../../../components/core/forms/fields';
import buildUIFlow from '../../../components/core/forms/uiFlowBuilder';
import Heading from '../../../components/core/Heading';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { DataCollectionStepsKey, IDataCollection, IDataCollectionPageType } from '../../../interfaces/IDataCollection';
import { isExtendedHomeInfoAvailable } from '../../../interfaces/IHome';
import { NoteKind } from '../../../interfaces/INote';
import { useUpdateDataCollection } from '../../../queries/leads/data_collection/useDataCollection';
import useCreatePersonNote from '../../../queries/people/useCreatePersonNote';
import {
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3,
  usePersonAssetsUIFlows
} from '../../../queries/workflows/useUiFlow';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';
import { GuidedSellingPathnames } from '../navigation';
import HomeEligibility from '../tabs/CollectedInfoTab/Homes/HomeEligibility';
import Notes from '../tabs/NotesTab/Notes';

const CREATE_NOTE_TEXTAREA_ID = 'create-note-textarea';

const MOOD_OPTIONS = [
  {
    key: 'enthusiastic_and_open',
    value: '😍 Enthusiastic and Open',
    description: 'The customer is excited about exploring their options and very receptive to suggestions.'
  },
  {
    key: 'pressed_for_time',
    value: '🕝 Pressed for Time',
    description: 'The customer is in a hurry and values efficiency and quick answers above all.'
  },
  {
    key: 'hesitant_but_curious',
    value: '😬 Hesitant but Curious',
    description: "The customer is somewhat unsure but willing to listen and learn more about what's offered."
  },
  {
    key: 'skeptical_and_cautious',
    value: '😒 Skeptical and Cautious',
    description: 'The customer may have doubts or past negative experiences, requiring reassurance.'
  },
  {
    key: 'frustrated_or_stressed',
    value: '😖 Frustrated or Stressed',
    description: 'The customer is dealing with external pressures or past frustrations, needing empathy and patience.'
  },
  {
    key: 'cant_understand_mood',
    value: "🤷‍♂️ I can't understand their mood.",
    description: ''
  }
] as const;

const BUYING_INTENTION_OPTIONS = [
  {
    key: 'dissatisfaction',
    value: '😤 Dissatisfaction with Current Provider',
    description: 'Experiences like poor customer service, claims issues, or coverage limitations can motivate a switch.'
  },
  {
    key: 'seeking_better_coverage',
    value: '👍 Seeking Better Coverage',
    description:
      'The desire for more comprehensive or tailored insurance solutions than what their current policy offers.'
  },
  {
    key: 'bundling_opportunities',
    value: '🚗 Bundling Opportunities',
    description:
      'Interest in consolidating multiple policies (e.g., home and auto) for convenience and potential discounts.'
  },
  {
    key: 'change_in_life_circumstances',
    value: '👨‍👩‍👧 Change in Life Circumstances',
    description:
      // eslint-disable-next-line max-len
      'Life events like buying a new home, getting married, or adding a family member prompt a review of existing coverage.'
  },
  {
    key: 'non_renew_or_cancel',
    value: '🚫 Carrier non-renewed or canceled policy',
    description: ''
  },
  {
    key: 'no_intention_provided',
    value: "🤷‍♂️ I don't know/they didn't mention anything beyond savings",
    description: ''
  }
] as const;

const HAND_OFF_STORAGE_KEY_PREFIX = 'person-hand-off-values';
const useCachedHandOffValues = (leadGid: string | undefined) => {
  React.useEffect(() => {
    const keys = Object.keys(localStorage).filter(key => key.includes(HAND_OFF_STORAGE_KEY_PREFIX));
    const dayAgo = Date.now() - 24 * 60 * 60 * 1000;
    keys.forEach(key => {
      const value = JSON.parse(localStorage.getItem(key) || '{}') as Record<string, any>;
      if ('timestamp' in value && value.timestamp < dayAgo) {
        localStorage.removeItem(key);
      }
    });
  }, []);

  const { mood, intention } = JSON.parse(localStorage.getItem(`${HAND_OFF_STORAGE_KEY_PREFIX}-${leadGid}`) || '{}') as {
    mood?: string;
    intention?: string;
    timestamp?: number;
  };

  return {
    personMoodFromStorage: mood,
    personBuyingAdditionalIntentionFromStorage: intention,
    saveMoodToStorage: (mood: string) => {
      const currentValue = JSON.parse(
        localStorage.getItem(`${HAND_OFF_STORAGE_KEY_PREFIX}-${leadGid}`) || '{}'
      ) as Record<string, any>;
      const newValue = { ...currentValue, mood, timestamp: Date.now() };
      localStorage.setItem(`${HAND_OFF_STORAGE_KEY_PREFIX}-${leadGid}`, JSON.stringify(newValue));
    },
    saveBuyingAdditionalIntentionToStorage: (intention: string) => {
      const currentValue = JSON.parse(
        localStorage.getItem(`${HAND_OFF_STORAGE_KEY_PREFIX}-${leadGid}`) || '{}'
      ) as Record<string, any>;
      const newValue = { ...currentValue, intention, timestamp: Date.now() };
      localStorage.setItem(`${HAND_OFF_STORAGE_KEY_PREFIX}-${leadGid}`, JSON.stringify(newValue));
    }
  };
};

const HandOffPage = ({ dataCollection }: { dataCollection: IDataCollection }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { personGid, lead } = useGuidedSellingExperienceContext();

  const homeOpportunities =
    dataCollection?.pages.find(p => p.page_type === IDataCollectionPageType.HomeProfile)?.opportunities || [];

  const assetsGids = homeOpportunities.map(op => op.assets?.[0]?.gid).filter(Boolean);

  const { data: homeEligibilityUIFlows, isPending: isPendingHomeEligibilityFlows } = usePersonAssetsUIFlows({
    uiFlowKey: featureFlags.homeEligibilityPropertyUnderMaintenance
      ? HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3
      : HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
    personGid,
    assetsGids
  });
  const { mutate: createNote } = useCreatePersonNote();

  const { mutate: updateDataCollection } = useUpdateDataCollection();

  const {
    personMoodFromStorage,
    personBuyingAdditionalIntentionFromStorage,
    saveMoodToStorage,
    saveBuyingAdditionalIntentionToStorage
  } = useCachedHandOffValues(lead?.gid);

  return (
    <BaseForm
      pb={spacings.px24}
      pt={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      enableReinitialize
      controlsAlignment="right"
      type="fullPage"
      controlsWidth={sizes.mediumFormInputWidth}
      initialValues={{
        person_mood: personMoodFromStorage,
        person_buying_additional_intention: personBuyingAdditionalIntentionFromStorage
      }}
      submitText="Finalize!"
      submitTestId="hand-off-submit-button"
      onSubmit={({ person_mood, person_buying_additional_intention }) => {
        const personMood = MOOD_OPTIONS.find(option => option.key === person_mood);
        const personBuyingAdditionalIntention = BUYING_INTENTION_OPTIONS.find(
          option => option.key === person_buying_additional_intention
        );
        const trailingNoteValue = (document.getElementById(CREATE_NOTE_TEXTAREA_ID) as HTMLTextAreaElement | null)
          ?.value;

        if (trailingNoteValue) {
          createNote({
            kind: NoteKind.Memo,
            description: trailingNoteValue,
            person_gid: personGid!
          });
        }

        if (personMood && !personMoodFromStorage) {
          saveMoodToStorage(personMood.key);
          createNote({
            kind: NoteKind.Memo,
            description: `Customer mood feels more like: ${personMood.value}. ${personMood.description}`,
            person_gid: personGid!
          });
        }

        if (personBuyingAdditionalIntention && !personBuyingAdditionalIntentionFromStorage) {
          saveBuyingAdditionalIntentionToStorage(personBuyingAdditionalIntention.key);
          createNote({
            kind: NoteKind.Memo,
            // eslint-disable-next-line max-len
            description: `Beyond price the reason for shopping is ${personBuyingAdditionalIntention.value}. ${personBuyingAdditionalIntention.description}`,
            person_gid: personGid!
          });
        }

        updateDataCollection({
          leadId: lead!.id,
          last_completed_step: DataCollectionStepsKey.QuotingConfiguration
        });

        return navigate(`${GuidedSellingPathnames.ISRFinish}${search}`);
      }}
      renderForm={() => {
        return (
          <FlexBox columnDirection gap={spacings.px12} maxWidth={840}>
            <Heading>Ready to hand off</Heading>
            {isPendingHomeEligibilityFlows && (
              <>
                <Skeleton count={8} height={40} baseColor={colors.grey10} highlightColor={colors.grey5} width="100%" />
              </>
            )}
            {!isPendingHomeEligibilityFlows && (
              <>
                <Paragraph bold type="small">
                  Your agent will appreciate knowing about the customer in advance{' '}
                  <Text type="small">(this will appear in the notes):</Text>
                </Paragraph>
                <>
                  <SelectField
                    name="person_mood"
                    id="person_mood"
                    label="Customer's mood is"
                    inline
                    options={MOOD_OPTIONS}
                    disabled={!!personMoodFromStorage}
                  />
                  <SelectField
                    name="person_buying_additional_intention"
                    id="person_buying_additional_intention"
                    label="Besides price, they are shopping because"
                    inline
                    options={BUYING_INTENTION_OPTIONS}
                    disabled={!!personBuyingAdditionalIntentionFromStorage}
                  />
                </>

                {homeOpportunities.map((homeOpportunity, index) => {
                  const home = homeOpportunity.assets?.[0];
                  const homeEligibilityUIFlow = buildUIFlow({
                    uiFlowResponse: homeEligibilityUIFlows?.[index]?.data?.ui_flow
                  });

                  return home && isExtendedHomeInfoAvailable(home) && homeEligibilityUIFlow ? (
                    <React.Fragment key={home.gid}>
                      <Paragraph bold type="small">
                        {home.description_without_icon}
                      </Paragraph>
                      <HomeEligibility
                        homeOpportunity={homeOpportunity}
                        homeEligibilityUIFlow={homeEligibilityUIFlow}
                        isEditModeAvailable={false}
                        onEdit={() => null}
                      />
                    </React.Fragment>
                  ) : null;
                })}

                <Paragraph bold type="small">
                  Any additional concerns or observations <Text type="small">(this will appear in the notes):</Text>
                </Paragraph>
                <Notes textareaId={CREATE_NOTE_TEXTAREA_ID} />
              </>
            )}
          </FlexBox>
        );
      }}
    />
  );
};

export default HandOffPage;
